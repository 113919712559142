// Function to check if element is in viewport
export const isInViewport = (element: HTMLElement) => {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= -rect.height &&
    rect.left >= -rect.width &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) +
        rect.height &&
    rect.right <=
      (window.innerWidth || document.documentElement.clientWidth) + rect.width
  );
};
