import { useGetPrograms } from "@/api/program";
import { useImpactStatistics } from "@/api/impact_statistics";
import { Skeleton } from "@/components/ui/skeleton";
import { ProgramType } from "@/types/programs";
import { ReactElement, useMemo, useState, useEffect } from "react";
import { ProgramProfileCard } from "@/pages/donor-profile/components/donate-card";
import { cn } from "@/lib/utils";
import { UpdateCard } from "@/pages/program/sections/update-card";
import { useNavigate } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";
import { ProgramImageSkeleton } from "@/pages/donor-profile/components/program-image-skeleton";
import { ProgramCardSkeleton } from "@/pages/donor-profile/components/program-card-skeleton";
import { FamiliesIcon } from "@/assets/icons/FamiliesIcon";
import SoupIcon from "@/assets/icons/soupIcon";
import { FlagIcon, Plus } from "lucide-react";
import { CarouselComponent } from "@/pages/home/sections/CarouselComponent";

export const programTitleFormatter = (title: string) => {
  const dots = title.split(" ").length > 3 ? "..." : "";
  return title.split(" ").splice(0, 3).join(" ") + dots;
};

export const ImpactCard = ({
  icon,
  title,
  value,
  loading,
}: {
  icon: ReactElement;
  title: string;
  value?: number;
  loading: boolean;
}) => {
  return (
    <div className="flex min-w-60 flex-col items-center rounded-lg bg-[#E3E1D8] p-6 md:min-w-72 md:items-start rtl:md:items-end">
      <div className="mb-2 md:mb-6">{icon}</div>
      <p className="text-xl font-bold md:text-3xl">
        {!loading && value}
        {loading && <Skeleton className="h-9 w-12 bg-[#c4c0bd]" />}
      </p>
      <p>{title}</p>
    </div>
  );
};

const MoreCauses = ({
  onClick,
  outline,
  isEmptyState,
}: {
  onClick: () => void;
  outline: boolean;
  isEmptyState?: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center gap-2 md:gap-4 md:px-2 md:py-4">
      <button
        onClick={onClick}
        className={cn(
          "flex justify-center items-center h-20 min-w-20 overflow-hidden rounded-full md:h-28 md:min-w-28 bg-[#CDCFC8]",
          {
            "outline outline-offset-2 outline-black": outline,
          },
        )}
      >
        <Plus className="h-6 w-6" />
      </button>
      <p>
        {isEmptyState
          ? t("myDonations.moreCauses")
          : t("myDonations.similarCauses")}
      </p>
    </div>
  );
};

export const MyUpdates = () => {
  const navigate = useNavigate();

  const { data: impactstatics, isLoading: impactStatsLoading } =
    useImpactStatistics();
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === "ar";

  const { data: programs, isLoading: programLoading } = useGetPrograms(
    { lang: i18n.language, donations: "true", page_size: "1000" },
    (data) =>
      data.sort((a, b) => {
        const diff = b.user_donated! - a.user_donated!;
        if (diff) {
          return diff;
        } else {
          return b.rank - a.rank;
        }
      }),
  );

  const [selectedProgram, setSelectedProgram] = useState<ProgramType | null>(
    null,
  );
  const [showMoreCauses, setShowMoreCauses] = useState<boolean>(false);

  const isEmptyState = useMemo(
    () => programs?.every((p) => p.user_donated === 0),
    [programs],
  );

  useEffect(() => {
    if (isEmptyState) {
      setShowMoreCauses(true);
    } else if (!selectedProgram && !showMoreCauses && programs) {
      const firstProgramWithUpdates = programs.find((p) => p.user_donated);
      setSelectedProgram(firstProgramWithUpdates || null);
    }
  }, [selectedProgram, showMoreCauses, isEmptyState, programs]);

  return (
    <section className="flex flex-col gap-y-4 md:gap-y-8">
      <h2 className="pt-4 text-2xl font-bold md:pt-6 md:text-5xl rtl:self-end">
        {t("myDonations.impact")}
      </h2>
      <div className="mb-' flex flex-col items-center justify-end py-2 md:mb-8 md:flex-row md:flex-row-reverse rtl:justify-start">
        <div className="flex max-w-full gap-4 overflow-x-auto md:gap-8 rtl:flex-row-reverse">
          <ImpactCard
            icon={<FamiliesIcon />}
            title={t("myUpdates.familiesHelped")}
            value={parseFloat((impactstatics?.box ?? 0).toFixed(2))}
            loading={impactStatsLoading}
          />
          <ImpactCard
            icon={<SoupIcon width={24} height={24} fill="#22222A" />}
            title={t("myUpdates.totalMeals")}
            value={parseFloat((impactstatics?.meal ?? 0).toFixed(2))}
            loading={impactStatsLoading}
          />
          <ImpactCard
            icon={<FlagIcon />}
            title={t("myUpdates.causesSupported")}
            value={programs?.filter((p) => p.user_donated).length}
            loading={programLoading}
          />
        </div>
      </div>
      {isEmptyState && (
        <p
          className="text-xl md:text-3xl"
          style={{ direction: isRTL ? "rtl" : "ltr" }}
        >
          {t("myUpdates.emptyStateMessage")}
        </p>
      )}
      <h2 className="text-xl font-bold md:text-3xl rtl:self-end">
        {t("myDonations.causeFromGround")}
      </h2>
      <div className="scrollbar-hide max-w-full overflow-x-auto ">
        <div className="m-1 flex gap-4 md:gap-8 rtl:flex-row-reverse">
          <MoreCauses
            onClick={() => {
              setSelectedProgram(null);
              setShowMoreCauses(true);
            }}
            outline={selectedProgram === null && showMoreCauses}
            isEmptyState={isEmptyState}
          />
          {!programLoading &&
            programs
              ?.filter((p) => p.user_donated)
              .map((program) => (
                <div
                  key={`my-updates-${program.id}`}
                  className="flex flex-col items-center gap-2 md:gap-4 md:px-2 md:py-4"
                >
                  <button
                    className={cn(
                      "h-20 w-20 overflow-hidden rounded-full md:h-28 md:w-28",
                      {
                        "outline outline-offset-2 outline-black":
                          selectedProgram === program,
                      },
                    )}
                    onClick={() =>
                      program.user_donated
                        ? (setSelectedProgram(program),
                          setShowMoreCauses(false))
                        : navigate({ to: `/program/${program.id}` })
                    }
                  >
                    <img
                      src={program.main_image?.minio_url}
                      alt={program.title}
                      className={cn("h-20 max-w-none md:h-28", {
                        "saturate-0": !program.updates.length,
                      })}
                    />
                  </button>
                  <p
                    className={cn("max-w-28 text-center", {
                      "font-bold": program === selectedProgram,
                    })}
                  >
                    {programTitleFormatter(program.title)}
                  </p>
                </div>
              ))}
          {programLoading && <ProgramImageSkeleton />}
        </div>
      </div>
      <div
        className={cn("max-w-full rtl:md:m-6 rtl:mr-4 ", {
          "rtl:flex-row-reverse": isRTL,
        })}
        style={{ direction: isRTL ? "rtl" : "ltr" }}
      >
        {!programLoading &&
          !isEmptyState &&
          selectedProgram &&
          !showMoreCauses && (
            <CarouselComponent imagesMd={2} imagesLg={3}>
              <ProgramProfileCard light={true} program={selectedProgram} />
              {selectedProgram.updates.map((update) => (
                <div
                  key={`my-updates-${update.id}`}
                  className="flex aspect-9/16 min-w-[250px] max-w-[250px] items-stretch md:min-w-[350px] md:max-w-[350px]"
                >
                  <UpdateCard
                    update={update}
                    bgColor={selectedProgram.color_third}
                  />
                </div>
              ))}
            </CarouselComponent>
          )}

        {showMoreCauses && (
          <CarouselComponent imagesMd={2} imagesLg={3}>
            {!programLoading &&
              !selectedProgram &&
              programs
                ?.filter((p) => !p.user_donated)
                .map((program) => (
                  <button
                    key={`profile-${program.id}`}
                    onClick={(e) => {
                      if ((e.target as HTMLElement).tagName !== "BUTTON") {
                        navigate({ to: `/program/${program.id}` });
                      }
                    }}
                    className="flex w-full cursor-pointer flex-col items-start border-none bg-none p-0 text-start"
                    tabIndex={0}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" || e.key === " ") {
                        navigate({ to: `/program/${program.id}` });
                      }
                    }}
                  >
                    <ProgramProfileCard program={program} />
                  </button>
                ))}
            {programLoading && <ProgramCardSkeleton />}
          </CarouselComponent>
        )}
      </div>
    </section>
  );
};
