import { GoalStep } from "./steps/GoalStep";
import { CauseStep } from "./steps/CauseStep";
import { DetailsStep } from "./steps/DetailsStep";
import { ThankYouStep } from "./steps/ThankYouStep";
import useChallengeFormStore from "@/store/challengeStore";

type ChallengeStepsContainerProps = {
  readonly step?: number;
};

const ChallengeStepsContainer = ({ step }: ChallengeStepsContainerProps) => {
  return (
    <div className=" flex h-full flex-col items-center bg-background">
      {step === 1 && <CauseStep />}
      {step === 2 && <GoalStep />}
      {step === 3 && <DetailsStep />}
      {step === 4 && <ThankYouStep />}
    </div>
  );
};

export default function ChallengeStepper() {
  const { currentStep } = useChallengeFormStore();
  return (
    <div className="flex h-full flex-col">
      <ChallengeStepsContainer step={currentStep} />
    </div>
  );
}
