import { useLocation } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";
import { useFormStore } from "@/contexts/paymentFlowContext";
import useCurrencyStore from "@/store/currencyStore";
import ChallengePaymentContainer from "../challenge-payment/ChallengePaymentContainer";
import { useChallenge } from "@/api/challenge";
import { Route } from "@/routes/challenge-checkout.$challengeId";
import ChallengePreference from "./ChallengePreference";
import ChallengeGratitude from "./ChallengeGratitude";
import { AppShell } from "@/components/Navbar";
import { cn } from "@/lib/utils";
import { useState } from "react";
import { Link } from "@tanstack/react-router";
import { isRTLLanguage } from "@/utils/helpers";

const ChallengeCheckout = () => {
  const { challengeId } = Route.useParams();
  const { currency } = useCurrencyStore();
  const { search } = useLocation();
  const { t, i18n } = useTranslation();
  const { email, inputAmount, preferenceSuccess } = useFormStore();
  const amount = `${search.currency ?? currency} ${inputAmount}`;

  const { data: challenge } = useChallenge(challengeId, {
    lang: i18n.language,
  });

  const [hideNavbar, setHideNavbar] = useState<boolean>(false);
  const handleScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    if (event.currentTarget.scrollTop > 10) {
      setHideNavbar(true);
    } else {
      setHideNavbar(false);
    }
  };

  return challenge ? (
    <>
      <div className="fixed left-0 right-0 top-0 flex h-14 items-center justify-between bg-foreground px-4 md:px-[24px]">
        <div className="flex flex-row gap-1 text-xs text-white md:text-sm">
          {t("poweredBy.first_part")}{" "}
          <Link
            aria-label={
              t("poweredBy.first_part") + " " + t("poweredBy.second_part")
            }
            to="/"
          >
            <p className="font-bold text-white underline">
              {" "}
              {t("poweredBy.second_part")}
            </p>
          </Link>
        </div>
        <Link
          aria-label={
            t("poweredBy.first_part") + " " + t("poweredBy.second_part")
          }
          to="/"
        >
          {isRTLLanguage(i18n.language) ? (
            <img src="/efb-logo-ar-white.svg" className="h-8" />
          ) : (
            <img src="/efb-logo-white.svg" className="h-8" />
          )}
        </Link>
      </div>
      <div className="fixed top-[32px] flex w-full flex-col overflow-hidden bg-[#CDCFC9] text-white md:hidden">
        <h2 className="block p-4 pb-8 text-lg font-bold md:hidden">
          {challenge.name}
        </h2>
      </div>
      <ChallengePaymentContainer challenge={challenge}>
        <AppShell
          navbarClass={cn(
            "absolute md:top-12 transition ease-in-out duration-150 block w-full md:fixed bg-transparent",
            { "opacity-0": hideNavbar },
          )}
          hideLogo={true}
        />
        <div
          onScroll={handleScroll}
          className={`h-[70%] flex-1 overflow-auto rounded-xl bg-background p-4 pb-0 sm:h-full md:rounded-none lg:p-16 lg:pb-0 lg:pt-24`}
        >
          {preferenceSuccess ? (
            <ChallengeGratitude program={challenge.program} />
          ) : (
            <ChallengePreference
              amount={amount}
              email={email}
              challengeId={challengeId}
            />
          )}
        </div>
      </ChallengePaymentContainer>
    </>
  ) : null;
};

export default ChallengeCheckout;
