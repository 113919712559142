import React from "react";
import { useTranslation } from "react-i18next";
import { CarouselComponent } from "@/pages/home/sections/CarouselComponent";

const VolunteersIcon: React.FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.5 4C7.84315 4 6.5 5.34315 6.5 7C6.5 8.65685 7.84315 10 9.5 10C11.1569 10 12.5 8.65685 12.5 7C12.5 5.34315 11.1569 4 9.5 4ZM4.5 7C4.5 4.23858 6.73858 2 9.5 2C12.2614 2 14.5 4.23858 14.5 7C14.5 9.76142 12.2614 12 9.5 12C6.73858 12 4.5 9.76142 4.5 7ZM14.5731 2.91554C14.7803 2.40361 15.3633 2.1566 15.8752 2.36382C17.7058 3.10481 19 4.90006 19 7C19 9.09994 17.7058 10.8952 15.8752 11.6362C15.3633 11.8434 14.7803 11.5964 14.5731 11.0845C14.3658 10.5725 14.6129 9.98953 15.1248 9.7823C16.2261 9.33652 17 8.25744 17 7C17 5.74256 16.2261 4.66348 15.1248 4.2177C14.6129 4.01047 14.3658 3.42748 14.5731 2.91554ZM7.96448 14H11.0355C11.9373 14 12.6647 14 13.2567 14.0404C13.8654 14.0819 14.4037 14.1695 14.9134 14.3806C16.1386 14.8881 17.1119 15.8614 17.6194 17.0866C17.8305 17.5963 17.9181 18.1346 17.9596 18.7433C18 19.3353 18 20.0627 18 20.9645V21C18 21.5523 17.5523 22 17 22C16.4477 22 16 21.5523 16 21C16 20.0544 15.9995 19.3953 15.9643 18.8794C15.9297 18.3725 15.865 18.0773 15.7716 17.8519C15.4672 17.1169 14.8831 16.5328 14.1481 16.2284C13.9227 16.135 13.6275 16.0703 13.1206 16.0357C12.6047 16.0005 11.9456 16 11 16H8C7.05444 16 6.39534 16.0005 5.87945 16.0357C5.37254 16.0703 5.07733 16.135 4.85195 16.2284C4.11687 16.5328 3.53284 17.1169 3.22836 17.8519C3.135 18.0773 3.07033 18.3725 3.03574 18.8794C3.00054 19.3953 3 20.0544 3 21C3 21.5523 2.55229 22 2 22C1.44772 22 1 21.5523 1 21L1 20.9645C0.999993 20.0627 0.999988 19.3353 1.04038 18.7433C1.08191 18.1346 1.16948 17.5963 1.3806 17.0866C1.88807 15.8614 2.86144 14.8881 4.08658 14.3806C4.59628 14.1695 5.13456 14.0819 5.74331 14.0404C6.33531 14 7.06272 14 7.96448 14ZM18.0316 14.8768C18.1692 14.3419 18.7144 14.0199 19.2493 14.1576C21.4056 14.7126 23 16.6688 23 19V21C23 21.5523 22.5523 22 22 22C21.4477 22 21 21.5523 21 21V19C21 17.6035 20.0449 16.4275 18.7507 16.0945C18.2159 15.9568 17.8939 15.4116 18.0316 14.8768Z"
      fill="#232329"
    />
  </svg>
);

const StudentInitiativesIcon: React.FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M21.8099 10.1163L18.7124 11.9163L12.6974 15.4113C12.1349 15.7338 11.4449 15.7338 10.8824 15.4113L4.86742 11.9163L1.76242 10.1163C1.40992 9.90629 1.40992 9.39629 1.76242 9.18629L10.8824 3.89129C11.4449 3.56879 12.1349 3.56879 12.6974 3.89129L21.8099 9.18629C22.1699 9.39629 22.1699 9.90629 21.8099 10.1163Z"
      stroke="#232329"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.7122 11.917V16.0345C18.7122 16.6645 18.3747 17.242 17.8347 17.557L14.0247 19.7545C12.6447 20.5495 10.9347 20.5495 9.54719 19.7545L5.74469 17.557C5.19719 17.242 4.86719 16.6645 4.86719 16.0345V11.917L10.8822 15.412C11.4447 15.7345 12.1347 15.7345 12.6972 15.412L18.7122 11.917Z"
      stroke="#232329"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.5 19C22.3284 19 23 18.3284 23 17.5C23 16.6716 22.3284 16 21.5 16C20.6716 16 20 16.6716 20 17.5C20 18.3284 20.6716 19 21.5 19Z"
      fill="#232329"
    />
    <path
      d="M21.5 17V9.5"
      stroke="#232329"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const PartneredOrganizationsIcon: React.FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <g id="Group">
      <path
        id="Vector"
        d="M8.25299 19.9829H9.22562M15.6898 19.9829H14.7708M9.22562 19.9829V19.0665C9.22562 18.8976 9.22562 18.8132 9.20153 18.6243C9.18941 18.5291 9.12323 18.2207 9.09527 18.129C9.03974 17.9468 9.03912 17.9454 9.03787 17.9426C8.81516 17.4474 8.46588 17.0114 8.01781 16.6878C6.38281 15.4953 5.44531 13.6578 5.44531 11.6478C5.44531 9.84778 6.17279 8.17529 7.4928 6.93778C8.82782 5.67778 10.5753 5.04776 12.4353 5.16779C15.7878 5.38527 18.4728 8.16028 18.5553 11.4903C18.6078 13.5753 17.6553 15.4728 15.9603 16.7028C15.5118 17.0243 15.1639 17.4653 14.9458 17.9703C14.9445 17.9732 14.9439 17.9746 14.8922 18.1509C14.8661 18.2397 14.8045 18.5377 14.7932 18.6296C14.7708 18.8119 14.7708 18.8937 14.7708 19.0572V19.9829M9.22562 19.9829H14.7708"
        stroke="#232329"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M9.54395 22.75H14.3984"
        stroke="#232329"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_3"
        d="M12 20L12 16"
        stroke="#232329"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_4"
        d="M12 2L12 1"
        stroke="#232329"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_5"
        d="M2 12L1 12"
        stroke="#232329"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_6"
        d="M23 12L22 12"
        stroke="#232329"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_7"
        d="M4.92896 4.92871L4.22185 4.2216"
        stroke="#232329"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_8"
        d="M19.7782 19.7783L19.0711 19.0712"
        stroke="#232329"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_9"
        d="M19.0713 4.92871L19.7784 4.2216"
        stroke="#232329"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_10"
        d="M4.22168 19.7783L4.92879 19.0712"
        stroke="#232329"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

const VolunteeredSchoolIcon: React.FC = () => (
  <svg width="20" height="24" viewBox="0 0 20 24" fill="none">
    <g id="Layer_6">
      <path
        id="Vector"
        d="M16 20.3752V20.3752C17.5202 20.3752 18.7526 19.1429 18.7526 17.6227C18.7526 14.3818 18.7526 11.1409 18.7526 7.9C18.7526 5.65979 18.7526 4.53968 18.3166 3.68404C17.9331 2.93139 17.3212 2.31947 16.5685 1.93597C15.7129 1.5 14.5928 1.5 12.3526 1.5H7.64756C5.40735 1.5 4.28724 1.5 3.4316 1.93597C2.67895 2.31947 2.06703 2.93139 1.68353 3.68404C1.24756 4.53968 1.24756 5.65979 1.24756 7.9C1.24756 10.3076 1.24756 12.7152 1.24756 15.1228C1.24756 16.2873 1.24756 16.8696 1.3679 17.349C1.72687 18.7792 2.84358 19.8959 4.27378 20.2549C4.75323 20.3752 5.33549 20.3752 6.5 20.3752V20.3752"
        stroke="#232329"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M12.9677 17.375V22.5L11.1152 21.285L9.2627 22.5V17.375"
        stroke="#232329"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_3"
        d="M11.1158 18.0431C12.7341 18.0431 14.046 16.7312 14.046 15.1129C14.046 13.4945 12.7341 12.1826 11.1158 12.1826C9.49746 12.1826 8.18555 13.4945 8.18555 15.1129C8.18555 16.7312 9.49746 18.0431 11.1158 18.0431Z"
        stroke="#232329"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
export const VolunteerSection: React.FC = () => {
  const { t } = useTranslation();

  const partnersData = [
    {
      icon: <VolunteersIcon />,
      title: t("aboutPage.volunteerSection.partners.volunteers.title"),
      description: (
        <span>
          {t("aboutPage.volunteerSection.partners.volunteers.line1")}
          <br />
          {t("aboutPage.volunteerSection.partners.volunteers.line2")}
        </span>
      ),
    },
    {
      icon: <StudentInitiativesIcon />,
      title: t("aboutPage.volunteerSection.partners.studentInitiatives.title"),
      description: (
        <span>
          {t("aboutPage.volunteerSection.partners.studentInitiatives.line1")}
          <br />
          {t("aboutPage.volunteerSection.partners.studentInitiatives.line2")}
        </span>
      ),
    },
    {
      icon: <PartneredOrganizationsIcon />,
      title: t(
        "aboutPage.volunteerSection.partners.partneredOrganizations.title",
      ),
      description: (
        <span>
          {t(
            "aboutPage.volunteerSection.partners.partneredOrganizations.line1",
          )}
          <br />
          {t(
            "aboutPage.volunteerSection.partners.partneredOrganizations.line2",
          )}
        </span>
      ),
    },
    {
      icon: <VolunteeredSchoolIcon />,
      title: t("aboutPage.volunteerSection.partners.volunteeredSchool.title"),
      description: (
        <span>
          {t("aboutPage.volunteerSection.partners.volunteeredSchool.line1")}
          <br />
          {t("aboutPage.volunteerSection.partners.volunteeredSchool.line2")}
        </span>
      ),
    },
  ];

  return (
    <>
      {/* Grid for medium screens and above */}
      <div className="my-8 hidden rounded-lg border border-[#E2DDD7] md:grid md:grid-cols-4">
        {partnersData.map((partner, index) => (
          <div
            key={index}
            className={`h-[220px] w-full ${
              index < partnersData.length - 1
                ? "border-[#E2DDD7] ltr:border-r rtl:border-l"
                : ""
            } flex flex-col justify-between p-4`}
          >
            <div className="m-2 self-end">{partner.icon}</div>
            <div className="m-2 inline-flex items-center gap-2">
              <h1 className="text-5xl">{partner.title}</h1>
              <p>{partner.description}</p>
            </div>
          </div>
        ))}
      </div>

      {/* Carousel for small screens */}
      <div className="my-8 block md:hidden">
        {" "}
        {/* Hide on medium screens and above */}
        <CarouselComponent imagesMd={1} imagesLg={2} hideArrows>
          {partnersData.map((partner, index) => (
            <div
              key={index}
              className={`flex h-[220px] w-[312px] flex-col justify-between rounded-lg border border-[#E2DDD7] p-4`}
            >
              <div className="m-2 self-end">{partner.icon}</div>
              <div className="m-2 inline-flex items-center gap-2">
                <h1 className="text-5xl">{partner.title}</h1>
                <p>{partner.description}</p>
              </div>
            </div>
          ))}
        </CarouselComponent>
      </div>
    </>
  );
};
