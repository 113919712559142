import { useTranslation } from "react-i18next";
import { formatNumber } from "@/utils/currency";
import CustomLink from "@/pages/home/sections/CustomLink";
import clsx from "clsx";

interface ImpactSectionProps {
  NumberOfBeneficiaries: number;
  YearsOfService?: number;
  NumberOfDonations: number;
  showLink: boolean;
  dynamicColor: string;
  isProgramDetail?: boolean;
  TotalMeals?: number;
}

export const ImpactSection = ({
  NumberOfBeneficiaries,
  YearsOfService,
  NumberOfDonations,
  showLink,
  dynamicColor,
  isProgramDetail,
  TotalMeals,
}: ImpactSectionProps) => {
  const { t } = useTranslation();
  return (
    <div className="my-2 flex w-full flex-col md:my-6" id="impact">
      {showLink ? (
        <div className="flex items-center justify-between">
          <h1
            className={clsx("mb-4 text-2xl font-bold md:mb-6 md:text-5xl", {
              "mx-[16px] md:mx-[24px]": isProgramDetail,
            })}
          >
            {t("impact.title")}
          </h1>
          <div className="flex flex-col justify-end pb-4 md:pb-0">
            <CustomLink
              to="/aboutUs"
              mobileText={t("highlightedLink.see_more_mobile")}
              desktopText={t("highlightedLink.see_more_mobile")}
            />
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-between">
          <h2
            className={clsx("mb-5 text-2xl font-bold md:mb-8 md:text-4xl", {
              "mx-[16px] md:mx-[24px]": isProgramDetail,
            })}
          >
            {t("impact.title")}
          </h2>
        </div>
      )}
      <section className="flex w-full overflow-x-auto">
        <div
          className={clsx("flex w-full gap-4 md:flex-row md:gap-6", {
            "first:ml-[16px] first:md:ml-[24px] last:mr-[16px] last:md:mr-[24px]":
              isProgramDetail,
          })}
        >
          <div
            className="flex w-80 flex-none flex-col gap-6 rounded-lg border-0 border-gray-500 p-6 md:w-full md:flex-initial"
            style={{ backgroundColor: dynamicColor }}
          >
            <div className="mb-10 me-auto rounded-full bg-transparent p-0">
              <img
                src="/icons/beneficiaries.svg"
                width="22px"
                height="auto"
                alt={t(`Number of Beneficiaries Helped`)}
              />
            </div>
            <div className="flex flex-col gap-4">
              <p className="text-2xl font-bold text-foreground md:text-5xl">
                {formatNumber(NumberOfBeneficiaries, true) + "+"}
              </p>
              <p className="text-foreground">{t(`impact.Beneficiaries`)}</p>
            </div>
          </div>

          <div
            className={`flex w-80 flex-none flex-col gap-6 rounded-lg border-0 border-gray-500 p-6 md:w-full md:flex-initial`}
            style={{ backgroundColor: dynamicColor }}
          >
            <div className="mb-10 me-auto rounded-full bg-transparent p-0">
              <img
                src={
                  YearsOfService ? "/icons/community.svg" : "/icons/meal.svg"
                }
                width="22px"
                height="auto"
                alt={t(`Years of Service to the Community`)}
              />
            </div>
            <div className="flex flex-col gap-4">
              <p className="text-2xl font-bold text-foreground md:text-5xl">
                {YearsOfService
                  ? formatNumber(YearsOfService, true)
                  : formatNumber(TotalMeals ?? 0, true)}
              </p>
              <p className="text-foreground">
                {YearsOfService
                  ? t(`impact.YearsOfService`)
                  : t(`impact.TotalMeals`)}
              </p>
            </div>
          </div>

          <div
            className="flex w-80 flex-none flex-col gap-6 rounded-lg border-0 border-gray-500 p-6 md:w-full md:flex-initial"
            style={{ backgroundColor: dynamicColor }}
          >
            <div className="mb-10 me-auto rounded-full bg-transparent p-0">
              <img
                src="/icons/donation.svg"
                width="22px"
                height="auto"
                alt={t(`Number of Donations`)}
              />
            </div>
            <div className="flex flex-col gap-4">
              <p className="text-2xl font-bold text-foreground md:text-5xl">
                {formatNumber(NumberOfDonations, true) + "+"}
              </p>
              <p className="text-foreground">{t(`impact.Donations`)}</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
