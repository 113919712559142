import { AppShell } from "@/components/Navbar";
import ProgramListing from "@/pages/home/sections/ProgramListing";
import { TitleSection } from "@/pages/home/sections/title-section";
import Categories from "./sections/Categories";
import { ImpactSection } from "../program/sections/impact";
import { Partners } from "./sections/Partners";
import { useHomepage } from "@/api/homepage";
import { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet-async";
import Testimonials from "./sections/Testimonials";
import { useLocation, Link } from "@tanstack/react-router";
import { useFormStore } from "@/contexts/paymentFlowContext";
import useCurrencyStore from "@/store/currencyStore";
import { useTranslation } from "react-i18next";
import aboutData from "@/utils/about.json";
import clsx from "clsx";
import { isRTLLanguage } from "@/utils/helpers";

export const HomePage = () => {
  const { t, i18n } = useTranslation();
  const donationSocket = useFormStore((state) => state.donationSocket);
  const { currency } = useCurrencyStore();
  const { data: homepageData } = useHomepage({ lang: i18n.language });
  const { search: params } = useLocation();
  const { checkoutStep, selectedAmount, inputAmount, reset } = useFormStore();
  const [isScrolled, setIsScrolled] = useState(false);
  const progRef = useRef<HTMLDivElement>(null);
  let logos = [];
  let testimonials = [];
  let kpis = {
    number_of_donations: 0,
    years_of_service: 0,
    number_of_beneficiaries: 0,
  };
  if (homepageData?.length) {
    kpis.number_of_beneficiaries = homepageData[0].number_of_beneficiaries;
    kpis.number_of_donations = homepageData[0].number_of_donations;
    kpis.years_of_service = homepageData[0].years_of_service;
    logos = homepageData[0].homepage_logo;
    testimonials = homepageData[0].testimonials;
  }
  const structuredJSON = JSON.stringify(aboutData);

  useEffect(() => {
    if (params.response_code) {
      checkoutStep();
      if (donationSocket && donationSocket.readyState === WebSocket.OPEN) {
        const amount =
          selectedAmount === inputAmount ? selectedAmount : inputAmount;
        donationSocket.send(JSON.stringify({ amount, currency }));
      }
    }
  }, [params]);

  useEffect(() => {
    if (!params.response_code) {
      reset();
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (progRef.current) {
        const rect = progRef.current.getBoundingClientRect();
        if (rect.bottom <= 0) {
          setIsScrolled(true);
        } else {
          setIsScrolled(false);
        }
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>
          Circle of Humanity | Creating Sustainable Programs to Combat Hunger
        </title>
        <meta
          name="description"
          content="Join Circle of Humanity in our mission to alleviate hunger and provide nutritious meals to Egyptians, refugees, and those in crisis. Support our causes to bring immediate relief to the most affected regions."
        />
        <meta
          name="keywords"
          content="Circle of Humanity, Egyptian Food Bank, hunger relief, food security, donate, humanitarian aid"
        />
        <link rel="canonical" href="https://coh.efb.eg" />
        <meta
          property="og:title"
          content="Circle of Humanity | Creating Sustainable Programs to Combat Hunger"
        />
        <meta
          property="og:description"
          content="Support the Circle of Humanity initiative to provide essential food aid and nutritional support to communities in need across Egypt and surrounding regions. Donate today to help us address the most critical causes."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://coh.efb.eg" />
        <meta
          property="og:image"
          content="https://minio-coh.efb.eg/efb/documents/COH.svg"
        />
        <meta property="og:site_name" content={t("siteName")} />
        <script className="structured-data-list" type="application/ld+json">
          {structuredJSON}
        </script>
      </Helmet>
      <div className="flex h-14 items-center justify-between bg-foreground px-4 md:px-[24px]">
        <div className="flex flex-row gap-1 text-white">
          {t("join.first_part")}{" "}
          <Link
            aria-label={t("join.first_part") + " " + t("join.second_part")}
            to="/community-challenge"
            search={{ tab: "" }}
          >
            <p className="font-bold text-white underline">
              {" "}
              {t("join.second_part")}
            </p>
          </Link>
        </div>
        <Link
          aria-label={t("join.first_part") + " " + t("join.second_part")}
          to="/community-challenge"
          search={{ tab: "" }}
        >
          {isRTLLanguage(i18n.language) ? (
            <img
              src="/coh-logo-ar-white.svg"
              className="h-8"
              alt="Circle of Humanity Logo"
            />
          ) : (
            <img
              src="/coh-logo-white.svg"
              className="h-8"
              alt="Circle of Humanity Logo"
            />
          )}
        </Link>
      </div>
      <a href="#mainContent" aria-label="Skip to main content"></a>
      <div className="relative">
        <img
          src="/Frame-hero.svg"
          alt="Decorative Background SVG"
          className="absolute left-0 top-0 z-10 h-full w-full object-cover"
        />
        <AppShell
          navbarClass={clsx(
            "fixed top-0 -z-10 w-full bg-background-second opacity-0 transition-opacity duration-300 ease-in-out",
            { "opacity-100 z-10": isScrolled },
          )}
        />
        <AppShell navbarClass="w-full bg-background-second" />

        <div
          ref={progRef}
          className="relative h-[50vh] min-h-[300px] bg-background-second"
        >
          <TitleSection />
        </div>
      </div>

      <main
        id="mainContent"
        className="mx-4 mt-4 flex max-h-full max-w-full flex-col items-center justify-center bg-background md:mx-6 md:mt-6"
      >
        <Partners Items={logos} />
        <ProgramListing />
        <ImpactSection
          NumberOfBeneficiaries={kpis.number_of_beneficiaries}
          YearsOfService={kpis.years_of_service}
          NumberOfDonations={kpis.number_of_donations}
          showLink={true}
          dynamicColor={"hsla(45, 18%, 87%, 1)"}
        />
        <Categories />
        <Testimonials
          testimonials={testimonials}
          size={"5xl"}
          dynamicColor={"hsla(45, 18%, 87%, 1)"}
        />
      </main>
    </>
  );
};
