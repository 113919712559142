import React, { useEffect } from "react";
import { Button } from "@/components/ui/button";
import { useTranslation } from "react-i18next";
import ProgramRecommendation from "../../components/PaymentFlow/ProgramRecommendation";
import { ProgramType } from "@/types/programs";
import { useFormStore } from "@/contexts/paymentFlowContext";
import { useGetAuthenticatedUser } from "@/api/user";
import { getLoginURL } from "@/utils/auth";
import { useRouter } from "@tanstack/react-router";
import SuccessPayment from "@/components/PaymentFlow/SuccessPayment";

interface ChallengeGratitudeProps {
  readonly program: ProgramType;
}

const ChallengeGratitude: React.FC<ChallengeGratitudeProps> = ({ program }) => {
  const { t } = useTranslation();
  const { setDonationType } = useFormStore();
  const { data: user } = useGetAuthenticatedUser();
  const { navigate } = useRouter();

  useEffect(() => {
    setDonationType(undefined);
  }, []);

  const handleOnclickCreateAccount = () => {
    const redirecturl = getLoginURL("", window.location.origin, true);
    window.location.href = redirecturl;
  };

  return (
    <div className="flex h-full w-full flex-col items-start justify-start gap-4 p-4 md:gap-8 md:pt-14">
      <h1 className="pt-20 text-xl font-bold text-foreground md:pt-0 md:text-4xl">
        {t("challenge.preferenceConfirmed")}
      </h1>
      <p className="font-medium text-foreground">
        {t("challenge.preferenceConfirmedDescription")}
      </p>
      {user ? (
        <></>
      ) : (
        <SuccessPayment
          handleOnclickCreateAccount={handleOnclickCreateAccount}
        />
      )}
      <hr className="border-t-1 w-full border-gray-300 text-foreground" />
      <div className="flex flex-col gap-8 pb-14">
        <h3 className="text-xl font-bold text-foreground">
          {t("paymentState.MoreCauses")}
        </h3>
        <ProgramRecommendation program={program} />
      </div>
      {user && (
        <div className="fixed bottom-0 left-0 right-0 flex w-full justify-end border-t border-[#CCC2BF] bg-background p-6">
          <Button
            type="submit"
            className="h-full w-full bg-primary px-10 py-4 font-[650] text-foreground hover:bg-foreground hover:text-light sm:w-[14rem]"
            onClick={() =>
              navigate({
                to: "/donor-profile",
                search: { page: "challenges" },
              })
            }
          >
            {t("challenge.goToMyProfile")}
          </Button>
        </div>
      )}
    </div>
  );
};

export default ChallengeGratitude;
