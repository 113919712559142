import { type FC, useState } from "react";
import ShareController from "./ShareController";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { FacebookWhiteIcon } from "@/assets/icons/social/FacebookWhiteIcon";
import { XWhiteIcon } from "@/assets/icons/social/XWhiteIcon";
import { WhatsappWhiteIcon } from "@/assets/icons/social/WhatsappWhiteIcon";
import { LinkedinWhiteIcon } from "@/assets/icons/social/LinkedinWhiteIcon";
import { CopyIcon } from "lucide-react";
import { cn } from "@/lib/utils";
import { Button } from "../ui/button";
import { EmailWhiteIcon } from "@/assets/icons/social/EmailWhiteIcon";
import ReactGA from "react-ga4";
import { isRTLLanguage } from "@/utils/helpers";
import { useTranslation } from "react-i18next";

interface GenericShareProps {
  buttonStyle: string;
  utm: string;
  shareData: ShareProps;
  label?: string;
}

export const GenericShare = ({
  buttonStyle,
  utm,
  shareData,
  label,
}: GenericShareProps) => {
  return (
    <>
      <div className="align-center flex w-full flex-row items-center justify-between gap-4 overflow-scroll">
        <FacebookShareButton
          url={`${shareData.url}${utm}&utm_source=facebook`}
          title={shareData.text}
          className={buttonStyle}
        >
          <ButtonComponent title="Facebook" children={<FacebookWhiteIcon />} />
        </FacebookShareButton>
        <TwitterShareButton
          url={`${shareData.url}${utm}&utm_source=x`}
          title={shareData.text}
          className={buttonStyle}
        >
          <ButtonComponent title="X" children={<XWhiteIcon />} />
        </TwitterShareButton>
        <WhatsappShareButton
          url={`${shareData.url}${utm}&utm_source=whatsapp`}
          title={shareData.text}
          className={buttonStyle}
        >
          <ButtonComponent title="Whatsapp" children={<WhatsappWhiteIcon />} />
        </WhatsappShareButton>
        <LinkedinShareButton
          url={`${shareData.url}${utm}&utm_source=linkedin`}
          title={shareData.title}
          summary={shareData.text}
          className={buttonStyle}
        >
          <ButtonComponent title="LinkedIn" children={<LinkedinWhiteIcon />} />
        </LinkedinShareButton>
        <EmailShareButton
          url={`${shareData.url}${utm}&utm_source=email`}
          subject={shareData.title}
          body={shareData.text}
          className={buttonStyle}
        >
          <ButtonComponent title="Email" children={<EmailWhiteIcon />} />
        </EmailShareButton>
      </div>
      <div className="flex w-full flex-col items-start gap-2">
        {label && <p className="text-sm font-[650]">{label}</p>}
        <div
          className={cn(
            "items-center min-h-[53px] flex flex-row justifybetween w-full bg-[#F4F3F2",
          )}
        >
          <p className="flex h-full w-full items-center truncate rounded-l rounded-r-none border-0 bg-[#F4F3F2] p-4 text-sm rtl:rounded-r">
            {shareData.url}
          </p>
          <button
            className="flex h-full items-center rounded-r bg-[#F4F3F2] p-4 hover:bg-[#F8F7F7] rtl:rounded-l"
            onClick={() => {
              navigator.clipboard.writeText(shareData.url);
            }}
            aria-label="Copy Program URL"
          >
            <CopyIcon />
          </button>
        </div>
      </div>
    </>
  );
};

type ShareProps = {
  title: string;
  text?: string;
  url: string;
};

interface Props {
  programId: string;
  children: React.ReactNode;
  shareData: ShareProps;
  onSuccess?: () => void;
  onError?: (error?: unknown) => void;
  onInteraction?: () => void;
  disabled?: boolean;
}

type ButtonComponentProps = {
  readonly children: React.ReactNode;
  readonly title: string;
};

export const ButtonComponent = ({ title, children }: ButtonComponentProps) => {
  return (
    <>
      <Button
        variant="ghost"
        size="icon"
        className="h-full w-full rounded-full bg-[#F4F3F2] p-6 hover:bg-[#F8F7F7]"
        aria-label={`Share Program on ${title}`}
        onClick={() => {
          ReactGA.event({
            category: "Social Media Share",
            action: "social_media_share",
            label: title,
          });
        }}
      >
        {children}
      </Button>
      <p className="text-center text-sm">{title}</p>
    </>
  );
};

const Share: FC<Props> = ({
  programId,
  children,
  shareData,
  onInteraction,
  onSuccess,
  onError,
  disabled,
}) => {
  const [openPopup, setOpenPopup] = useState(false);
  const { i18n } = useTranslation();
  const handleNonNativeShare = () => {
    setOpenPopup(true);
  };
  const buttonStyle = "flex flex-col items-center gap-2";
  const utm = `/?utm_medium=social&utm_campaign=share&utm_id=${programId}`;

  return (
    <>
      <ShareController
        shareData={shareData}
        onInteraction={onInteraction}
        onSuccess={onSuccess}
        onError={onError}
        onNonNativeShare={handleNonNativeShare}
        disabled={disabled}
      >
        {children}
      </ShareController>
      <Dialog open={openPopup} onOpenChange={setOpenPopup}>
        <DialogContent
          dir={isRTLLanguage(i18n.language) ? "rtl" : "ltr"}
          className={
            "mt-80 flex h-screen flex-col justify-start gap-8 rounded-2xl bg-[#FAFAFA] p-8 sm:mt-0 sm:h-[317px] sm:w-[470px] sm:items-start"
          }
        >
          <GenericShare
            buttonStyle={buttonStyle}
            utm={utm}
            shareData={shareData}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Share;
