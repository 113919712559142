import {
  ItemType,
  LivestockType,
  ProgramType,
  SuggestedAmountType,
} from "@/types/programs";
import FirstStep from "./Steps/FirstStep";
import LastStep from "./Steps/SelectPaymentStep";
import DetailsStep from "./Steps/EnterDetailsStep";
import { useFormStore } from "@/contexts/paymentFlowContext";
import { useEffect } from "react";

type StepsContainerProps = {
  readonly step?: number;
  readonly program: ProgramType;
  readonly suggestedAmounts?: SuggestedAmountType[];
  readonly livestock?: LivestockType[];
  readonly items?: ItemType[];
};

const StepsContainer = ({
  step,
  program,
  suggestedAmounts,
  livestock,
  items,
}: StepsContainerProps) => {
  const { setFormError } = useFormStore();

  useEffect(() => {
    setFormError(false);
  }, [step]);

  return (
    <div className="flex h-full flex-col items-center bg-background ">
      {step === 1 && (
        <FirstStep
          program={program}
          suggestedAmounts={suggestedAmounts}
          livestockList={livestock}
          items={items}
        />
      )}
      {step === 2 && <LastStep />}
      {step === 3 && <DetailsStep />}
    </div>
  );
};

export default StepsContainer;
