import { Progress } from "@/components/ui/progress";
import clsx from "clsx";
import { ProgramType } from "@/types/programs";
import NumberTicker from "@/components/Helpers/NumberTicker";
import useDonationData from "../useDonationData";
import Share from "@/components/Share/Share";
import { ShareIcon } from "@/assets/icons/ShareIcon";
import ReactGA from "react-ga4";
import { formatCurrency, getProgramGoal } from "@/utils/currency";
import { Button } from "@/components/ui/button";
import { useRouter } from "@tanstack/react-router";
import { shareDataFormatter } from "@/utils/share";
import { useFormStore } from "@/contexts/paymentFlowContext";

interface DonationBoxProps {
  bgColor: string;
  className?: string;
  program: ProgramType;
  specialHeader?: boolean;
}

export const DonationBox = ({
  bgColor,
  className,
  program,
  specialHeader,
}: DonationBoxProps) => {
  const { t, raisedAmount, raisedPercentage } = useDonationData(program);
  const { reset } = useFormStore();

  const shareData = shareDataFormatter(program);

  const handleSuccess = () => {
    ReactGA.event({
      category: "Social Media Share",
      action: "social_media_share",
      label: "Mobile",
    });
  };

  const { navigate } = useRouter();

  const handleDonateNow = () => {
    reset();
    if (program) {
      navigate({
        to: `/paymentFlow/${program.id}`,
        search: { backUrl: location.pathname },
      });
    }
  };

  return (
    <div
      className={clsx(
        "hidden md:inline-flex",
        {
          "sticky top-0 flex w-full justify-between py-6 ": !specialHeader,
        },
        className,
      )}
      style={{
        backgroundColor: !specialHeader ? bgColor : "auto",
        gap: !specialHeader ? "72px" : "auto",
      }}
    >
      {!specialHeader && (
        <div
          className={clsx("flex-8 flex w-full flex-col", {
            dark: !program.is_special,
          })}
        >
          {program.is_special ? (
            <h3 className="text-[32px] font-bold">{program.title}</h3>
          ) : (
            <>
              <div className="flex justify-between">
                <div className="flex items-center space-x-3">
                  <p className="font-light text-secondary">
                    {t("ProgramCard.raised_label")}
                  </p>
                  <p className="text-xl font-semibold text-secondary">
                    <NumberTicker
                      value={raisedAmount}
                      initialValue={raisedAmount}
                    />
                    ({raisedPercentage.toFixed(1)}%)
                  </p>
                </div>
                <div className="flex items-center space-x-3">
                  <p className="font-light text-secondary">
                    {t("ProgramCard.goal_label")}
                  </p>
                  <p className="text-xl font-semibold text-secondary">
                    {formatCurrency(getProgramGoal(program))}
                  </p>
                </div>
              </div>
              <Progress
                value={raisedPercentage}
                className="mt-1 h-[6px] rounded-[2px] bg-[#D6D9DC] rtl:scale-x-[-1] rtl:transform"
                aria-label="Donation amount progress bar"
              />
            </>
          )}
        </div>
      )}
      <div className="flex-2 flex items-center gap-x-4">
        <Button
          className="h-[54px] w-full px-5 py-4 font-bold md:min-w-[170px]"
          onClick={handleDonateNow}
        >
          {t("ProgramCard.donate_now")}
        </Button>
        <Share
          shareData={shareData}
          programId={program.id}
          onSuccess={handleSuccess}
        >
          <ShareIcon
            className={`${specialHeader ? "fill-[#fafafa] hover:fill-[#22222A]" : "fill-[#22222A]"}`}
          />
        </Share>
      </div>
    </div>
  );
};
