import { useTranslation } from "react-i18next";
import { QuickDonation } from "@/components/Navbar/QuickDonation";

export const TitleSection = () => {
  const { t } = useTranslation();
  return (
    <section className="flex h-full w-full flex-shrink-0 flex-col items-start justify-end gap-[1rem] self-stretch p-4  md:gap-[2rem] md:p-[24px]">
      <div className=" flex flex-col md:flex-row md:items-center md:gap-2">
        <h1 className="z-20 w-full text-4xl font-bold text-foreground md:w-4/6 md:text-7xl lg:text-8xl 2xl:text-8xl ">
          {t("title")}
        </h1>
      </div>
      <div className="z-20 block w-full md:hidden">
        <QuickDonation />
      </div>
    </section>
  );
};
