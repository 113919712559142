import { AppShell } from "@/components/Navbar";
import { useTranslation } from "react-i18next";
import { LayoutErrorPages } from "../sections/layout";

export const ServerError = () => {
  const { t } = useTranslation();

  return (
    <>
      <AppShell theme="white" navbarClass="top-0 absolute w-full" />

      <LayoutErrorPages
        title={t("serverError.title")}
        message={t("serverError.message")}
        description={t("serverError.description")}
        backHomeText={t("notFound.backHome")}
      />
    </>
  );
};
