import { Button } from "../ui/button";
import { Link } from "@tanstack/react-router";
import { X } from "lucide-react";
import { useTranslation } from "react-i18next";

type FailedPaymentProps = {
  readonly responseMessage?: string;
  readonly handleOnclickRetry?: () => void;
};

export default function FailedPayment({
  responseMessage,
  handleOnclickRetry,
}: FailedPaymentProps) {
  const { t } = useTranslation();
  return (
    <>
      <div className="flex h-14 w-14 items-center justify-center rounded-full bg-[#E4E1D8]">
        <X className="h-5 w-5 stroke-foreground" />
      </div>
      <h1 className="text-5xl font-bold text-foreground">
        {t("paymentState.paymentFailed")}
      </h1>
      <p className="text-start text-base font-medium text-foreground">
        {t("paymentState.paymentFailedMessage")}
        <br />
        {responseMessage && (
          <span className="mt-1">
            <b>{t("paymentState.reason")}: </b>
            {decodeURIComponent(responseMessage).replace(/\+/g, " ")}
          </span>
        )}
      </p>
      <div className="flex flex-col gap-4">
        <Button
          className="max-w-[186px] px-10 py-6 font-bold"
          onClick={() => handleOnclickRetry && handleOnclickRetry()}
        >
          {t("paymentState.retryPaymentButton")}
        </Button>
        <p className="font-medium text-foreground">
          {t("paymentState.contactSupport")}{" "}
          <Link
            to="/contact"
            className="text-foreground underline"
            aria-label="Check payment state"
          >
            {t("paymentState.here")}
          </Link>
        </p>
      </div>
    </>
  );
}
