import PackageIcon from "@/assets/icons/packageIcon";
import { PlayButtonIcon } from "@/assets/icons/social/PlayButtonIcon";
import SoupIcon from "@/assets/icons/soupIcon";
import { ShareIcon } from "@/assets/icons/ShareIcon";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "@/components/ui/card";
import { cn } from "@/lib/utils";
import {
  StatisticContentType,
  UpdateContentType,
  UpdateType,
  UpdateViewType,
} from "@/types/programs";
import { convertDjangoDateToReadable } from "@/utils/challenge";
import Share from "@/components/Share/Share";
import ReactGA from "react-ga4";
import { shareDataFormatter } from "@/utils/share";
import { bgOverlayStyle } from "@/utils/helpers";
import clsx from "clsx";
import dayjs from "dayjs";
import { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

type UpdateCardProps = {
  update: UpdateType;
  bgColor: string;
  showYearOnly?: boolean;
  size?: string;
  isChallenge?: boolean;
};

const monthsArabic =
  "يناير_فبراير_مارس_أبريل_مايو_يونيو_يوليو_أغسطس_سبتمبر_أكتوبر_نوفمبر_ديسمبر".split(
    "_",
  );

const toArabicNumeral = (numStr: string) =>
  numStr.replace(/\d/g, (digit) => "٠١٢٣٤٥٦٧٨٩"[Number(digit)]);

export const UpdateCard = ({
  update,
  bgColor,
  showYearOnly = false,
  size,
  isChallenge,
}: UpdateCardProps) => {
  const { t, i18n } = useTranslation();
  const [isMore, setIsMore] = useState(false);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const playButtonRef = useRef<HTMLButtonElement | null>(null);

  const handleSuccess = () => {
    ReactGA.event({
      category: "Social Media Share",
      action: "social_media_share",
      label: "Mobile",
    });
  };

  const formatDate = (dateString: string) => {
    if (showYearOnly) {
      return dayjs(dateString).format("YYYY");
    }

    let formattedDate;

    if (dayjs(dateString).isToday()) {
      formattedDate = t("Today");
    } else if (dayjs(dateString).isYesterday()) {
      formattedDate = t("Yesterday");
    } else if (i18n.language === "ar") {
      dayjs.locale("ar");
      const date = dayjs(dateString);
      const day = toArabicNumeral(date.format("D"));
      const month = monthsArabic[date.month()];
      const year = toArabicNumeral(date.format("YYYY"));
      formattedDate = `${day} ${month}, ${year}`;
    } else {
      dayjs.locale("en");
      formattedDate = dayjs(dateString).format("D MMMM, YYYY");
    }
    return formattedDate;
  };
  const mainImage = update.media?.find(
    (p) => p.file_type === "main_picture",
  )?.minio_url;
  const video = update.media?.find((p) => p.file_type === "video")?.minio_url;
  const cardStyle = useMemo(() => {
    if (update.view === UpdateViewType.PORTRAIT) {
      return {
        backgroundImage: mainImage ? bgOverlayStyle(mainImage) : undefined,
      };
    }
    return {
      backgroundColor: bgColor,
    };
  }, [update]);

  const handlePlay = () => {
    videoRef.current?.play();
    playButtonRef.current?.classList.add("opacity-0");
  };

  const handlePause = () => {
    videoRef.current?.pause();
    playButtonRef.current?.classList.remove("opacity-0");
  };

  return (
    <Card
      className={cn(
        `relative flex flex-col rounded-lg border-0 bg-cover bg-center ${size ?? "w-[249px] md:w-[358px] oveflow-hidden"}`,
        {
          "[&_*]:text-white":
            update.view === UpdateViewType.PORTRAIT &&
            update.update_type === UpdateContentType.MEDIA,
          "bg-black": update.view === UpdateViewType.PORTRAIT && video,
        },
      )}
      style={cardStyle}
    >
      {video && update.view === UpdateViewType.PORTRAIT && (
        <div className="absolute inset-0 z-0 min-h-full">
          <div className="video-overlay rounded-lg" onClick={handlePause}></div>
          <button
            ref={playButtonRef}
            className="video-play-button transition-opacity rtl:right-4"
            onClick={() =>
              videoRef.current?.paused ? handlePlay() : handlePause()
            }
          >
            <PlayButtonIcon />
          </button>
          <video
            ref={videoRef}
            className="absolute z-0 my-auto max-h-full min-h-full max-w-full rounded-lg object-cover"
          >
            <source src={video} />
          </video>
        </div>
      )}
      <CardHeader className="z-[4]">
        {isChallenge ? (
          <>
            <h3 className="text-sm font-[350]">{update.update_type}</h3>
            <h3 className="font-[650]">
              {convertDjangoDateToReadable(update.date)}
            </h3>
          </>
        ) : (
          <h3 className="text-sm font-medium">{formatDate(update.date)}</h3>
        )}
      </CardHeader>
      <CardContent className="relative z-[4] overflow-y-auto">
        <div className="mb-2 md:mb-4">
          <p className="inline text-xs md:text-base">
            {isMore ? update.content : update.content.substring(0, 150)}
          </p>
          {update.content.length > 150 && (
            <button
              onClick={() => setIsMore(true)}
              className={clsx(
                { hidden: isMore },
                "text-xs font-bold md:text-base",
              )}
            >
              <span className="font-normal">{"... "}</span>
              {t("update.more")}
            </button>
          )}
        </div>
        {video && update.view === UpdateViewType.STACKED && (
          <div className="relative h-[200px] w-full overflow-hidden rounded md:h-[300px]">
            <div className="video-overlay" onClick={handlePause}></div>
            <button
              ref={playButtonRef}
              className="video-play-button transition-opacity"
              onClick={() =>
                videoRef.current?.paused ? handlePlay() : handlePause()
              }
            >
              <PlayButtonIcon />
            </button>
            <video ref={videoRef} className="h-full min-w-full object-cover">
              <source src={video} />
            </video>
          </div>
        )}
        {update.update_type === UpdateContentType.MEDIA &&
          mainImage &&
          update.view === UpdateViewType.STACKED && (
            <img
              src={mainImage}
              alt="Update Image"
              className="rounded-m h-[200px] w-full rounded-md object-cover object-center md:mb-4 md:h-[300px]"
            />
          )}
        {update.update_type === UpdateContentType.STATISTIC && (
          <div className="mb-4 grid gap-2">
            {!video &&
              update.statistics?.map((stat) => (
                <div
                  key={`update-${stat.label}`}
                  className="flex flex-col gap-6 rounded-lg bg-foreground p-6 [&_div]:text-background"
                >
                  {stat.label === StatisticContentType.MEAL ? (
                    <>
                      <div className="mr-4 text-base">
                        <SoupIcon />
                      </div>
                      <div className="flex flex-col gap-2">
                        <div className="text-4xl font-bold">{stat.value}</div>
                        <div className="text-sm">
                          {t("update.meal_provision")}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="mr-4 text-base">
                        <PackageIcon />
                      </div>
                      <div className="flex flex-col gap-2">
                        <div className="text-4xl font-bold">{stat.value}</div>
                        <div className="text-sm">
                          {t("update.box_provision")}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              ))}
          </div>
        )}
      </CardContent>
      <CardFooter className="mt-auto flex flex-col">
        <div className="flex w-full items-center justify-end gap-2">
          {update.program && (
            <Share
              shareData={shareDataFormatter(update.program)}
              programId={update.program.id}
              onSuccess={handleSuccess}
            >
              <ShareIcon className="fill-[#22222A]" />
            </Share>
          )}
        </div>
      </CardFooter>
    </Card>
  );
};
