import { Skeleton } from "@/components/ui/skeleton";
import { bgOverlayStyle } from "@/utils/helpers";
import { useNavigate } from "@tanstack/react-router";
import { useEffect, useState } from "react";
import { SupportersDisplay } from "./SupportersDisplay";
import { ChallengeDonationBox } from "./ChallengeDonationBox";
import SmallChallengeDonationBox from "./SmallChallengeDonationBox";
import { ChallengeType } from "@/types/challenge";
export const HeroSection = ({
  challenge,
  isLoading,
  isError,
}: {
  readonly challenge: ChallengeType;
  readonly isLoading: boolean;
  readonly isError: Error | null;
}) => {
  const navigate = useNavigate();
  const [imageLoaded, setImageLoaded] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    if (challenge.program?.main_image?.large_url) {
      const img = new Image();
      img.src = challenge.program.main_image.large_url;
      img.onload = () => {
        setFadeOut(true);
        setImageLoaded(true);
      };
    }
  }, [challenge]);

  if (isLoading) {
    return (
      <Skeleton className="animated-skeleton h-[60vh] pt-36 md:h-[calc(100vh-5rem)]" />
    );
  }

  if (isError) {
    navigate({ to: "/" });
  }

  return challenge ? (
    <section
      className="dark relative h-[60vh] w-full !bg-cover pt-36 md:h-[calc(100vh-5rem)] md:min-h-[620px]"
      style={{
        background: bgOverlayStyle(
          challenge.challenge_picture
            ? challenge.challenge_picture?.large_url
            : challenge.program.main_image?.large_url,
        ),
        backgroundPosition: "center center",
      }}
    >
      {!imageLoaded && (
        <div
          className={`absolute inset-0 !bg-cover blur-md transition-opacity duration-1000 ${
            fadeOut ? "opacity-0" : "opacity-100"
          }`}
          style={{
            background: bgOverlayStyle(
              challenge.challenge_picture
                ? challenge.challenge_picture?.thumbnail_url
                : challenge.program.main_image?.thumbnail_url,
            ),
            backgroundPosition: "center center",
          }}
        />
      )}
      <div className="flex h-full flex-col justify-end px-4 md:px-[24px]">
        <div className="my-8 flex w-3/6 flex-col gap-6">
          <div className="flex flex-col items-start">
            <h4 className="text-lg font-[350] leading-[110%] text-foreground md:text-2xl">
              {challenge.program.title}
            </h4>
            <h2 className="text-2xl font-[650] leading-[120%] text-foreground md:text-5xl 2xl:text-[4rem]">
              {challenge.name}
            </h2>
          </div>
          <SupportersDisplay
            supporters={challenge.supporters}
            count={challenge.supporters_count}
          />
        </div>
        <ChallengeDonationBox
          bgColor={challenge.program.color_third}
          className="mb-5 hidden rounded-lg p-6 md:block"
          challenge={challenge}
        />
      </div>
      <SmallChallengeDonationBox
        bgColor={challenge.program.color_third}
        className="md:hidden"
        challenge={challenge}
      />
    </section>
  ) : (
    <></>
  );
};
