"use client";

import * as React from "react";
import useEmblaCarousel, {
  type UseEmblaCarouselType,
} from "embla-carousel-react";
import { useTranslation } from "react-i18next";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import CarouselArrowR from "@/assets/icons/CarouselArrowR";
import CarouselArrowL from "@/assets/icons/CarouselArrowL";

type CarouselApi = UseEmblaCarouselType[1];
type UseCarouselParameters = Parameters<typeof useEmblaCarousel>;
type CarouselOptions = UseCarouselParameters[0];
type CarouselPlugin = UseCarouselParameters[1];

type CarouselProps = {
  opts?: CarouselOptions;
  plugins?: CarouselPlugin;
  orientation?: "horizontal" | "vertical";
  hideArrows?: boolean;
  setApi?: (api: CarouselApi) => void;
};

type CarouselContextProps = {
  carouselRef: ReturnType<typeof useEmblaCarousel>[0];
  api: ReturnType<typeof useEmblaCarousel>[1];
  scrollPrev: () => void;
  scrollNext: () => void;
  canScrollPrev: boolean;
  canScrollNext: boolean;
  hasOverflow: boolean;
  hideArrows: boolean;
} & CarouselProps;

const CarouselContext = React.createContext<CarouselContextProps | null>(null);

function useCarousel() {
  const context = React.useContext(CarouselContext);
  if (!context) {
    throw new Error("useCarousel must be used within a <Carousel />");
  }
  return context;
}

const Carousel = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & CarouselProps
>(
  (
    {
      orientation = "horizontal",
      hideArrows = false,
      opts,
      setApi,
      plugins,
      className,
      children,
      ...props
    },
    ref,
  ) => {
    const { i18n } = useTranslation();
    const isRTL = i18n.language === "ar";
    const [carouselRef, api] = useEmblaCarousel(
      {
        ...opts,
        axis: orientation === "horizontal" ? "x" : "y",
        direction: isRTL ? "rtl" : "ltr",
        dragFree: true,
      },
      plugins,
    );
    const [canScrollPrev, setCanScrollPrev] = React.useState(false);
    const [canScrollNext, setCanScrollNext] = React.useState(false);
    const [hasOverflow, setHasOverflow] = React.useState(false);

    const onSelect = React.useCallback((api: CarouselApi) => {
      if (!api) {
        return;
      }
      setCanScrollPrev(api.canScrollPrev());
      setCanScrollNext(api.canScrollNext());
    }, []);

    const onInit = React.useCallback(
      (api: CarouselApi) => {
        if (!api) {
          return;
        }
        setHasOverflow(api.scrollSnapList().length > 1);
        onSelect(api);
      },
      [onSelect],
    );

    const scrollPrev = React.useCallback(() => {
      api?.scrollPrev();
    }, [api]);

    const scrollNext = React.useCallback(() => {
      api?.scrollNext();
    }, [api]);

    const handleKeyDown = React.useCallback(
      (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === "ArrowLeft") {
          event.preventDefault();
          scrollPrev();
        } else if (event.key === "ArrowRight") {
          event.preventDefault();
          scrollNext();
        }
      },
      [scrollPrev, scrollNext],
    );

    React.useEffect(() => {
      if (!api || !setApi) {
        return;
      }
      setApi(api);
    }, [api, setApi]);

    React.useEffect(() => {
      if (!api) {
        return;
      }
      onInit(api);
      api.on("reInit", onInit);
      api.on("select", onSelect);

      return () => {
        api?.off("select", onSelect);
        api?.off("reInit", onInit);
      };
    }, [api, onInit, onSelect]);

    return (
      <CarouselContext.Provider
        value={{
          carouselRef,
          api: api,
          opts,
          orientation:
            orientation || (opts?.axis === "y" ? "vertical" : "horizontal"),
          scrollPrev,
          scrollNext,
          canScrollPrev,
          canScrollNext,
          hasOverflow,
          hideArrows,
        }}
      >
        <div
          ref={ref}
          onKeyDownCapture={handleKeyDown}
          className={cn("relative", className)}
          role="region"
          aria-roledescription="carousel"
          {...props}
        >
          {children}
        </div>
      </CarouselContext.Provider>
    );
  },
);
Carousel.displayName = "Carousel";

const CarouselContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  const { carouselRef, orientation } = useCarousel();

  return (
    <div ref={carouselRef} className="overflow-hidden">
      <div
        ref={ref}
        className={cn(
          "flex gap-4",
          orientation === "horizontal" ? "" : "flex-col",
          className,
        )}
        {...props}
      />
    </div>
  );
});
CarouselContent.displayName = "CarouselContent";

const CarouselItem = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  const { orientation } = useCarousel();

  return (
    <div
      ref={ref}
      role="group"
      aria-roledescription="slide"
      className={cn("", orientation === "horizontal" ? "" : "", className)}
      {...props}
    />
  );
});
CarouselItem.displayName = "CarouselItem";

const CarouselPrevious = React.forwardRef<
  HTMLButtonElement,
  React.ComponentProps<typeof Button>
>(({ className, ...props }) => {
  const { orientation, hideArrows, scrollPrev, canScrollPrev, hasOverflow } =
    useCarousel();
  const { i18n } = useTranslation();

  const isRTL = i18n.language === "ar";

  if (!hasOverflow) {
    return null;
  }

  return (
    <div
      className={cn(
        "absolute h-12 w-12",
        orientation === "horizontal"
          ? isRTL
            ? "right-3 top-1/2 transform -translate-y-1/2 "
            : "left-3 top-1/2 transform -translate-y-1/2 "
          : "top-0 left-1/2 transform -translate-x-1/2",
      )}
    >
      <Button
        size="icon"
        className={cn(
          "bg-[#fafafa] hover:bg-[#fafafa] h-full w-full",
          hideArrows ? "hidden" : "",
          canScrollPrev ? "opacity-80" : "opacity-50",
          className,
        )}
        aria-label="Go to category page"
        disabled={!canScrollPrev}
        onClick={scrollPrev}
        style={{ pointerEvents: canScrollPrev ? "auto" : "none" }}
        {...props}
      >
        {isRTL ? <CarouselArrowR /> : <CarouselArrowL />}
      </Button>
    </div>
  );
});
CarouselPrevious.displayName = "CarouselPrevious";

const CarouselNext = React.forwardRef<
  HTMLButtonElement,
  React.ComponentProps<typeof Button>
>(({ className, ...props }) => {
  const { orientation, hideArrows, scrollNext, canScrollNext, hasOverflow } =
    useCarousel();
  const { i18n } = useTranslation();

  const isRTL = i18n.language === "ar";

  if (!hasOverflow) {
    return null;
  }

  return (
    <div
      className={cn(
        "absolute h-12 w-12",
        orientation === "horizontal"
          ? isRTL
            ? "left-3 top-1/2 transform -translate-y-1/2 "
            : "right-3 top-1/2 transform -translate-y-1/2 "
          : "bottom-0 left-1/2 transform -translate-x-1/2",
      )}
    >
      <Button
        size="icon"
        className={cn(
          "bg-[#fafafa] hover:bg-[#fafafa] h-full w-full",
          hideArrows ? "hidden" : "",
          canScrollNext ? "opacity-90" : "opacity-20",
          className,
        )}
        aria-label="Go to category page"
        disabled={!canScrollNext}
        onClick={scrollNext}
        style={{ pointerEvents: canScrollNext ? "auto" : "none" }}
        {...props}
      >
        {isRTL ? <CarouselArrowL /> : <CarouselArrowR />}
      </Button>
    </div>
  );
});
CarouselNext.displayName = "CarouselNext";

export {
  type CarouselApi,
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselPrevious,
  CarouselNext,
};
