import { createFileRoute } from "@tanstack/react-router";
import React from "react";

export const Route = createFileRoute("/challenge/$challengeId")({
  component: React.lazy(() =>
    import("@/pages/community-challenge/Challenge").then((mod) => ({
      default: mod.Challenge,
    })),
  ),
});
