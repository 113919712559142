import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Input } from "@/components/ui/input";
import { cn } from "@/lib/utils";
import { isRTLLanguage } from "@/utils/helpers";
import { useTranslation } from "react-i18next";

import { Circle, CircleCheck } from "lucide-react";

const useDebounce = (fn: Function, delay: number) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  return useCallback(
    (...args: any[]) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        fn(...args);
      }, delay);
    },
    [fn, delay],
  );
};

interface CommonSelectFieldProps {
  options: { value: string; label: string }[];
  placeholder?: string;
  searchable?: boolean;
  searchPlaceholder?: string;
  label?: string;
  optional?: boolean;
  name: string;
  value: string | string[];
  onChange: (value: string | string[]) => void;
  onBlur?: React.FocusEventHandler<
    HTMLInputElement | HTMLButtonElement | HTMLSelectElement
  >;
  error?: string;
  multiSelect?: boolean;
  onScroll?: () => void;
  className?: string;
  maxHeight?: string;
}

const CommonSelectField: React.FC<CommonSelectFieldProps> = ({
  options,
  placeholder,
  searchable = false,
  searchPlaceholder,
  label,
  name,
  value,
  onChange,
  onBlur,
  error,
  optional,
  multiSelect = false,
  className,
  maxHeight,
  onScroll,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [debouncedInputValue, setDebouncedInputValue] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const { i18n } = useTranslation();

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  const debouncedSetInputValue = useDebounce((value: string) => {
    setDebouncedInputValue(value);
  }, 300);

  const filteredOptions = useMemo(() => {
    if (!searchable || !debouncedInputValue) {
      return options;
    }
    return options.filter((option) =>
      option.label.toLowerCase().startsWith(debouncedInputValue.toLowerCase()),
    );
  }, [searchable, debouncedInputValue, options]);

  const selectedOptions = useMemo(() => {
    if (!multiSelect) {
      return options.filter((o) => o.value === value);
    }
    return options.filter((o) => (value as string[]).includes(o.value));
  }, [options, value, multiSelect]);

  useEffect(() => {
    if (multiSelect) {
      setInputValue(selectedOptions.map((o) => o.label).join(", "));
    } else if (selectedOptions.length > 0) {
      setInputValue(selectedOptions[0].label);
    } else {
      setInputValue("");
    }
  }, [selectedOptions, multiSelect]);

  const handleSelect = useCallback(
    (newValue: string) => {
      if (multiSelect) {
        const currentValues = Array.isArray(value) ? value : [];
        const updatedValues = currentValues.includes(newValue)
          ? currentValues.filter((v) => v !== newValue)
          : [...currentValues, newValue];
        onChange(updatedValues);
      } else {
        onChange(newValue);
        setIsOpen(false);
      }
    },
    [onChange, multiSelect, value],
  );

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      setInputValue(newValue);
      debouncedSetInputValue(newValue);
      setIsOpen(true);

      if (!newValue && !multiSelect) {
        onChange("");
      }
    },
    [onChange, multiSelect, debouncedSetInputValue],
  );

  const toggleDropdown = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (!isOpen) {
        if (e.key === "Enter" || e.key === " " || e.key === "ArrowDown") {
          e.preventDefault();
          setIsOpen(true);
        }
      } else {
        if (e.key === "Escape") {
          setIsOpen(false);
        } else if (e.key === "ArrowDown" || e.key === "ArrowUp") {
          e.preventDefault();
          const currentIndex = filteredOptions.findIndex(
            (option) =>
              option.value === (Array.isArray(value) ? value[0] : value),
          );
          const direction = e.key === "ArrowDown" ? 1 : -1;
          const nextIndex =
            (currentIndex + direction + filteredOptions.length) %
            filteredOptions.length;
          handleSelect(filteredOptions[nextIndex].value);
        }
      }
    },
    [isOpen, filteredOptions, value, handleSelect],
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
        if (multiSelect) {
          setInputValue(selectedOptions.map((o) => o.label).join(", "));
        } else if (selectedOptions.length === 0) {
          setInputValue("");
        } else {
          setInputValue(selectedOptions[0].label);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectedOptions, multiSelect]);

  const handleFocus = () => {
    setIsOpen(true);
  };

  const handleBlur = (
    e: React.FocusEvent<
      HTMLInputElement | HTMLButtonElement | HTMLSelectElement
    >,
  ) => {
    if (onBlur) {
      onBlur(e);
    }
  };

  const renderMobileContent = () => (
    <div className="fixed inset-0 z-50 flex items-end justify-center bg-black bg-opacity-50">
      <div className="w-full overflow-hidden rounded-t-2xl bg-background">
        <div className="flex items-end justify-between border-b p-4">
          <button onClick={() => setIsOpen(false)} className="font-semibold">
            Cancel
          </button>
          <h2 className="text-xl font-bold">{label}</h2>
          <button onClick={() => setIsOpen(false)} className="font-semibold">
            Done
          </button>
        </div>
        {searchable && (
          <div className="p-4">
            <Input
              type="text"
              placeholder={searchPlaceholder || "Search..."}
              value={inputValue}
              onChange={handleInputChange}
              className="w-full rounded-lg"
            />
          </div>
        )}
        <div className="max-h-72 overflow-y-auto" onScroll={onScroll}>
          {filteredOptions.map((option) => (
            <button
              key={option.value}
              className={cn(
                "w-full text-left p-4 border-b",
                className,
                multiSelect
                  ? (value as string[]).includes(option.value) && "bg-white"
                  : value === option.value && "bg-white",
              )}
              onClick={() => handleSelect(option.value)}
            >
              {option.label}
              {multiSelect && (value as string[]).includes(option.value) && (
                <span className="ml-2">✓</span>
              )}
            </button>
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <div
      dir={isRTLLanguage(i18n.language) ? "rtl" : "ltr"}
      className="flex flex-col gap-2"
    >
      <label
        id={`${name}-label`}
        htmlFor={name}
        className="block text-xs font-bold text-foreground md:text-sm"
      >
        {label}
        {optional && (
          <span className="text-foreground opacity-50"> (optional)</span>
        )}
      </label>
      <div className="relative w-full" ref={dropdownRef}>
        {searchable ? (
          <div className="relative h-[58px] rounded-md border border-[#CCC2BF] p-2">
            <Input
              ref={inputRef}
              type="text"
              autoComplete="off"
              autoCapitalize="off"
              autoCorrect="off"
              spellCheck="false"
              data-lpignore="true"
              placeholder={searchPlaceholder || placeholder}
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onClick={() => setIsOpen(true)}
              className={cn(
                "block w-full pr-10 bg-[#E2DDD7] border-transparent h-[42px]",
                error ? "border-[#F04438]" : "",
              )}
              style={{
                color: inputValue ? "#232329" : undefined,
                fontWeight: inputValue ? "bold" : undefined,
              }}
              aria-autocomplete="list"
              aria-controls={`${name}-listbox`}
              aria-expanded={isOpen}
            />
            <ChevronDownIcon
              className="absolute right-3 top-1/2 h-5 w-5 -translate-y-1/2 cursor-pointer "
              onClick={toggleDropdown}
              aria-hidden="true"
            />
          </div>
        ) : (
          <div className="h-[58px] rounded-md border border-[#CCC2BF] p-2">
            <button
              type="button"
              onClick={() => setIsOpen(true)}
              onKeyDown={handleKeyDown}
              onFocus={handleFocus}
              onBlur={handleBlur}
              aria-haspopup="listbox"
              aria-expanded={isOpen}
              aria-labelledby={`${name}-label`}
              className={cn(
                "flex h-[42px] w-full items-center rounded-md border border-transparent bg-[#E2DDD7] p-2 md:text-base text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50",
                "cursor-pointer pr-10 rtl:pr-4",
                className,
                error ? "border-[#F04438]" : "",
              )}
              style={{
                color: selectedOptions.length > 0 ? "#232329" : undefined,
                fontWeight: selectedOptions.length > 0 ? "bold" : undefined,
              }}
            >
              <span
                className={`block truncate ${selectedOptions[0] ? "" : "text-muted-foreground"}`}
              >
                {multiSelect
                  ? selectedOptions.map((o) => o.label).join(", ") ||
                    placeholder
                  : selectedOptions[0]?.label || placeholder}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4 rtl:left-0 rtl:right-auto rtl:pl-4">
                <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
              </span>
            </button>
          </div>
        )}
        {isOpen &&
          (isMobile ? (
            renderMobileContent()
          ) : (
            <ul
              className={cn(
                "absolute z-20 top-2 left-2 max-h-60 overflow-hidden overflow-y-auto rounded-lg border border-[#CCC2BF] bg-background",
                maxHeight,
              )}
              style={{
                width: `calc(${dropdownRef.current?.offsetWidth}px - 16px)`,
              }}
              role="listbox"
              id={`${name}-listbox`}
              aria-labelledby={`${name}-label`}
              onScroll={onScroll}
            >
              {filteredOptions.length === 0 ? (
                <li className="px-4 py-2 text-gray-500">No results found</li>
              ) : (
                filteredOptions.map((option) => {
                  const isSelected = multiSelect
                    ? (value as string[]).includes(option.value)
                    : value === option.value;

                  return (
                    <li key={option.value} role="option">
                      <button
                        type="button"
                        className={`flex w-full items-center gap-2 px-4 py-2 text-start text-14px hover:font-bold ${
                          isSelected ? "font-bold" : ""
                        }`}
                        onClick={() => handleSelect(option.value)}
                        aria-selected={isSelected}
                      >
                        {isSelected ? (
                          <CircleCheck className="h-4 w-4 fill-[#232329] stroke-background" />
                        ) : (
                          <Circle className="h-4 w-4 stroke-[#C2B4B3]" />
                        )}
                        {option.label}
                      </button>
                    </li>
                  );
                })
              )}
            </ul>
          ))}
        {error && (
          <div className="mt-2 text-start text-sm text-[#F04438]">{error}</div>
        )}
      </div>
    </div>
  );
};

export default CommonSelectField;
