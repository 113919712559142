interface MarketsDetails {
  code: string;
  beneficiaries: string;
  products: number;
}

const allMarketsDetails: MarketsDetails[] = [
  { code: "O1004", beneficiaries: "133", products: 2587 },
  { code: "O1009", beneficiaries: "151", products: 16399 },
  { code: "O1011", beneficiaries: "185", products: 7306 },
  { code: "O1015", beneficiaries: "139", products: 17343 },
  { code: "O1017", beneficiaries: "34", products: 1149 },
  { code: "O1039", beneficiaries: "89", products: 8283 },
  { code: "O1041", beneficiaries: "88", products: 8414 },
  { code: "O1043", beneficiaries: "63", products: 3771 },
  { code: "O1058", beneficiaries: "73", products: 10878 },
  { code: "O1063", beneficiaries: "78", products: 5264 },
  { code: "O1067", beneficiaries: "113", products: 9578 },
  { code: "O1085", beneficiaries: "86", products: 3271 },
  { code: "O109", beneficiaries: "43", products: 2351 },
  { code: "O1097", beneficiaries: "89", products: 1510 },
  { code: "O111", beneficiaries: "250", products: 4078 },
  { code: "O116", beneficiaries: "97", products: 5623 },
  { code: "O118", beneficiaries: "108", products: 1872 },
  { code: "O133", beneficiaries: "43", products: 749 },
  { code: "O136", beneficiaries: "25", products: 418 },
  { code: "O147", beneficiaries: "3", products: 56 },
  { code: "O1522", beneficiaries: "226", products: 4090 },
  { code: "O1601", beneficiaries: "186", products: 11528 },
  { code: "O167", beneficiaries: "257", products: 34152 },
  { code: "O174", beneficiaries: "160", products: 5633 },
  { code: "O1849", beneficiaries: "44", products: 4179 },
  { code: "O186", beneficiaries: "150", products: 3086 },
  { code: "O1877", beneficiaries: "62", products: 1121 },
  { code: "O188", beneficiaries: "113", products: 4362 },
  { code: "O195", beneficiaries: "23", products: 483 },
  { code: "O203", beneficiaries: "247", products: 5182 },
  { code: "O207", beneficiaries: "41", products: 787 },
  { code: "O2080", beneficiaries: "83", products: 6111 },
  { code: "O210", beneficiaries: "54", products: 821 },
  { code: "O2158", beneficiaries: "90", products: 1635 },
  { code: "O222", beneficiaries: "92", products: 4420 },
  { code: "O247", beneficiaries: "54", products: 4458 },
  { code: "O2547", beneficiaries: "34", products: 659 },
  { code: "O2595", beneficiaries: "90", products: 6468 },
  { code: "O2617", beneficiaries: "95", products: 6797 },
  { code: "O269", beneficiaries: "137", products: 6664 },
  { code: "O2739", beneficiaries: "47", products: 3404 },
  { code: "O276", beneficiaries: "258", products: 4466 },
  { code: "O2804", beneficiaries: "126", products: 7924 },
  { code: "O3", beneficiaries: "84", products: 1454 },
  { code: "O303", beneficiaries: "127", products: 8083 },
  { code: "O3103", beneficiaries: "91", products: 1834 },
  { code: "O313", beneficiaries: "221", products: 25163 },
  { code: "O316", beneficiaries: "84", products: 3710 },
  { code: "O317", beneficiaries: "229", products: 37364 },
  { code: "O3177", beneficiaries: "76", products: 1428 },
  { code: "O318", beneficiaries: "47", products: 3640 },
  { code: "O320", beneficiaries: "28", products: 892 },
  { code: "O323", beneficiaries: "107", products: 3417 },
  { code: "O329", beneficiaries: "79", products: 1486 },
  { code: "O338", beneficiaries: "38", products: 3784 },
  { code: "O341", beneficiaries: "65", products: 2188 },
  { code: "O35", beneficiaries: "18", products: 322 },
  { code: "O3505", beneficiaries: "125", products: 2478 },
  { code: "O3539", beneficiaries: "76", products: 6532 },
  { code: "O356", beneficiaries: "179", products: 3088 },
  { code: "O359", beneficiaries: "323", products: 10531 },
  { code: "O361", beneficiaries: "90", products: 8134 },
  { code: "O362", beneficiaries: "63", products: 978 },
  { code: "O37", beneficiaries: "88", products: 8254 },
  { code: "O3736", beneficiaries: "48", products: 1003 },
  { code: "O376", beneficiaries: "83", products: 7624 },
  { code: "O379", beneficiaries: "99", products: 5031 },
  { code: "O386", beneficiaries: "87", products: 6391 },
  { code: "O3923", beneficiaries: "44", products: 2604 },
  { code: "O401", beneficiaries: "160", products: 10021 },
  { code: "O423", beneficiaries: "128", products: 15582 },
  { code: "O4233", beneficiaries: "27", products: 2645 },
  { code: "O4285", beneficiaries: "76", products: 2714 },
  { code: "O4297", beneficiaries: "45", products: 1810 },
  { code: "O4305", beneficiaries: "166", products: 21559 },
  { code: "O4306", beneficiaries: "197", products: 13790 },
  { code: "O431", beneficiaries: "51", products: 892 },
  { code: "O4310", beneficiaries: "146", products: 22161 },
  { code: "O4319", beneficiaries: "52", products: 1176 },
  { code: "O4339", beneficiaries: "35", products: 562 },
  { code: "O4362", beneficiaries: "75", products: 4507 },
  { code: "O4397", beneficiaries: "58", products: 5608 },
  { code: "O4406", beneficiaries: "83", products: 9250 },
  { code: "O4423", beneficiaries: "94", products: 1803 },
  { code: "O4427", beneficiaries: "35", products: 638 },
  { code: "O443", beneficiaries: "156", products: 3151 },
  { code: "O4434", beneficiaries: "58", products: 1919 },
  { code: "O4435", beneficiaries: "99", products: 10330 },
  { code: "O4439", beneficiaries: "348", products: 55187 },
  { code: "O4456", beneficiaries: "24", products: 535 },
  { code: "O446", beneficiaries: "34", products: 2908 },
  { code: "O4473", beneficiaries: "92", products: 1959 },
  { code: "O448", beneficiaries: "96", products: 5040 },
  { code: "O4503", beneficiaries: "96", products: 6291 },
  { code: "O4522", beneficiaries: "104", products: 6358 },
  { code: "O453", beneficiaries: "37", products: 649 },
  { code: "O4564", beneficiaries: "58", products: 1023 },
  { code: "O4571", beneficiaries: "97", products: 11500 },
  { code: "O4572", beneficiaries: "132", products: 11576 },
  { code: "O4577", beneficiaries: "142", products: 10413 },
  { code: "O459", beneficiaries: "79", products: 1436 },
  { code: "O4591", beneficiaries: "84", products: 6923 },
  { code: "O46", beneficiaries: "66", products: 7859 },
  { code: "O460", beneficiaries: "33", products: 642 },
  { code: "O4609", beneficiaries: "82", products: 1371 },
  { code: "O4614", beneficiaries: "85", products: 7852 },
  { code: "O4622", beneficiaries: "108", products: 1880 },
  { code: "O4628", beneficiaries: "145", products: 4551 },
  { code: "O4629", beneficiaries: "70", products: 2111 },
  { code: "O4633", beneficiaries: "65", products: 2022 },
  { code: "O4635", beneficiaries: "76", products: 1372 },
  { code: "O4640", beneficiaries: "27", products: 553 },
  { code: "O4641", beneficiaries: "78", products: 7458 },
  { code: "O4645", beneficiaries: "150", products: 11686 },
  { code: "O4651", beneficiaries: "79", products: 1360 },
  { code: "O4656", beneficiaries: "76", products: 5485 },
  { code: "O4686", beneficiaries: "114", products: 5000 },
  { code: "O4737", beneficiaries: "303", products: 30556 },
  { code: "O4761", beneficiaries: "63", products: 2060 },
  { code: "O484", beneficiaries: "66", products: 3026 },
  { code: "O4846", beneficiaries: "18", products: 340 },
  { code: "O485", beneficiaries: "159", products: 3279 },
  { code: "O4905", beneficiaries: "164", products: 2517 },
  { code: "O491", beneficiaries: "113", products: 9859 },
  { code: "O50", beneficiaries: "86", products: 2098 },
  { code: "O502", beneficiaries: "106", products: 8874 },
  { code: "O503", beneficiaries: "77", products: 1293 },
  { code: "O505", beneficiaries: "193", products: 5848 },
  { code: "O536", beneficiaries: "61", products: 1138 },
  { code: "O546", beneficiaries: "46", products: 741 },
  { code: "O55", beneficiaries: "30", products: 519 },
  { code: "O555", beneficiaries: "68", products: 5400 },
  { code: "O556", beneficiaries: "21", products: 306 },
  { code: "O557", beneficiaries: "173", products: 16798 },
  { code: "O563", beneficiaries: "187", products: 2925 },
  { code: "O573", beneficiaries: "64", products: 3637 },
  { code: "O578", beneficiaries: "109", products: 1594 },
  { code: "O585", beneficiaries: "115", products: 2033 },
  { code: "O588", beneficiaries: "105", products: 1674 },
  { code: "O596", beneficiaries: "47", products: 859 },
  { code: "O6", beneficiaries: "48", products: 827 },
  { code: "O60", beneficiaries: "123", products: 1835 },
  { code: "O608", beneficiaries: "37", products: 1389 },
  { code: "O612", beneficiaries: "57", products: 1843 },
  { code: "O619", beneficiaries: "53", products: 2432 },
  { code: "O621", beneficiaries: "56", products: 1843 },
  { code: "O625", beneficiaries: "61", products: 1883 },
  { code: "O627", beneficiaries: "260", products: 34968 },
  { code: "O633", beneficiaries: "59", products: 1912 },
  { code: "O640", beneficiaries: "94", products: 9185 },
  { code: "O645", beneficiaries: "36", products: 1225 },
  { code: "O654", beneficiaries: "23", products: 475 },
  { code: "O658", beneficiaries: "83", products: 8055 },
  { code: "O660", beneficiaries: "28", products: 2203 },
  { code: "O668", beneficiaries: "46", products: 1884 },
  { code: "O675", beneficiaries: "32", products: 540 },
  { code: "O676", beneficiaries: "212", products: 4948 },
  { code: "O685", beneficiaries: "41", products: 800 },
  { code: "O689", beneficiaries: "137", products: 8111 },
  { code: "O693", beneficiaries: "80", products: 1325 },
  { code: "O695", beneficiaries: "211", products: 27916 },
  { code: "O7", beneficiaries: "110", products: 5442 },
  { code: "O709", beneficiaries: "90", products: 8534 },
  { code: "O710", beneficiaries: "96", products: 6844 },
  { code: "O714", beneficiaries: "241", products: 4507 },
  { code: "O716", beneficiaries: "98", products: 7904 },
  { code: "O720", beneficiaries: "55", products: 1114 },
  { code: "O731", beneficiaries: "92", products: 2046 },
  { code: "O740", beneficiaries: "159", products: 9183 },
  { code: "O741", beneficiaries: "90", products: 4877 },
  { code: "O749", beneficiaries: "53", products: 3222 },
  { code: "O750", beneficiaries: "47", products: 2309 },
  { code: "O752", beneficiaries: "175", products: 8965 },
  { code: "O776", beneficiaries: "122", products: 14739 },
  { code: "O777", beneficiaries: "115", products: 1889 },
  { code: "O778", beneficiaries: "137", products: 14611 },
  { code: "O784", beneficiaries: "126", products: 9157 },
  { code: "O794", beneficiaries: "148", products: 3352 },
  { code: "O804", beneficiaries: "94", products: 1607 },
  { code: "O806", beneficiaries: "100", products: 10717 },
  { code: "O808", beneficiaries: "45", products: 4629 },
  { code: "O809", beneficiaries: "77", products: 1449 },
  { code: "O811", beneficiaries: "128", products: 2501 },
  { code: "O813", beneficiaries: "165", products: 11627 },
  { code: "O817", beneficiaries: "75", products: 7704 },
  { code: "O818", beneficiaries: "162", products: 12037 },
  { code: "O819", beneficiaries: "83", products: 6296 },
  { code: "O830", beneficiaries: "115", products: 8321 },
  { code: "O849", beneficiaries: "153", products: 11102 },
  { code: "O856", beneficiaries: "183", products: 24772 },
  { code: "O857", beneficiaries: "269", products: 12115 },
  { code: "O863", beneficiaries: "178", products: 4010 },
  { code: "O866", beneficiaries: "59", products: 1166 },
  { code: "O872", beneficiaries: "78", products: 6328 },
  { code: "O874", beneficiaries: "96", products: 6622 },
  { code: "O878", beneficiaries: "80", products: 5639 },
  { code: "O879", beneficiaries: "68", products: 1154 },
  { code: "O88", beneficiaries: "36", products: 717 },
  { code: "O880", beneficiaries: "133", products: 5025 },
  { code: "O89", beneficiaries: "124", products: 1844 },
  { code: "O896", beneficiaries: "184", products: 25390 },
  { code: "O92", beneficiaries: "27", products: 539 },
  { code: "O922", beneficiaries: "137", products: 5034 },
  { code: "O926", beneficiaries: "141", products: 10252 },
  { code: "O936", beneficiaries: "95", products: 4473 },
  { code: "O938", beneficiaries: "85", products: 4082 },
  { code: "O942", beneficiaries: "227", products: 16187 },
  { code: "O945", beneficiaries: "144", products: 8817 },
  { code: "O950", beneficiaries: "93", products: 2041 },
  { code: "O952", beneficiaries: "144", products: 7156 },
  { code: "O953", beneficiaries: "121", products: 13438 },
  { code: "O955", beneficiaries: "85", products: 1899 },
  { code: "O960", beneficiaries: "132", products: 9142 },
  { code: "O965", beneficiaries: "123", products: 2561 },
  { code: "O968", beneficiaries: "86", products: 1869 },
  { code: "O969", beneficiaries: "98", products: 1832 },
  { code: "O970", beneficiaries: "121", products: 6800 },
  { code: "O972", beneficiaries: "194", products: 28720 },
  { code: "O985", beneficiaries: "399", products: 62777 },
  { code: "O996", beneficiaries: "40", products: 3818 },
];

export default allMarketsDetails;
