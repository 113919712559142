"use client";

import * as React from "react";
import { Circle, CircleCheck, ChevronDown, ChevronUp } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { cn } from "@/lib/utils";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { isRTLLanguage } from "@/utils/helpers";

interface OptionType {
  value: string;
  label: string;
}

interface DropdownMenuCheckboxesProps {
  options: OptionType[];
  selectedOptions: string[];
  handleChange: (value: string) => void;
  buttonTitle: string | undefined;
  className?: string;
  classNameDrop?: string;
  classNameIcon?: string;
  keepButtonVisible?: boolean;
}

export function DropdownMenuCheckboxes({
  options,
  selectedOptions,
  handleChange,
  buttonTitle,
  className = "",
  classNameDrop = "",
  classNameIcon = "",
  keepButtonVisible = true,
}: DropdownMenuCheckboxesProps) {
  const [isOpen, setIsOpen] = React.useState(false);
  const { i18n } = useTranslation();

  const getClassName = (value: string) => {
    let baseClass = "hover:rounded-sm hover:font-bold mb-2";
    if (selectedOptions.includes(value)) {
      baseClass += " font-bold";
    }
    return baseClass;
  };
  const btnRef = useRef<HTMLButtonElement>(null);

  return (
    <DropdownMenu
      dir={isRTLLanguage(i18n.language) ? "rtl" : "ltr"}
      open={isOpen}
      onOpenChange={setIsOpen}
      modal={false}
    >
      <DropdownMenuTrigger
        aria-controls={buttonTitle}
        role="button"
        aria-haspopup="true"
        aria-expanded={isOpen}
        asChild
      >
        <Button
          ref={btnRef}
          variant="outline"
          className={cn(
            "flex !p-4 !font-bold text-base h-[44px] w-auto justify-between rounded-lg hover:bg-[#E2DDD7] focus:outline-none md:focus:ring-0 bg-[#E2DDD7]",
            className,
            !keepButtonVisible && isOpen ? "invisible" : "",
          )}
        >
          {buttonTitle}
          {isOpen ? (
            <ChevronUp
              className={cn("ltr:ml-auto rtl:mr-auto h-4 w-4", classNameIcon)}
            />
          ) : (
            <ChevronDown
              className={cn("ltr:ml-auto rtl:mr-auto h-4 w-4", classNameIcon)}
            />
          )}
        </Button>
      </DropdownMenuTrigger>
      {btnRef.current && (
        <DropdownMenuContent
          id={buttonTitle}
          style={{
            width: btnRef.current.clientWidth,
            marginTop:
              !keepButtonVisible && isOpen
                ? `-${btnRef.current.clientHeight}px`
                : "0",
          }}
          className={cn(
            "bg-background p-2 pb-0 text-[14px] border-none",
            classNameDrop,
          )}
          side="bottom"
          align="center"
          avoidCollisions={false}
          hideWhenDetached={true}
          role="menu"
        >
          {options.map((option) => (
            <button
              key={option.value}
              role="menuitem"
              aria-checked={selectedOptions.includes(option.value)}
              onClick={() => {
                handleChange(option.value);
                setIsOpen(false);
              }}
              className={cn(
                "flex cursor-pointer select-none items-center p-3 w-full",
                getClassName(option.value),
              )}
            >
              {selectedOptions.includes(option.value) ? (
                <CircleCheck className="h-4 w-4 fill-black stroke-background ltr:mr-2 rtl:ml-2" />
              ) : (
                <Circle className="h-4 w-4 stroke-[#C2B4B3] ltr:mr-2 rtl:ml-2" />
              )}
              {option.label}
            </button>
          ))}
        </DropdownMenuContent>
      )}
    </DropdownMenu>
  );
}
