import { Button } from "@/components/ui/button";
import { Link } from "@tanstack/react-router";
import React from "react";
import { Helmet } from "react-helmet-async";

interface MaintenanceMessageProps {
  title: string;
  message: string;
  description: string;
  backHomeText?: string;
}

export const LayoutErrorPages: React.FC<MaintenanceMessageProps> = ({
  title,
  message,
  description,
  backHomeText,
}) => {
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <div className="flex min-h-[80vh] flex-col items-start justify-start gap-16 px-4 py-40 text-black md:px-96 md:py-32">
        <div className="flex flex-col items-start justify-start gap-4">
          <h1 className="pt-4 text-40px font-bold md:text-96px">{title}</h1>
          <h2 className="text-24px font-bold md:text-48px">{message}</h2>
          <h3 className="py-16 md:text-32px">{description}</h3>
          {backHomeText && (
            <Link to="/">
              <Button>{backHomeText}</Button>
            </Link>
          )}
        </div>
      </div>
    </>
  );
};
