import i18n from "@/i18n";
import useCurrencyStore, { Currencies } from "@/store/currencyStore";
import { ProgramType, SuggestedAmountType } from "@/types/programs";

export const defaultCurrencies = ["USD", "EGP"];

export function formatNumber(number: number, integer: boolean = false) {
  const fixedPoint = integer ? 0 : 2;
  
  if (number >= 1_000_000_000) {
    const formattedNumber = number / 1_000_000_000;
    return formattedNumber.toFixed(fixedPoint) + "B";
  } else if (number >= 1_000_000) {
    const formattedNumber = number / 1_000_000;
    return formattedNumber.toFixed(fixedPoint) + "M";
  } else if (number >= 1000) {
    const formattedNumber = number / 1000;
    return formattedNumber.toFixed(fixedPoint) + "K";
  } else {
    return number.toFixed(fixedPoint);
  }
}

export const getCurrencySymbol = () => {
  const currency = useCurrencyStore.getState().currency;
  return (0)
    .toLocaleString(i18n.language, {
      style: "currency",
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, "")
    .trim();
};

export const formatCurrency = (amount: number) => {
  return `${getCurrencySymbol()}${formatNumber(amount)}`;
};

export const getSuggestedAmount = (amount: SuggestedAmountType) => {
  const { currency, conversionRate } = useCurrencyStore.getState();
  if (currency === Currencies.EGP) {
    return amount.amount_egp;
  } else if (currency === Currencies.USD) {
    return amount.amount_usd;
  } else {
    return Math.round((amount.amount_usd / conversionRate!) * 100) / 100;
  }
};

export const getProgramGoal = (program: ProgramType) => {
  const { currency, conversionRate } = useCurrencyStore.getState();
  let amount = 0;
  if (currency === Currencies.EGP) {
    amount = program.goal_amount_egp;
  } else if (currency === Currencies.USD) {
    amount = program.goal_amount_usd;
  } else {
    amount = program.goal_amount_usd / conversionRate!;
  }
  return amount;
};

export const getProgramRaisedAmount = (program: ProgramType) => {
  const { currency, conversionRate } = useCurrencyStore.getState();
  let amount = 0;
  if (currency === Currencies.EGP) {
    amount =
      program.raised_amount_egp +
      program.raised_amount_usd * program.currency_exchange_rate;
  } else if (currency === Currencies.USD) {
    amount =
      program.raised_amount_usd +
      program.raised_amount_egp / program.currency_exchange_rate;
  } else {
    const amount_usd =
      program.raised_amount_egp / program.currency_exchange_rate;
    amount = (amount_usd + program.raised_amount_usd) / conversionRate!;
  }
  return amount;
};

export const getProgramRaisedPercentage = (
  program: ProgramType,
  inputAmount: number = 0,
) => {
  return (
    ((getProgramRaisedAmount(program) + inputAmount) /
      getProgramGoal(program)) *
    100
  );
};

export const getMealPrice = (price_egp: number, price_usd: number) => {
  const { currency, conversionRate } = useCurrencyStore.getState();
  if (currency === Currencies.EGP) {
    return price_egp;
  } else if (currency === Currencies.USD) {
    return price_usd;
  } else {
    return price_usd / conversionRate!;
  }
};

export const getConversionApproximation = (inputAmount: number | null) => {
  const { currency, conversionRate } = useCurrencyStore.getState();
  if (!defaultCurrencies.includes(currency) && conversionRate && inputAmount) {
    return `(~$${Number(inputAmount * conversionRate).toFixed(2)})`;
  } else {
    return "";
  }
};

export const getMinAmount = (program: ProgramType) => {
  const { currency, conversionRate } = useCurrencyStore.getState();
  if (currency === Currencies.EGP) {
    return program.min_amount_egp;
  } else if (currency === Currencies.USD) {
    return program.min_amount_usd;
  } else {
    return program.min_amount_usd / conversionRate!;
  }
};
