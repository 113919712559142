import useChallengeFormStore from "@/store/challengeStore";
import { StepFooter } from "./stepFooter";
import { GenericShare } from "@/components/Share/Share";
import { useTranslation } from "react-i18next";

export const ThankYouStep = () => {
  const { t } = useTranslation();
  const buttonStyle = "flex flex-col items-center gap-2";
  const utm = `/?utm_medium=social&utm_campaign=share&utm_id=${"some-challenge-id"}`;
  const { nextStep, challengeName, challengeId } = useChallengeFormStore();
  const shareData = {
    title: `I'm supporting the ${challengeName} cause that fights hunger`,
    text: "",
    url: `${window.location.origin}/challenge/${challengeId}`,
  };
  return (
    <>
      <div className="scrollbar-hide mt-2 flex h-screen w-full flex-col gap-16 overflow-y-auto bg-background pt-14 md:mb-[90px] md:pt-16 lg:max-w-[615px]">
        <div className="mb-8 flex h-full w-full flex-col gap-4 bg-background md:mt-0 md:gap-8 md:px-6">
          <h3 className="w-full py-2 text-xl font-[650] leading-[120%] sm:text-[32px] md:py-0">
            {t("challenge.created")}
          </h3>
          <p className="text-2xl font-normal leading-[114%]">
            {t("challenge.createdDescription")}
          </p>

          <div className="flex h-full flex-col gap-16 py-8">
            <GenericShare
              buttonStyle={buttonStyle}
              utm={utm}
              shareData={shareData}
              label={t("challenge.copyLink")}
            />
          </div>
        </div>
      </div>
      <StepFooter nextStep={nextStep} isDisabled={false}></StepFooter>
    </>
  );
};
