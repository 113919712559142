import { useEffect, useState } from "react";
import { Button } from "../ui/button";
import ReactGA from "react-ga4";
import { Trans, useTranslation } from "react-i18next";
import { isRTLLanguage } from "@/utils/helpers";
const ConsentButton = () => {
  const { t, i18n } = useTranslation();
  const [isVisible, setIsVisible] = useState(true);
  const TRACKING_ID = "G-WSCBHG034Q";
  useEffect(() => {
    if (localStorage.getItem("cookie_consent") === "true") {
      ReactGA.initialize(TRACKING_ID);
      ReactGA.gtag("consent", "update", {
        analytics_storage: "granted",
      });
    }
  }, []);
  const handleAcceptClick = async () => {
    localStorage.setItem("cookie_consent", "true");
    ReactGA.initialize(TRACKING_ID);
    ReactGA.gtag("consent", "update", {
      analytics_storage: "granted",
    });
    setIsVisible(false);
  };
  const handleDenyClick = () => {
    localStorage.setItem("cookie_consent", "false");
    ReactGA.gtag("consent", "default", {
      analytics_storage: "denied",
    });
    setIsVisible(false);
  };
  if (!isVisible || localStorage.getItem("cookie_consent")) {
    return null;
  }
  return (
    <div
      dir={isRTLLanguage(i18n.language) ? "rtl" : "ltr"}
      className="fixed bottom-0 z-[60] flex w-full flex-col items-center justify-center gap-[60px] bg-white px-4 py-8 md:flex-row md:px-[135px]"
    >
      <div className="flex flex-col items-start gap-3.5">
        <p className="font-bold">{t("policies.cookieBannerTitle")}</p>
        <p className="text-gray-800">
          <Trans
            i18nKey="policies.cookiesBannerText"
            components={{
              underline: (
                <a
                  aria-label="Go to Cookies Policy"
                  href="/policy?type=privacy"
                  className="underline"
                />
              ),
            }}
          />
        </p>
      </div>
      <div className="flex gap-4 self-end md:self-center">
        <Button
          onClick={handleDenyClick}
          className="px-10 py-4 font-bold underline decoration-2"
          aria-label="Close install"
          variant="ghost"
        >
          {t("policies.cookieReject")}
        </Button>
        <Button
          className="rounded-md bg-primary px-10 py-4 font-bold"
          onClick={handleAcceptClick}
          aria-label="Accept Cookies"
          size="lg"
        >
          {t("policies.cookieAccept")}
        </Button>
      </div>
    </div>
  );
};
export default ConsentButton;
