import React, { useState, useEffect } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { CarouselComponent } from "@/pages/home/sections/CarouselComponent";
import { DocumentType } from "@/types/document";
import { useTranslation } from "react-i18next";

type AwardsCarouselProps = {
  items: DocumentType[];
};

export const AwardsCarousel: React.FC<AwardsCarouselProps> = ({ items }) => {
  const { t, i18n } = useTranslation();
  const [isRTL, setIsRTL] = useState(false);

  useEffect(() => {
    setIsRTL(i18n.dir() === "rtl");
  }, [i18n.language]);

  const [scrollPrev, setScrollPrev] = useState<() => void>(() => () => {});
  const [scrollNext, setScrollNext] = useState<() => void>(() => () => {});
  const [canScrollPrevFunc, setCanScrollPrevFunc] = useState<() => boolean>(
    () => () => false,
  );
  const [canScrollNextFunc, setCanScrollNextFunc] = useState<() => boolean>(
    () => () => false,
  );
  const [canScrollPrev, setCanScrollPrev] = useState(false);
  const [canScrollNext, setCanScrollNext] = useState(true);

  const handleSetScrollFunctions = (functions: any) => {
    setScrollPrev(() => functions.scrollPrev);
    setScrollNext(() => functions.scrollNext);
    setCanScrollNextFunc(() => functions.canScrollNext);
    setCanScrollPrevFunc(() => functions.canScrollPrev);
  };

  const handleScrollPrev = () => {
    (isRTL ? scrollNext : scrollPrev)();
    setCanScrollPrev(canScrollPrevFunc());
    setCanScrollNext(canScrollNextFunc());
  };

  const handleScrollNext = () => {
    (isRTL ? scrollPrev : scrollNext)();
    setCanScrollPrev(canScrollPrevFunc());
    setCanScrollNext(canScrollNextFunc());
  };

  const carouselItems = items.map((item) => ({
    title: item.description,
    imageSrc: item.minio_url,
    tags: item.tags,
  }));

  return (
    <div className="md:flex">
      <div className="flex flex-col justify-center md:w-1/3">
        <h1 className="text-2xl font-semibold md:text-6xl">
          {t("aboutPage.awardsCarousel.title")}
        </h1>
        <h6 className="mt-4 pr-4 text-lg md:text-xl">
          {t("aboutPage.awardsCarousel.description")}
        </h6>
        <div className="mt-8 hidden gap-4 md:flex">
          <button
            className={`flex h-12 w-12 items-center justify-center gap-2.5 rounded ${
              canScrollPrev ? "bg-[#232329]" : "bg-[#E2DDD7]"
            }`}
            onClick={handleScrollPrev}
            disabled={!canScrollPrev}
          >
            {isRTL ? (
              <ChevronRight stroke={canScrollPrev ? "#EDEAE4" : "#232329"} />
            ) : (
              <ChevronLeft stroke={canScrollPrev ? "#EDEAE4" : "#232329"} />
            )}
          </button>
          <button
            className={`flex h-12 w-12 items-center justify-center gap-2.5 rounded ${
              canScrollNext ? "bg-[#232329]" : "bg-[#E2DDD7]"
            }`}
            onClick={handleScrollNext}
            disabled={!canScrollNext}
          >
            {isRTL ? (
              <ChevronLeft stroke={canScrollNext ? "#EDEAE4" : "#232329"} />
            ) : (
              <ChevronRight stroke={canScrollNext ? "#EDEAE4" : "#232329"} />
            )}
          </button>
        </div>
      </div>
      <div className="mt-4 md:mt-0 md:w-2/3 rtl:flex-row-reverse">
        <CarouselComponent
          imagesMd={2}
          imagesLg={3}
          setScrollFunctions={handleSetScrollFunctions}
          hideArrows
        >
          {carouselItems.map((item, index) => (
            <div
              key={index}
              className={`flex aspect-9/16 h-[298px] min-w-[250px] max-w-[250px] flex-col items-stretch justify-between rounded-[16px] md:min-w-[350px] md:max-w-[350px] ${
                index % 2 === 0 ? "bg-[#E2DDD7]" : "border border-[#E2DDD7]"
              }`}
            >
              <h6 className="p-6 font-semibold">{item.title}</h6>
              <a
                href={item.tags}
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                <img
                  className="h-[186px] w-full rounded-[16px]"
                  src={item.imageSrc}
                  alt={item.title}
                />
              </a>
            </div>
          ))}
        </CarouselComponent>
      </div>
    </div>
  );
};
