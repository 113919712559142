import { AppShell } from "@/components/Navbar";
import { useTranslation } from "react-i18next";
import { LayoutErrorPages } from "../sections/layout";

export const Maintenance = () => {
  const { t } = useTranslation();

  return (
    <>
      <AppShell theme="white" navbarClass="top-0 sticky absolute w-full" />

      <LayoutErrorPages
        title={t("maintenance.title")}
        message={t("maintenance.message")}
        description={t("maintenance.description")}
      />
    </>
  );
};
