import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { cn } from "@/lib/utils.ts";

function LogoSvg({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="13"
      viewBox="0 0 8 13"
      fill="none"
      className={className}
    >
      <path
        d="M7.92228 4.98716V9.0491C7.92227 9.86444 7.61046 10.6493 7.05003 11.2446C6.4896 11.8399 5.72247 12.2012 4.90408 12.2552V8.19243C4.90353 7.37701 5.21517 6.59195 5.77574 5.99665C6.3363 5.40134 7.10375 5.04042 7.92228 4.98716Z"
        fill="#0A5849"
      />
      <path
        d="M0.643366 0.150227C0.643366 0.150227 0.643366 0.336324 0.643366 0.42898C0.642577 2.24598 0.621249 6.97535 0.676542 8.79078C0.69234 9.30981 0.881916 9.86182 1.13231 10.3306C1.79188 11.5642 3.24924 12.3392 4.43725 12.1766C4.43725 10.1351 4.54546 5.17171 4.40407 3.13563C4.28559 1.43014 2.36929 0.0395114 0.643366 0.150227Z"
        fill="#0A5849"
      />
      <path
        d="M7.57788 1.05166V2.77915C7.57788 4.05827 6.53363 4.70372 5.25084 4.78774V3.06025C5.25084 1.77956 6.29272 1.13647 7.57788 1.05245"
        fill="#70C171"
      />
    </svg>
  );
}

function convertLatLonToSVG(lat: number, lon: number) {
  const minLat = 22.0;
  const maxLat = 31.7;
  const minLong = 25.0;
  const maxLong = 36.0;

  const svgWidth = 600;
  const svgHeight = 572;

  const x = ((lon - minLong) / (maxLong - minLong)) * svgWidth;
  const y = ((maxLat - lat) / (maxLat - minLat)) * svgHeight;

  return { x, y };
}

interface Governorate {
  code: string;
  name: string;
  name_en: string;
  path: string;
}

interface Market {
  code: string;
  name: string;
  name_en: string;
  lat: number;
  long: number;
}

export enum POIType {
  Governorate,
  Market,
}

interface RtkMapProps {
  onPOIHover: (
    POI: { name: string; code: string; type: POIType } | null,
  ) => void;
  activeTab: string;
}

export function RtkMap({ onPOIHover, activeTab }: RtkMapProps) {
  const { i18n } = useTranslation();
  // Track which POI (Point of interest which is either governorate or market)
  // is hovered and its center position.
  const [hoveredPOI, setHoveredPOI] = useState<string | null>(null);
  const [tooltipPos, setTooltipPos] = useState<{ x: number; y: number } | null>(
    null,
  );

  const [governorates, setGovernorates] = useState<Governorate[]>([]);
  const [markets, setMarkets] = useState<Market[]>([]);

  useEffect(() => {
    fetch("/map-data/governorates_v1.0.json")
      .then((res) => res.json())
      .then((data) => {
        setGovernorates(data);
      });
    fetch("/map-data/markets_v1.1.json")
      .then((res) => res.json())
      .then((data) => {
        setMarkets(data);
      });
  }, []);

  // When the mouse enters a path, update both the external callback
  // and the internal state. Also, compute the center of the path.
  const handlePOIHover = (
    e: React.MouseEvent<SVGElement>,
    POI: { code: string; name: string; name_en: string; type: POIType },
  ) => {
    const name = i18n.language === "en" ? POI.name_en : POI.name;
    setHoveredPOI(name);
    onPOIHover({ name, code: POI.code, type: POI.type });

    // Get the bounding box of the hovered path in screen coordinates
    const bbox = e.currentTarget.getBoundingClientRect();

    // Find the center of the path
    const centerX = bbox.left + bbox.width / 2;
    const centerY = bbox.top + bbox.height / 2;

    // Adjust relative to the container
    const svgContainer =
      e.currentTarget.ownerSVGElement?.getBoundingClientRect();
    if (svgContainer) {
      setTooltipPos({
        x: centerX - svgContainer.left,
        y: centerY - svgContainer.top,
      });
    }
  };

  // When the mouse leaves, clear both states.
  const handleMouseLeave = () => {
    setHoveredPOI(null);
    onPOIHover(null);
    setTooltipPos(null);
  };

  return (
    <div style={{ position: "relative" }}>
      <svg
        viewBox="0 0 649 572"
        fill="none"
        style={{ width: "100%", height: "auto", display: "block" }}
      >
        <defs>
          <symbol id="icon-pin" viewBox="0 0 17 32">
            <path d="M16.573 8.136c0-4.486-3.65-8.136-8.136-8.136s-8.136 3.65-8.136 8.136c0 4.303 3.36 7.827 7.593 8.108v15.214c0 0.3 0.243 0.542 0.542 0.542s0.542-0.242 0.542-0.542v-15.214c4.233-0.281 7.593-3.805 7.593-8.108zM5.726 8.136c-1.196 0-2.17-0.973-2.17-2.17s0.973-2.17 2.17-2.17c1.196 0 2.17 0.973 2.17 2.17s-0.973 2.17-2.17 2.17z"></path>
          </symbol>
        </defs>
        {governorates.map((gov) => (
          <path
            key={gov.code}
            id={gov.code}
            onMouseEnter={(e) => {
              // Do not show the tooltip if the active tab is the supermarket projects
              if (activeTab !== "supermarket-projects") {
                handlePOIHover(e, { ...gov, type: POIType.Governorate });
              }
            }}
            onMouseLeave={handleMouseLeave}
            d={gov.path}
            className={cn(
              "fill-[#DBDBD1]",
              activeTab !== "supermarket-projects" && "hover:fill-[#8CA89E]",
            )}
            stroke="#0A5849"
            strokeWidth="0.4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        ))}
        {activeTab === "supermarket-projects" &&
          markets.map((market) => {
            const { x, y } = convertLatLonToSVG(market.lat, market.long);
            return (
              <g
                key={market.code}
                onMouseEnter={(e) =>
                  handlePOIHover(e, { ...market, type: POIType.Market })
                }
                onMouseLeave={handleMouseLeave}
                className="fill-[#0A5849] duration-300 animate-in fade-in slide-in-from-top-1"
              >
                <rect
                  x={x - 12}
                  y={y - 16}
                  width="20"
                  height="20"
                  fill="transparent"
                />
                <use
                  href="#icon-pin"
                  x={x - 12}
                  y={y - 16}
                  width="20"
                  height="20"
                />
              </g>
            );
          })}
      </svg>
      {tooltipPos && hoveredPOI && (
        <div
          className="pointer-events-none absolute z-50"
          style={{
            left: tooltipPos.x,
            top: tooltipPos.y,
            transform: "translate(-50%, -120%)", // Positions the tooltip above the path
          }}
        >
          {/* Tooltip box */}
          <div className="flex items-center rounded-lg bg-[#EDEAE4] bg-white p-2 shadow-lg">
            <LogoSvg className="me-2 h-6 w-6" />
            <p className="text-sm font-bold">{hoveredPOI}</p>
          </div>
          <div
            className="mx-auto h-[10px] w-[20px] bg-white"
            style={{
              clipPath: "ellipse(40% 100% at 50% 0%)",
            }}
          ></div>
        </div>
      )}
    </div>
  );
}
