import React, { useEffect, useMemo } from "react";
import { Button } from "../ui/button";
import { useLocation, useNavigate } from "@tanstack/react-router";
import { useTranslation, Trans } from "react-i18next";
import ProgramRecommendation from "./ProgramRecommendation";
import { ProgramType } from "@/types/programs";
import { useFormStore } from "@/contexts/paymentFlowContext";
import { useGetAuthenticatedUser } from "@/api/user";
import { getLoginURL } from "@/utils/auth";
import { Check } from "lucide-react";
import { useGetSubscriptions } from "@/api/subscription";
import dayjs from "dayjs";
import FailedPayment from "./FailedPayment";

interface PaymentStateProps {
  amount: string;
  email: string;
  readonly program: ProgramType;
}

const PaymentState: React.FC<PaymentStateProps> = ({
  amount,
  email,
  program,
}) => {
  const { t, i18n } = useTranslation();
  const { search } = useLocation();
  const {
    cashSuccess,
    setDonationType,
    setInputAmount,
    setSelectedOption,
    setFinalMonth,
    setAgreementId,
    checkoutStep,
  } = useFormStore();
  const { data: user } = useGetAuthenticatedUser();
  const { data: subscriptions } = useGetSubscriptions({
    agreement_id: search.agreement_id?.replaceAll("/", "") ?? "null",
  });

  useEffect(() => {
    setDonationType(undefined);
  }, []);

  const navigate = useNavigate();
  const handleOnclickRetry = () => {
    const subscription = subscriptions?.results.at(0);
    if (search.agreement_id && !user) {
      window.location.href = getLoginURL("");
      return;
    }
    if (subscription) {
      setInputAmount(subscription.amount);
      setSelectedOption("Credit Card");
      setFinalMonth(dayjs(subscription.expiration_date).toDate());
      setAgreementId(subscription.agreement_id);
      checkoutStep();
    }
    navigate({
      to: "/paymentFlow/$programId",
      params: { programId: program.id },
      search: {
        backUrl: "/",
      },
    });
  };

  const createAccountReasons = useMemo(() => {
    return [
      t("paymentState.createAccount1"),
      t("paymentState.createAccount2"),
      t("paymentState.createAccount3"),
      t("paymentState.createAccount4"),
    ];
  }, [i18n.language]);

  const handleOnclickCreateAccount = () => {
    const redirecturl = getLoginURL("", window.location.origin, true);
    window.location.href = redirecturl;
  };

  return (
    <div
      className={`${search.response_code === 14000 || cashSuccess ? "justify-start" : "mt-20 justify-start"} flex h-full w-full flex-col items-start gap-4 p-4 md:gap-8 md:pt-14`}
    >
      {search.response_code === 14000 || cashSuccess ? (
        <>
          <h1 className="pt-10 text-xl font-bold text-foreground md:pt-0 md:text-4xl">
            {t("paymentState.thankYou")}
          </h1>
          <p className="font-medium text-foreground">
            <Trans
              i18nKey={
                search.response_code === 14000
                  ? "paymentState.donationProcessed"
                  : "paymentState.cashDonationProcessed"
              }
              values={{ amount, email }}
              components={{
                bold: <span className="font-bold text-foreground" />,
              }}
            />
          </p>
          {user ? (
            <></>
          ) : (
            <>
              <div className="flex flex-col gap-4">
                <p className="font-medium text-foreground">
                  {t("paymentState.createAccountPrompt")}
                </p>
                <ul className="flex flex-col gap-2.5">
                  {createAccountReasons.map((reason) => (
                    <li
                      key={reason}
                      className="flex items-center gap-2 font-medium text-foreground"
                    >
                      <div className="flex h-5 w-5 items-center justify-center rounded-full border-2 border-[#111111] ">
                        <Check className="h-3.5 w-3.5 stroke-foreground stroke-2" />
                      </div>
                      <p>{reason}</p>
                    </li>
                  ))}
                </ul>
              </div>
              <div>
                <Button
                  className="px-10 py-6 font-bold"
                  onClick={handleOnclickCreateAccount}
                >
                  {t("paymentState.createAccountButton")}
                </Button>
              </div>
            </>
          )}

          <hr className="border-t-1 w-full border-gray-300 text-foreground" />
          <div className="flex flex-col gap-8 pb-14">
            <h3 className="text-xl font-bold text-foreground">
              {t("paymentState.MoreCauses")}
            </h3>
            <ProgramRecommendation program={program} />
          </div>
        </>
      ) : (
        <FailedPayment
          responseMessage={search.response_message}
          handleOnclickRetry={handleOnclickRetry}
        />
      )}
    </div>
  );
};

export default PaymentState;
