import { Button } from "@/components/ui/button";
import { useFormStore } from "@/contexts/paymentFlowContext";
import useCurrencyStore from "@/store/currencyStore";
import { formatNumber, getConversionApproximation } from "@/utils/currency";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

interface StepFooterProps {
  readonly nextStep: () => void;
  donationtype?: string;
}

const StepFooter = ({ nextStep, donationtype }: StepFooterProps) => {
  const { t } = useTranslation();
  const { hasCompletedAllSteps, formError } = useFormStore();
  const { currency, conversionRate } = useCurrencyStore();
  const { inputAmount, activeTab } = useFormStore();

  const conversionApproximation = useMemo(
    () => getConversionApproximation(inputAmount),
    [currency, conversionRate, inputAmount],
  );

  return (
    <div className="flex w-full flex-col gap-1 border-t border-[#CCC2BF] bg-background py-3 sm:flex-row sm:items-center sm:justify-between sm:gap-0 md:py-6">
      {formError && (
        <p className="mt-1 block text-sm text-[#F04438] sm:hidden">
          {t("donationFlow.globalError")}
        </p>
      )}
      {inputAmount ? (
        <div className="flex w-full flex-col gap-1 sm:items-start">
          <div className="hidden w-full items-center justify-between sm:flex sm:justify-start sm:gap-1">
            <p className="hidden md:block">{t("StepsContainer.donate")}</p>
            <p className="font-bold">
              {currency} {formatNumber(inputAmount)}
            </p>
            <span className="text-sm">{conversionApproximation}</span>
          </div>

          <div className="flex w-full justify-between sm:hidden">
            <p className="text-xs text-[#AC9CA0]">
              {activeTab === "once"
                ? t("StepsContainer.once")
                : t("donationFlow.monthly")}{" "}
              {t("StepsContainer.donation")} {donationtype}
            </p>
            <div className="flex items-center gap-1">
              <p className="text-sm font-bold">
                {currency} {formatNumber(inputAmount)}
              </p>
              <span className="text-sm">{conversionApproximation}</span>
            </div>
          </div>

          <p className="hidden text-sm text-[#AC9CA0] sm:block">
            {activeTab === "once"
              ? t("StepsContainer.once")
              : t("donationFlow.monthly")}{" "}
            {t("StepsContainer.donation")} {donationtype}
          </p>
        </div>
      ) : (
        <div></div>
      )}
      <div className="hidden flex-grow sm:block"></div>
      <Button
        onClick={nextStep}
        className="h-full w-full bg-primary font-bold text-foreground hover:bg-foreground hover:text-light sm:w-[14rem] md:px-10 md:py-4"
      >
        {hasCompletedAllSteps
          ? t("donationFlow.donateNow")
          : t("donationFlow.next")}
      </Button>
    </div>
  );
};

export default StepFooter;
