import React from "react";

interface CircularProgressBarProps {
  percentage: number; // Percentage value (0 to 100)
  size?: number; // Size of the progress bar (width and height)
  strokeWidth?: number; // Thickness of the progress bar
  bgColor?: string; // Background color of the progress bar
  progressColor?: string; // Color of the progress bar
  textColor?: string; // Color of the percentage text
  rounded?: string; // Border radius for the completion stroke
}

export const CircularProgressBar: React.FC<CircularProgressBarProps> = ({
  percentage,
  size = 180,
  strokeWidth = 20, // Double the thickness (default 10px -> 20px)
  bgColor = "#E2DDD7", // Background color
  progressColor = "#0A5849", // Progress color
  textColor = "#0A5849", // Text color
  rounded = "rounded-[16px]", // Rounded completion stroke
}) => {
  const radius = (size - strokeWidth) / 2; // Radius of the circle
  const circumference = 2 * Math.PI * radius; // Circumference of the circle
  const offset = circumference * (1 - percentage / 100); // Offset for the progress stroke

  return (
    <div className="relative flex items-center justify-center">
      <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
        {/* Background Circle */}
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          fill="none"
          stroke={bgColor}
          strokeWidth={strokeWidth}
        />
        {/* Progress Circle */}
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          fill="none"
          stroke={progressColor}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          strokeLinecap="round" // Rounded completion stroke
          transform={`rotate(-90 ${size / 2} ${size / 2})`}
          className={rounded} // Apply rounded completion stroke
        />
      </svg>
      {/* Percentage Text */}
      <div
        className="absolute text-center text-lg font-semibold"
        style={{ color: textColor }}
      >
        {percentage.toFixed(2)}%
      </div>
    </div>
  );
};
