import { ItemType } from "@/types/programs";
import api from "./api-client";
import { queryOptions } from "@tanstack/react-query";
import { paramsToKey } from "@/utils/helpers";

export const itemKeys = {
  all: (params?: Record<string, string>) =>
    ["items", ...paramsToKey(params)] as const,
  program: (params?: Record<string, string>) =>
    [...itemKeys.all(), ...paramsToKey(params)] as const,
};

const getItems = async (params?: Record<string, string>) => {
  const queryParams = { ...params };
  const res = await api.get("/item/", { params: queryParams });
  return res.data.results as ItemType[];
};

export const itemQueryOptions = (params?: Record<string, string>) =>
  queryOptions({
    queryKey: itemKeys.program(params),
    queryFn: () => getItems(params),
  });
