import { useState } from "react";
import { UpdateCard } from "../program/sections/update-card";
import useChallengeFormStore from "@/store/challengeStore";
import { useCreateChallengeUpdate } from "@/api/challenge";
import { ChallengeType, ChallengeUpdateType } from "@/types/challenge";
import { OwnerUpdateCard } from "./components/OwnerUpdateCard";
import { convertDjangoDateToReadable } from "@/utils/challenge";
import { DonationWrap } from "./components/DonationWrap";
import { LastPersonalDonation } from "./components/LastPersonalDonation";
import { useTranslation } from "react-i18next";

const OwnerUpdateComponent = ({ challenge }: { challenge: ChallengeType }) => {
  const [updateText, setUpdateText] = useState("");
  const [newUpdate, setNewUpdate] = useState<ChallengeUpdateType>();
  const { t } = useTranslation();
  const { mutate } = useCreateChallengeUpdate({
    onSuccess: (data) => {
      const newUpdate = {
        id: data.id,
        content: data.content,
        created_at: data.created_at,
      };
      setNewUpdate(newUpdate);
      setTimeout(() => {
        challenge.owner_updates.unshift(newUpdate);
        setNewUpdate(undefined);
      }, 500);
    },
  });

  const handlePostUpdate = () => {
    mutate({ challengeId: challenge.id, data: { content: updateText } });
    setUpdateText("");
  };

  return (
    <div className="flex gap-4 overflow-y-auto">
      <OwnerUpdateCard
        date={t("challenge.toBePublished")}
        updateText={updateText}
        setUpdateText={setUpdateText}
        handlePostUpdate={handlePostUpdate}
        isReadonly={false}
      />
      {newUpdate && (
        <OwnerUpdateCard
          date={convertDjangoDateToReadable(newUpdate.created_at as string)}
          updateText={newUpdate.content}
          isReadonly={true}
          author={challenge.owner.profile.firstName}
          className="animate-slideInFromLeft"
        />
      )}
      {challenge.owner_updates.map((update) => (
        <OwnerUpdateCard
          key={update.id}
          date={convertDjangoDateToReadable(update.created_at as string)}
          updateText={update.content}
          isReadonly={true}
          author={challenge.owner.profile.firstName}
        />
      ))}
    </div>
  );
};

export const UpdatesComponent = ({
  challenge,
}: {
  challenge: ChallengeType;
}) => {
  const { t } = useTranslation();
  const { preview } = useChallengeFormStore();
  return (
    <section id="Updates">
      <div className="scrollbar-hide mx-auto flex w-full max-w-2xl flex-col gap-4 overflow-y-hidden bg-background md:mt-0 md:gap-8">
        <h1 className="w-full py-2 text-xl font-[650] leading-[120%] sm:text-[32px] md:py-0">
          {t("challenge.challengeUpdates")}
        </h1>
        {challenge.is_owner && !preview && challenge.state !== "Closed" ? (
          <OwnerUpdateComponent challenge={challenge} />
        ) : (
          <div className="scrollbar-hide mx-auto w-full  max-w-2xl overflow-y-hidden pb-6">
            <div className="flex shrink-0  flex-row gap-2">
              {challenge.last_personal_donation && (
                <LastPersonalDonation
                  challenge={challenge}
                  last_personal_donation={challenge.last_personal_donation}
                />
              )}
              {challenge.last_program_update && (
                <div className="shrink-0">
                  <UpdateCard
                    update={challenge.last_program_update}
                    bgColor={challenge.program.color_third}
                    size="w-[310px] h-[550px] rounded-2xl"
                    isChallenge
                  />
                </div>
              )}
              <DonationWrap
                donation_wrap={challenge.donation_wrap}
                challenge={challenge}
              />
              {challenge.owner_updates.map((item) => (
                <OwnerUpdateCard
                  key={item.id}
                  date={convertDjangoDateToReadable(item.created_at as string)}
                  updateText={item.content}
                  isReadonly={true}
                  author={challenge.owner.profile.firstName}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </section>
  );
};
