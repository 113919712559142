import { Checkbox } from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import { useTranslation } from "react-i18next";
import { isRTLLanguage } from "@/utils/helpers";
import useUserStore from "@/store/userStore";
import { useGetReasonOptions, usepostReasons } from "@/api/reason";
import { ReasonState } from "@/types/reason";
import { Form, Formik } from "formik";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { UseMutateFunction } from "@tanstack/react-query";
import { User } from "@/types/auth";

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  deleteUser: UseMutateFunction<User, Error, void, unknown>;
};

export default function DeleteCheckModal({
  open,
  setOpen,
  deleteUser,
}: Readonly<Props>) {
  const { t, i18n } = useTranslation();
  const { data: reasons, isFetching } = useGetReasonOptions({
    lang: i18n.language,
  });
  const [reasonsState, setReasonsState] = useState<ReasonState[]>([]);
  useEffect(() => {
    if (reasons) {
      setReasonsState(reasons.map((reason) => ({ reason, checked: false })));
    }
  }, [isFetching]);

  const { mutate } = usepostReasons({
    onSuccess: () => {
      deleteUser();
    },
    onError: () => {
      deleteUser();
    },
  });

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent
        dir={isRTLLanguage(i18n.language) ? "rtl" : "ltr"}
        className="mt-48 flex h-screen w-full overflow-auto rounded-t-2xl p-8 pb-56 lg:mt-0 lg:h-auto lg:w-[1041px] lg:rounded-2xl lg:p-16"
        onCloseAutoFocus={() => useUserStore.getState().clearToken()}
      >
        <Formik
          initialValues={{
            comment: "",
            reasons: reasonsState,
          }}
          onSubmit={(values) => {
            mutate({ data: values });
          }}
        >
          {({ values, setFieldValue }) => (
            <Form
              dir={isRTLLanguage(i18n.language) ? "rtl" : "ltr"}
              className="h-full w-full"
            >
              <div className="md:align-center flex h-full w-full flex-1 flex-col gap-4 text-start md:gap-16">
                <div className="md:align-center flex w-full flex-1 flex-col gap-4 text-start md:gap-8">
                  <h3 className="text-[20px] font-bold md:text-3xl md:text-[32px]">
                    {t("profile.deleteCause")}
                  </h3>
                  <div className="align-center flex flex-col justify-between gap-4">
                    {reasons &&
                      reasonsState.length > 0 &&
                      reasons.map((reason, index) => (
                        <div
                          key={reason.id}
                          className="flex items-start md:items-center rtl:gap-8"
                        >
                          <Checkbox
                            aria-checked={values.reasons[index].checked}
                            id={reason.name}
                            checked={values.reasons[index].checked}
                            onCheckedChange={() =>
                              setFieldValue(
                                "reasons",
                                values.reasons.map((reasonState, i) =>
                                  i === index
                                    ? {
                                        ...reasonState,
                                        checked: !reasonState.checked,
                                      }
                                    : reasonState,
                                ),
                              )
                            }
                            className="border-[#CDCFC9] checked:border-[#3F503E] disabled:bg-[#DCDCDC] ltr:mr-8"
                          />
                          <label
                            htmlFor={reason.name}
                            className={
                              "text-base text-foreground sm:text-base md:text-lg"
                            }
                          >
                            {reason.name}
                          </label>
                        </div>
                      ))}
                  </div>
                  <Input
                    type="text"
                    aria-label="comment"
                    name="comment"
                    value={values.comment}
                    onChange={(e) => setFieldValue("comment", e.target.value)}
                    placeholder={t("profile.otherReasons")}
                    className="max-w-[500px]"
                  />
                </div>
                <div className="flex w-full justify-center self-center ">
                  <Button size="lg" type="submit" className="min-w-56">
                    {t("profile.send")}
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
