import config from "@/config/config";
import { Currencies } from "@/store/currencyStore";
const RTLanguages = ["ar"];

export const isRTLLanguage = (lang: string) => {
  return RTLanguages.includes(lang);
};

export const bgOverlayStyle = (
  imgUrl?: string,
) => `linear-gradient(0deg, rgba(0, 0, 0, 0) 56.92%, rgba(0, 0, 0, 0.3) 68.22%, rgba(0, 0, 0, 0.4) 100%),
linear-gradient(180deg, rgba(0, 0, 0, 0) 40.39%, rgba(0, 0, 0, 0.3) 61.28%, rgba(0, 0, 0, 0.4) 92.02%),
linear-gradient(220deg, rgba(0, 0, 0, 0) 40.39%, rgba(0, 0, 0, 0.3) 61.28%, rgba(0, 0, 0, 0.4) 92.02%),
linear-gradient(30deg, rgba(0, 0, 0, 0) 40.39%, rgba(0, 0, 0, 0.3) 61.28%, rgba(0, 0, 0, 0.4) 92.02%), url(${imgUrl})`;

export const paramsToKey = (params?: Record<string, string>) => {
  if (params) {
    return Object.entries(params).map(([k, v]) => `${k}:${v}`);
  } else {
    return [];
  }
};

export const convertCurrency = (
  amount: number,
  fromCurrency: string,
  toCurrency: string,
  exchangeRate: number,
): number => {
  if (fromCurrency === toCurrency) {
    return amount;
  }

  if (fromCurrency === Currencies.USD && toCurrency === Currencies.EGP) {
    return amount * exchangeRate;
  }

  if (fromCurrency === Currencies.EGP && toCurrency === Currencies.USD) {
    return amount / exchangeRate;
  }

  return amount;
};

export const toggleElementFromArray = (arr: string[], item: string) => {
  if (item === "") {
    return [""];
  }
  return arr.includes(item)
    ? arr.filter((i) => i !== item)
    : [...arr, item].filter((i) => i !== "");
};

export const getCookieDomain = () => {
  return config.prod
    ? "." + window.location.hostname.split(".").slice(1).join(".")
    : "localhost";
};

export const getPageNumberFromURL = (url?: string | null) => {
  if (!url) {
    return undefined;
  }
  const parsed = new URL(url);
  return parsed.searchParams.get("page");
};

/**
 * Checks if a string contains Arabic characters
 * @param text - The text to check
 * @returns boolean - True if the text contains Arabic characters
 */
export const containsArabic = (text?: string): boolean => {
  if (!text) return false;

  // Arabic Unicode ranges
  const arabicPattern =
    /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFF]/;
  return arabicPattern.test(text);
};
