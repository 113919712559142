import { CohLogo } from "@/assets/CohLogo";
import { CohLogoAr } from "@/assets/CohLogo-ar";
import { Progress } from "@/components/ui/progress";
import { Trans, useTranslation } from "react-i18next";
import useCurrencyStore, { Currencies } from "@/store/currencyStore";
import { bgOverlayStyle, convertCurrency } from "@/utils/helpers";
import { formatNumber } from "@/utils/currency";
import NumberTicker from "@/components/Helpers/NumberTicker";
import { useFormStore } from "@/contexts/paymentFlowContext";
import { useLocation, useNavigate } from "@tanstack/react-router";
import { Button } from "@/components/ui/button";
import { X } from "lucide-react";
import i18n from "@/i18n";
import { ChallengeType } from "@/types/challenge";
import { ProfileIcon } from "@/assets/icons/Profile";
import { calculateDeadline } from "@/utils/challenge";
import { SupportersDisplay } from "../community-challenge/components/SupportersDisplay";

interface ChallengePaymentContainerProps {
  readonly challenge: ChallengeType;
  readonly children?: React.ReactNode;
}

export default function ChallengePaymentContainer({
  children,
  challenge,
}: ChallengePaymentContainerProps) {
  const { t } = useTranslation();
  const { search } = useLocation();
  const { inputAmount, reset } = useFormStore();
  const totalDonations = useFormStore((state) => state.totalDonations);
  const { currency } = useCurrencyStore();

  if (!challenge) {
    return null;
  }

  const inputAmt = inputAmount ?? 0;
  let goalAmount =
    currency === Currencies.EGP ? challenge.goal_egp : challenge.goal_usd;
  const exchangeRate = challenge.program.currency_exchange_rate;
  let collectedAmount =
    convertCurrency(
      challenge.raised_amount_usd,
      Currencies.USD,
      currency,
      exchangeRate,
    ) +
    convertCurrency(
      challenge.raised_amount_egp,
      Currencies.EGP,
      currency,
      exchangeRate,
    ) +
    totalDonations;

  if (inputAmt !== 0 && !search.response_code) {
    goalAmount =
      currency === Currencies.EGP ? challenge.goal_egp : challenge.goal_usd;
    collectedAmount =
      convertCurrency(
        challenge.raised_amount_usd,
        Currencies.USD,
        currency,
        exchangeRate,
      ) +
      convertCurrency(
        challenge.raised_amount_egp,
        Currencies.EGP,
        currency,
        exchangeRate,
      ) +
      inputAmt;
  }

  const raisedPercentage =
    (collectedAmount * 100) /
    (currency === Currencies.USD ? challenge.goal_usd : challenge.goal_egp);

  const impact = (totalDonations * 100) / goalAmount;
  const navigate = useNavigate();

  const handleModalClose = () => {
    reset();
    if (search.response_code) {
      navigate({ to: "/" });
    } else {
      navigate({ to: search.backUrl ?? "/" });
    }
  };

  return (
    <main className="fixed top-[92px] flex h-[calc(100vh-92px)] w-full flex-col overflow-hidden sm:flex-row md:top-[52px] md:h-[calc(100vh-52px)]">
      <div
        className="relative z-20 hidden h-full w-full sm:h-full sm:w-2/5 md:block"
        style={{
          background: bgOverlayStyle(
            challenge.challenge_picture
              ? challenge.challenge_picture.minio_url
              : challenge.program.main_image?.minio_url,
          ),
          backgroundPosition: "center center",
          backgroundSize: "cover",
        }}
      >
        <div className="dark h-16 w-full items-start justify-center sm:h-12 sm:w-12 sm:p-4">
          <a
            href="/"
            aria-label="Go to home page"
            className="z-50 hidden md:block"
          >
            {i18n.language === "ar" ? <CohLogoAr /> : <CohLogo />}
          </a>
          <div className="flex-grow"></div>
          <Button
            variant={"ghost"}
            aria-label={t("closeModal")}
            className="flex ring-offset-background hover:bg-transparent focus:outline-none focus:ring-0 focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground sm:hidden"
            onClick={handleModalClose}
          >
            <X className="h-6 w-6 stroke-white sm:stroke-foreground" />
          </Button>
        </div>
        <div className="absolute bottom-0 flex w-full flex-col gap-6 p-4 md:p-8">
          <div className="flex w-full flex-col items-start gap-4">
            <div className="flex flex-row items-center gap-4">
              <div className="flex h-10 w-10 items-center justify-center rounded-full bg-[#C5D6C2]">
                <ProfileIcon height={20} width={19} />
              </div>
              <h2 className="text-white">
                <Trans
                  i18nKey={"challenge.createdBy"}
                  values={{
                    person: `${challenge.owner.profile.firstName}
                      ${challenge.owner.profile.lastName}`,
                  }}
                />
              </h2>
            </div>
            <div className="flex flex-col items-start gap-1">
              <h2 className="text-white">{challenge.program.title}</h2>
              <h1 className="max-w-[30rem] text-3xl font-[650] leading-[120%] text-light md:text-[32px] lg:text-5xl">
                {challenge.name}
              </h1>
            </div>
          </div>
          <SupportersDisplay
            supporters={challenge.supporters}
            count={challenge.supporters_count}
          />
          <div className="flex flex-col gap-4">
            <Progress
              value={Math.min(raisedPercentage + impact, 100)}
              className="h-[4px] rounded-[5px] bg-[#FFFFFF55] rtl:scale-x-[-1] rtl:transform"
              aria-label="Donation amount progress bar"
            />
            <div className="flex justify-between">
              <div className="flex flex-col items-start gap-1">
                <p className="font-[650] text-light">
                  <NumberTicker
                    key={currency}
                    value={collectedAmount + totalDonations}
                    initialValue={collectedAmount}
                    className="!text-base !font-[650] text-light"
                  />
                  {" ("}
                  {formatNumber(raisedPercentage + impact)}%)
                </p>
                <p className="text-sm font-[350] text-light">
                  {t("ProgramCard.raised_label")}
                </p>
              </div>
              <div className="flex flex-col items-end gap-1">
                <p className="font-[650] capitalize text-light">
                  {calculateDeadline(
                    challenge.start_date as string,
                    challenge.end_date as string,
                  )}
                </p>

                <p className="text-sm font-[350] text-light">Deadline</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {children}
    </main>
  );
}
