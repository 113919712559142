import { isInViewport } from "@/utils/viewport";
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";

type Dimensions = {
  width: string;
  height: string;
  left?: string;
};
// Constant side padding (in pixels) for fullscreen mode.
const SIDE_PADDING = 24;
// Constant scroll offset (in pixels) for video translation.
const SCROLL_OFFSET = 32;
// Constant navbar height (in pixels) for mobile video translation
const NAVBAR_HEIGHT = 108;

export const MealsSection: React.FC = () => {
  const { t } = useTranslation();
  const videoRef = useRef<HTMLVideoElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const cardsRef = useRef<HTMLDivElement>(null);
  const videoOriginalColumn = useRef<HTMLDivElement>(null);

  // videoStyles holds inline styles for width, height, margin, etc.
  const [videoStyles, setVideoStyles] = useState<React.CSSProperties>({});
  // Save the initial (non‑fullscreen) dimensions.
  const [initialDimensions, setInitialDimensions] = useState<{
    width: string;
    height: string;
    left?: string;
  }>({
    width: "0px",
    height: "0px",
    left: "0px",
  });

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isVideoVisible, setIsVideoVisible] = useState(false);

  // Add these state variables
  const [isMobileVideoFixed, setIsMobileVideoFixed] = useState(false);
  const [isMobileVideoExpanded, setIsMobileVideoExpanded] = useState(false);
  const mobileVideoRef = useRef<HTMLDivElement>(null);

  // Add this state to store the initial fixed position
  const [initialFixedTop, setInitialFixedTop] = useState<number | null>(null);

  // On mount, measure the video’s initial dimensions.
  useEffect(() => {
    if (videoRef.current && containerRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect();
      const rect = videoRef.current.getBoundingClientRect();
      const videoOriginalColumnRect =
        videoOriginalColumn.current?.getBoundingClientRect();
      let width = videoOriginalColumnRect?.width || rect.width;
      let height = rect.height;
      if (isMobile) {
        width = containerRect.width / 2;
        height = 320;
        setInitialDimensions({
          width: `${width}px`,
          height: `${height}px`,
        });
        setVideoStyles({
          width: `${width}px`,
          height: `${height}px`,
          margin: "0 auto",
          transformOrigin: "center",
        });
      } else if (videoOriginalColumnRect) {
        setInitialDimensions({
          width: `${width}px`,
          height: `${height}px`,
          left: `${videoOriginalColumnRect.left - SIDE_PADDING}px`,
        });
        setVideoStyles({
          width: `${width}px`,
          height: `${height}px`,
          left: `${videoOriginalColumnRect.left - SIDE_PADDING}px`,
          margin: "0 auto",
          transformOrigin: "center",
        });
      }
    }
  }, [isMobile]);

  // Update isMobile flag on window resize.
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Control video playback based on visibility
  useEffect(() => {
    if (videoRef.current) {
      if (isVideoVisible) {
        videoRef.current.play().catch((error) => {
          // Handle potential autoplay restrictions
          console.log("Autoplay prevented:", error);
        });
      } else {
        videoRef.current.pause();
      }
    }
  }, [isVideoVisible]);

  // Scroll logic
  useEffect(() => {
    // Desktop scroll handling
    const handleDesktopScroll = (
      videoRef: React.RefObject<HTMLElement>,
      containerRef: React.RefObject<HTMLElement>,
      initialDimensions: Dimensions,
      isExpanded: boolean,
      setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>,
      setVideoStyles: React.Dispatch<React.SetStateAction<React.CSSProperties>>,
      scrollOffset: number,
      sidePadding: number,
    ): void => {
      const containerRect = containerRef.current!.getBoundingClientRect();
      const videoRect = videoRef.current!.getBoundingClientRect();

      // Calculate base translation
      const baseTranslateY = Math.min(0, containerRect.top + scrollOffset * 3);

      // Calculate maximum possible translation (container bottom - video bottom)
      // This ensures the video doesn't go beyond the container's bottom
      const maxTranslation =
        containerRect.height - videoRect.height - scrollOffset * 6;

      // Clamp the translation value between 0 and maxTranslation
      const translateY = Math.min(maxTranslation, -1 * baseTranslateY);

      videoRef.current!.style.transform = `translateY(${translateY}px)`;

      handleDesktopVideoExpansion(
        baseTranslateY,
        containerRect,
        isExpanded,
        setIsExpanded,
        setVideoStyles,
        initialDimensions,
        sidePadding,
      );
    };

    const handleDesktopVideoExpansion = (
      baseTranslateY: number,
      containerRect: DOMRect,
      isExpanded: boolean,
      setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>,
      setVideoStyles: React.Dispatch<React.SetStateAction<React.CSSProperties>>,
      initialDimensions: Dimensions,
      sidePadding: number,
    ): void => {
      const expansionTriggerPoint =
        containerRect.height / 2 + window.innerHeight / 4;

      if (-1 * baseTranslateY >= expansionTriggerPoint && !isExpanded) {
        setIsExpanded(true);
        setVideoStyles({
          width: `${window.innerWidth - sidePadding * 2}px`,
          height: `${window.innerHeight * 0.8}px`,
          margin: 0,
          transformOrigin: "center",
        });
      } else if (isExpanded && -1 * baseTranslateY < expansionTriggerPoint) {
        setIsExpanded(false);
        setVideoStyles({
          width: initialDimensions.width,
          height: initialDimensions.height,
          left: initialDimensions.left,
          margin: "0 auto",
          transformOrigin: "center",
        });
      }
    };

    // Mobile scroll handling
    const handleMobileScroll = (
      videoRef: React.RefObject<HTMLElement>,
      mobileVideoRef: React.RefObject<HTMLElement>,
      containerRef: React.RefObject<HTMLElement>,
      initialDimensions: Dimensions,
      isMobileVideoFixed: boolean,
      setIsMobileVideoFixed: React.Dispatch<React.SetStateAction<boolean>>,
      isMobileVideoExpanded: boolean,
      setIsMobileVideoExpanded: React.Dispatch<React.SetStateAction<boolean>>,
      setVideoStyles: React.Dispatch<React.SetStateAction<React.CSSProperties>>,
      initialFixedTop: number | null,
      setInitialFixedTop: React.Dispatch<React.SetStateAction<number | null>>,
      scrollOffset: number,
      sidePadding: number,
    ): void => {
      const containerRect = containerRef.current!.getBoundingClientRect();
      const mobileVideoRect = mobileVideoRef.current!.getBoundingClientRect();
      const triggerPoint = NAVBAR_HEIGHT;
      const maxTranslation = 150;

      // Get current video dimensions (considering whether it's expanded or not)
      const currentVideoHeight = isMobileVideoExpanded
        ? window.innerHeight - NAVBAR_HEIGHT - scrollOffset
        : parseInt(initialDimensions.height);

      // Calculate container boundaries more precisely
      const containerTop = containerRef.current!.offsetTop;
      const containerHeight = containerRect.height;
      const containerBottom = containerTop + containerHeight;

      // Calculate the max scroll position where the video should stop
      const maxScrollPosition = containerBottom - currentVideoHeight;

      // Calculate how much space is left between the video's bottom and container's bottom
      const videoBottom = window.scrollY + mobileVideoRect.bottom;
      const distanceToContainerBottom = Math.max(
        0,
        containerBottom - videoBottom,
      );

      if (mobileVideoRect.top <= triggerPoint) {
        handleMobileVideoFixing(
          // mobileVideoRect,
          triggerPoint,
          maxScrollPosition,
          isMobileVideoFixed,
          setIsMobileVideoFixed,
          initialDimensions,
          initialFixedTop,
          setInitialFixedTop,
          setVideoStyles,
        );

        if (!isMobileVideoExpanded) {
          handleMobileVideoTranslation(
            videoRef,
            mobileVideoRect,
            triggerPoint,
            maxTranslation,
            distanceToContainerBottom,
          );

          handleMobileVideoExpansion(
            mobileVideoRect,
            triggerPoint,
            maxTranslation,
            distanceToContainerBottom,
            maxScrollPosition,
            currentVideoHeight,
            setIsMobileVideoExpanded,
            setVideoStyles,
            videoRef,
            scrollOffset,
            sidePadding,
          );
        }
      } else if (mobileVideoRect.top > triggerPoint && isMobileVideoFixed) {
        resetMobileVideo(
          setIsMobileVideoFixed,
          setIsMobileVideoExpanded,
          setInitialFixedTop,
          setVideoStyles,
          initialDimensions,
          videoRef,
        );
      }
    };

    const handleMobileVideoFixing = (
      // mobileVideoRect: DOMRect,
      triggerPoint: number,
      maxScrollPosition: number,
      isMobileVideoFixed: boolean,
      setIsMobileVideoFixed: React.Dispatch<React.SetStateAction<boolean>>,
      initialDimensions: Dimensions,
      initialFixedTop: number | null,
      setInitialFixedTop: React.Dispatch<React.SetStateAction<number | null>>,
      setVideoStyles: React.Dispatch<React.SetStateAction<React.CSSProperties>>,
    ): void => {
      if (!isMobileVideoFixed) {
        setIsMobileVideoFixed(true);
        setInitialFixedTop(window.scrollY + triggerPoint);
        setVideoStyles((prev) => ({
          ...prev,
          position: "absolute",
          top: `${window.scrollY + triggerPoint}px`,
          left: `${(window.innerWidth - parseInt(initialDimensions.width)) / 2}px`,
          zIndex: 40,
        }));
      } else {
        // Calculate new top position, ensuring it doesn't exceed container bottom
        const newTop = Math.min(
          window.scrollY + triggerPoint,
          maxScrollPosition,
        );

        if (initialFixedTop !== null) {
          setVideoStyles((prev) => ({
            ...prev,
            top: `${newTop}px`,
          }));
        }
      }
    };

    const handleMobileVideoTranslation = (
      videoRef: React.RefObject<HTMLElement>,
      mobileVideoRect: DOMRect,
      triggerPoint: number,
      maxTranslation: number,
      distanceToContainerBottom: number,
    ): void => {
      const scrolledPastTrigger = triggerPoint - mobileVideoRect.top;

      // Limit translation to prevent exceeding container bottom
      const maxAllowedTranslation = Math.min(
        maxTranslation,
        distanceToContainerBottom > 0 ? distanceToContainerBottom - 50 : 0,
      );

      const newTranslateY = Math.min(
        maxAllowedTranslation,
        scrolledPastTrigger,
      );

      // Apply translation only if it's valid and there's room
      if (newTranslateY > 0 && distanceToContainerBottom > newTranslateY) {
        videoRef.current!.style.transform = `translateY(${newTranslateY}px)`;
      } else {
        videoRef.current!.style.transform = "";
      }
    };

    const handleMobileVideoExpansion = (
      mobileVideoRect: DOMRect,
      triggerPoint: number,
      maxTranslation: number,
      distanceToContainerBottom: number,
      maxScrollPosition: number,
      currentVideoHeight: number,
      setIsMobileVideoExpanded: React.Dispatch<React.SetStateAction<boolean>>,
      setVideoStyles: React.Dispatch<React.SetStateAction<React.CSSProperties>>,
      videoRef: React.RefObject<HTMLElement>,
      scrollOffset: number,
      sidePadding: number,
    ): void => {
      const scrolledPastTrigger = triggerPoint - mobileVideoRect.top;

      // Check if we should expand - only if we haven't reached bottom yet
      if (
        scrolledPastTrigger >= maxTranslation &&
        distanceToContainerBottom > window.innerHeight / 3 &&
        window.scrollY + triggerPoint < maxScrollPosition
      ) {
        setIsMobileVideoExpanded(true);
        // Use absolute position with adjusted height
        const expandedTop = Math.min(
          window.scrollY + NAVBAR_HEIGHT + scrollOffset,
          maxScrollPosition -
            (window.innerHeight - NAVBAR_HEIGHT - scrollOffset) +
            currentVideoHeight,
        );

        setVideoStyles({
          position: "absolute",
          top: `${expandedTop}px`,
          left: "50%",
          width: `${window.innerWidth - sidePadding}px`,
          height: `${window.innerHeight - NAVBAR_HEIGHT - scrollOffset}px`,
          margin: 0,
          transformOrigin: "center",
          transform: "translateX(-50%)",
        });
        videoRef.current!.style.transform = "";
      }
    };

    const resetMobileVideo = (
      setIsMobileVideoFixed: React.Dispatch<React.SetStateAction<boolean>>,
      setIsMobileVideoExpanded: React.Dispatch<React.SetStateAction<boolean>>,
      setInitialFixedTop: React.Dispatch<React.SetStateAction<number | null>>,
      setVideoStyles: React.Dispatch<React.SetStateAction<React.CSSProperties>>,
      initialDimensions: Dimensions,
      videoRef: React.RefObject<HTMLElement>,
    ): void => {
      setIsMobileVideoFixed(false);
      setIsMobileVideoExpanded(false);
      setInitialFixedTop(null);
      setVideoStyles({
        width: initialDimensions.width,
        height: initialDimensions.height,
        position: "relative",
        top: "auto",
        left: "auto",
        margin: "0 auto",
        transformOrigin: "center",
        transform: "translateX(0)",
      });
      videoRef.current!.style.transform = "";
    };

    // Main scroll handler function
    const handleScroll = (): void => {
      if (videoRef.current && containerRef.current && cardsRef.current) {
        const videoIsVisible = isInViewport(videoRef.current);
        setIsVideoVisible(videoIsVisible);

        if (!isMobile) {
          handleDesktopScroll(
            videoRef,
            containerRef,
            initialDimensions,
            isExpanded,
            setIsExpanded,
            setVideoStyles,
            SCROLL_OFFSET,
            SIDE_PADDING,
          );
        } else {
          if (mobileVideoRef.current) {
            handleMobileScroll(
              videoRef,
              mobileVideoRef,
              containerRef,
              initialDimensions,
              isMobileVideoFixed,
              setIsMobileVideoFixed,
              isMobileVideoExpanded,
              setIsMobileVideoExpanded,
              setVideoStyles,
              initialFixedTop,
              setInitialFixedTop,
              SCROLL_OFFSET,
              SIDE_PADDING,
            );
          }
        }
      }
      return;
    };

    // Use requestAnimationFrame for scroll throttling
    let isScrolling = false;

    const onScroll = () => {
      if (!isScrolling) {
        window.requestAnimationFrame(() => {
          handleScroll();
          isScrolling = false;
        });
        isScrolling = true;
      }
    };

    window.addEventListener("scroll", onScroll);

    // Initial check on mount
    if (videoRef.current) {
      setIsVideoVisible(isInViewport(videoRef.current));
    }

    return () => window.removeEventListener("scroll", onScroll);
  }, [
    isMobile,
    initialDimensions,
    SIDE_PADDING,
    isExpanded,
    isMobileVideoFixed,
    isMobileVideoExpanded,
    initialFixedTop,
  ]);

  return (
    <section
      className={`mt-16 min-h-[4000px] p-6 md:min-h-[300vh]`}
      ref={containerRef}
    >
      <h1 className="text-2xl font-semibold md:text-5xl">
        {t("aboutPage.mealsSection.title")}
      </h1>
      <div
        className={`relative order-1 mt-8 grid h-[2470px] grid-cols-2  flex-col md:order-none md:grid md:h-[300vh] md:grid-cols-5 md:flex-row`}
        ref={cardsRef}
      >
        {/* First Column */}
        <div>
          <div className={`px-2 md:w-auto md:px-0`}>
            {t("aboutPage.mealsSection.cards.card1.visible") === "true" && (
              <div className="flex h-60 w-full flex-col justify-end rounded-lg bg-[#E2DDD7] p-4">
                <h1 className="text-5xl">
                  {t("aboutPage.mealsSection.cards.card1.amount")}
                </h1>
                <p>{t("aboutPage.mealsSection.cards.card1.label")}</p>
              </div>
            )}
            {t("aboutPage.mealsSection.cards.card2.visible") === "true" && (
              <div className="relative mt-4 flex h-80 w-full flex-col justify-end rounded-lg bg-[url('/meals-1.jpeg')] bg-cover bg-center p-4">
                <div className="absolute inset-0 rounded-md bg-black/50"></div>
                <div className="relative">
                  <h1 className="text-5xl text-[#E2DDD7]">
                    {t("aboutPage.mealsSection.cards.card2.amount")}
                  </h1>
                  <p className="text-[#E2DDD7]">
                    {t("aboutPage.mealsSection.cards.card2.label")}
                  </p>
                </div>
              </div>
            )}
          </div>
          <div className={`mt-8 px-2 md:w-auto md:px-0`}>
            {t("aboutPage.mealsSection.cards.card9.visible") === "true" && (
              <div className="flex h-60 w-full flex-col justify-end rounded-lg bg-[#E2DDD7] p-4">
                <h1 className="text-5xl">
                  {t("aboutPage.mealsSection.cards.card9.amount")}
                </h1>
                <p>{t("aboutPage.mealsSection.cards.card9.label")}</p>
              </div>
            )}
            {t("aboutPage.mealsSection.cards.card10.visible") === "true" && (
              <div className="relative mt-4 flex h-80 w-full flex-col justify-end rounded-lg bg-[url('/meals-1.jpeg')] bg-cover bg-center p-4">
                <div className="absolute inset-0 rounded-md bg-black/50"></div>
                <div className="relative">
                  <h1 className="text-5xl text-[#E2DDD7]">
                    {t("aboutPage.mealsSection.cards.card10.amount")}
                  </h1>
                  <p className="text-[#E2DDD7]">
                    {t("aboutPage.mealsSection.cards.card10.label")}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* Second Column */}
        <div>
          <div className={`order-3 mt-12 px-2 md:order-none md:w-auto`}>
            {t("aboutPage.mealsSection.cards.card3.visible") === "true" && (
              <div className="relative flex h-80 w-full flex-col justify-end rounded-lg bg-[url('/meals-2.png')] bg-cover bg-center p-4">
                <div className="absolute inset-0 rounded-md bg-black/50"></div>
                <div className="relative">
                  <h1 className="text-5xl text-[#E2DDD7]">
                    {t("aboutPage.mealsSection.cards.card3.amount")}
                  </h1>
                  <p className="text-[#E2DDD7]">
                    {t("aboutPage.mealsSection.cards.card3.label")}
                  </p>
                </div>
              </div>
            )}
            {t("aboutPage.mealsSection.cards.card4.visible") === "true" && (
              <div className="mt-4 flex h-60 w-full flex-col justify-end rounded-lg bg-[#E2DDD7] p-4">
                <h1 className="text-5xl">
                  {t("aboutPage.mealsSection.cards.card4.amount")}
                </h1>
                <p>{t("aboutPage.mealsSection.cards.card4.label")}</p>
              </div>
            )}
          </div>
          <div className={`order-3 mt-12 px-2 md:order-none md:w-auto`}>
            {t("aboutPage.mealsSection.cards.card11.visible") === "true" && (
              <div className="relative flex h-80 w-full flex-col justify-end rounded-lg bg-[url('/meals-2.png')] bg-cover bg-center p-4">
                <div className="absolute inset-0 rounded-md bg-black/50"></div>
                <div className="relative">
                  <h1 className="text-5xl text-[#E2DDD7]">
                    {t("aboutPage.mealsSection.cards.card11.amount")}
                  </h1>
                  <p className="text-[#E2DDD7]">
                    {t("aboutPage.mealsSection.cards.card11.label")}
                  </p>
                </div>
              </div>
            )}
            {t("aboutPage.mealsSection.cards.card12.visible") === "true" && (
              <div className="mt-4 flex h-60 w-full flex-col justify-end rounded-lg bg-[#E2DDD7] p-4">
                <h1 className="text-5xl">
                  {t("aboutPage.mealsSection.cards.card12.amount")}
                </h1>
                <p>{t("aboutPage.mealsSection.cards.card12.label")}</p>
              </div>
            )}
          </div>
        </div>
        {/* Video Column (Desktop) */}
        {!isMobile && (
          <div
            className={`order-5 h-full w-full md:order-none`}
            ref={videoOriginalColumn}
          >
            <div
              className="absolute left-0 top-32 flex min-h-80 flex-col justify-end rounded-lg bg-cover bg-center transition-all duration-1000 ease-in-out"
              style={{
                ...videoStyles,
                transform: videoStyles.transform || "translateZ(0)", // Force GPU acceleration
              }}
            >
              <video
                src="/meals.mp4"
                controls
                loop
                muted
                className="h-full w-full rounded-lg object-cover"
                ref={videoRef}
              />
            </div>
          </div>
        )}
        {/* Third Column */}
        <div>
          <div
            className={`order-2 mt-4  px-2 md:order-none md:mt-12 md:w-auto`}
          >
            {t("aboutPage.mealsSection.cards.card5.visible") === "true" && (
              <div className="flex h-60 w-full flex-col justify-end rounded-lg bg-[#E2DDD7] p-4">
                <h1 className="text-5xl">
                  {t("aboutPage.mealsSection.cards.card5.amount")}
                </h1>
                <p>{t("aboutPage.mealsSection.cards.card5.label")}</p>
              </div>
            )}
            {t("aboutPage.mealsSection.cards.card6.visible") === "true" && (
              <div className="relative mt-4 flex h-80 w-full flex-col justify-end rounded-lg bg-[url('/meals-3.jpeg')] bg-cover bg-center p-4">
                <div className="absolute inset-0 rounded-md bg-black/50"></div>
                <div className="relative">
                  <h1 className="text-5xl text-[#E2DDD7]">
                    {t("aboutPage.mealsSection.cards.card6.amount")}
                  </h1>
                  <p className="text-[#E2DDD7]">
                    {t("aboutPage.mealsSection.cards.card6.label")}
                  </p>
                </div>
              </div>
            )}
          </div>
          <div className={`order-2 mt-4 px-2 md:order-none md:mt-12 md:w-auto`}>
            {t("aboutPage.mealsSection.cards.card13.visible") === "true" && (
              <div className="flex h-60 w-full flex-col justify-end rounded-lg bg-[#E2DDD7] p-4">
                <h1 className="text-5xl">
                  {t("aboutPage.mealsSection.cards.card13.amount")}
                </h1>
                <p>{t("aboutPage.mealsSection.cards.card13.label")}</p>
              </div>
            )}
            {t("aboutPage.mealsSection.cards.card14.visible") === "true" && (
              <div className="relative mt-4 flex h-80 w-full flex-col justify-end rounded-lg bg-[url('/meals-3.jpeg')] bg-cover bg-center p-4">
                <div className="absolute inset-0 rounded-md bg-black/50"></div>
                <div className="relative">
                  <h1 className="text-5xl text-[#E2DDD7]">
                    {t("aboutPage.mealsSection.cards.card14.amount")}
                  </h1>
                  <p className="text-[#E2DDD7]">
                    {t("aboutPage.mealsSection.cards.card14.label")}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* Fourth Column */}
        <div>
          <div
            className={` order-4 mt-4 px-2 md:order-none md:mt-0 md:w-auto md:px-0`}
          >
            {t("aboutPage.mealsSection.cards.card7.visible") === "true" && (
              <div className="relative flex h-80 w-full flex-col justify-end rounded-lg bg-[url('/meals-4.jpeg')] bg-cover bg-center p-4">
                <div className="absolute inset-0 rounded-md bg-black/50"></div>
                <div className="relative">
                  <h1 className="text-5xl text-[#E2DDD7]">
                    {t("aboutPage.mealsSection.cards.card7.amount")}
                  </h1>
                  <p className="text-[#E2DDD7]">
                    {t("aboutPage.mealsSection.cards.card7.label")}
                  </p>
                </div>
              </div>
            )}
            {t("aboutPage.mealsSection.cards.card8.visible") === "true" && (
              <div className="mt-4 flex h-60 w-full flex-col justify-end rounded-lg bg-[#E2DDD7] p-4">
                <h1 className="text-5xl">
                  {t("aboutPage.mealsSection.cards.card8.amount")}
                </h1>
                <p>{t("aboutPage.mealsSection.cards.card8.label")}</p>
              </div>
            )}
          </div>
          <div
            className={`order-4 mt-4 px-2 md:order-none md:mt-12 md:w-auto md:px-0`}
          >
            {t("aboutPage.mealsSection.cards.card15.visible") === "true" && (
              <div className="relative flex h-80 w-full flex-col justify-end rounded-lg bg-[url('/meals-4.jpeg')] bg-cover bg-center p-4">
                <div className="absolute inset-0 rounded-md bg-black/50"></div>
                <div className="relative">
                  <h1 className="text-5xl text-[#E2DDD7]">
                    {t("aboutPage.mealsSection.cards.card15.amount")}
                  </h1>
                  <p className="text-[#E2DDD7]">
                    {t("aboutPage.mealsSection.cards.card15.label")}
                  </p>
                </div>
              </div>
            )}
            {t("aboutPage.mealsSection.cards.card16.visible") === "true" && (
              <div className="mt-4 flex h-60 w-full flex-col justify-end rounded-lg bg-[#E2DDD7] p-4">
                <h1 className="text-5xl">
                  {t("aboutPage.mealsSection.cards.card16.amount")}
                </h1>
                <p>{t("aboutPage.mealsSection.cards.card16.label")}</p>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Mobile Video */}
      {isMobile && (
        <div className={`mx-auto`} ref={mobileVideoRef}>
          <div
            className={`relative flex flex-col justify-end rounded-lg bg-cover bg-center transition-all duration-500 ease-in-out`}
            style={{
              ...videoStyles,
            }}
          >
            <video
              src="/meals.mp4"
              controls
              loop
              muted
              className="z-40 h-full w-full rounded-lg object-cover"
              ref={videoRef}
            />
          </div>
        </div>
      )}
    </section>
  );
};

export default MealsSection;
