import { useState } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { useTranslation } from "react-i18next";

export const SliderSection: React.FC = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir() === "rtl";

  const slides = [
    {
      img: "/slider-1.png",
      heading: t("aboutPage.sliderSection.slides.slide1.heading"),
      subheading: t("aboutPage.sliderSection.slides.slide1.subheading"),
      description: t("aboutPage.sliderSection.slides.slide1.description"),
    },
    {
      img: "/slider-2.png",
      heading: t("aboutPage.sliderSection.slides.slide2.heading"),
      subheading: t("aboutPage.sliderSection.slides.slide2.subheading"),
      description: t("aboutPage.sliderSection.slides.slide2.description"),
    },
    {
      img: "/slider-3.png",
      heading: t("aboutPage.sliderSection.slides.slide3.heading"),
      subheading: t("aboutPage.sliderSection.slides.slide3.subheading"),
      description: t("aboutPage.sliderSection.slides.slide3.description"),
    },
  ];

  const handleNextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };

  const handlePrevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };

  return (
    <section
      className="mt-16 flex w-full flex-col md:mb-32 md:flex-row"
      dir={isRTL ? "rtl" : "ltr"}
    >
      <div className="flex flex-1 flex-col justify-center p-6">
        <h1 className="text-center text-xl font-semibold md:text-start md:text-5xl">
          {t("aboutPage.sliderSection.title")}: {t("aboutPage.period")}
        </h1>
        <div className="mt-4 inline-flex items-center justify-center gap-5 md:mt-12 md:justify-start">
          <button
            className={`flex h-12 w-12 items-center justify-center gap-2.5 rounded ${
              currentSlide === 0 ? "bg-[#E2DDD7]" : "bg-[#232329]"
            }`}
            onClick={handlePrevSlide}
            disabled={currentSlide === 0}
          >
            {isRTL ? (
              <ChevronRight
                stroke={currentSlide === 0 ? "#232329" : "#EDEAE4"}
              />
            ) : (
              <ChevronLeft
                stroke={currentSlide === 0 ? "#232329" : "#EDEAE4"}
              />
            )}
          </button>
          <button
            className={`flex h-12 w-12 items-center justify-center gap-2.5 rounded ${
              currentSlide === slides.length - 1
                ? "bg-[#E2DDD7]"
                : "bg-[#232329]"
            }`}
            onClick={handleNextSlide}
            disabled={currentSlide === slides.length - 1}
          >
            {isRTL ? (
              <ChevronLeft
                stroke={
                  currentSlide === slides.length - 1 ? "#232329" : "#EDEAE4"
                }
              />
            ) : (
              <ChevronRight
                stroke={
                  currentSlide === slides.length - 1 ? "#232329" : "#EDEAE4"
                }
              />
            )}
          </button>
        </div>
      </div>

      <div className="relative mb-8 h-[250px] px-8 md:h-[500px] md:max-w-sm md:flex-1 md:px-0">
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`absolute h-full rounded-lg px-6 transition-all duration-500 ease-in-out ${
              currentSlide === index
                ? "left-0 top-0 z-40 w-full opacity-100"
                : ""
            } ${
              index < currentSlide
                ? "-translate-x-full -translate-y-full opacity-0"
                : ""
            } ${
              index > currentSlide
                ? `top-${(index - currentSlide) * 5} ${
                    isRTL
                      ? "right-1/2 translate-x-1/2"
                      : "left-1/2 -translate-x-1/2"
                  } z-${40 - (index - currentSlide) * 10} w-[${100 - (index - currentSlide) * 10}%]`
                : ""
            }`}
          >
            <img
              src={slide.img}
              alt={`Slide ${index + 1}`}
              className="h-full w-full rounded-lg object-cover object-center"
            />
          </div>
        ))}
      </div>

      <div className="flex flex-1 flex-col overflow-hidden px-6 pt-6 md:flex-col md:justify-end md:py-4 md:pl-16 md:pr-8 md:pt-0">
        <div className="mt-4">
          <div className="relative h-8 overflow-hidden md:h-20">
            <h1
              key={currentSlide}
              className="animate-roll-up absolute w-full text-lg md:text-5xl"
            >
              {slides[currentSlide].heading}
            </h1>
          </div>

          <div className="relative h-8 overflow-hidden">
            <p key={currentSlide} className="animate-roll-up absolute w-full">
              {slides[currentSlide].subheading}
            </p>
          </div>

          <div className="relative mt-1 h-32 overflow-hidden md:mt-4">
            <p
              key={currentSlide}
              className="animate-roll-up md:text-md absolute w-full text-sm"
            >
              {slides[currentSlide].description}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
