import { Checkbox } from "@/components/ui/checkbox";
import { useTranslation } from "react-i18next";
import { Formik, Form, Field, FieldProps } from "formik";
import * as Yup from "yup";
import { Textarea } from "../../ui/textarea";
import { useFormStore } from "@/contexts/paymentFlowContext";
import { InputText } from "@/components/ui/inputText";

export function DedicationForm() {
  const { t } = useTranslation();

  const isDedicated = useFormStore((state) => state.isDedicated);
  const setIsDedicated = useFormStore((state) => state.setIsDedicated);
  const honoreeName = useFormStore((state) => state.honoreeName);
  const setHonoreeName = useFormStore((state) => state.setHonoreeName);
  const honoreeEmail = useFormStore((state) => state.honoreeEmail);
  const setHonoreeEmail = useFormStore((state) => state.setHonoreeEmail);
  const dedicationMessage = useFormStore((state) => state.dedicationMessage);
  const setDedicationMessage = useFormStore(
    (state) => state.setDedicationMessage,
  );

  const initialValues = {
    honoreeName,
    honoreeEmail,
    dedicationMessage,
  };

  const validationSchema = Yup.object().shape({
    honoreeName: Yup.string().required(t("Please enter honoree name")),
    honoreeEmail: Yup.string().email(t("Please enter valid email")),
    dedicationMessage: Yup.string(),
  });

  const handleFormikChange =
    (
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean,
      ) => void,
    ) =>
    (e: { target: { name: string; value: any } }) => {
      const { name, value } = e.target;
      setFieldValue(name, value, true);
      if (name === "honoreeName") {
        setHonoreeName(value);
      } else if (name === "honoreeEmail") {
        setHonoreeEmail(value);
      } else if (name === "dedicationMessage") {
        setDedicationMessage(value);
      }
    };

  return (
    <div className="mt-6">
      <div className="flex items-center">
        <Checkbox
          checked={isDedicated}
          onCheckedChange={() => setIsDedicated(!isDedicated)}
          className="ltr:mr-2 rtl:ml-2"
        />
        <label className="text-sm md:text-base">
          {t("donationFlow.dedicate")}
        </label>
      </div>
      {isDedicated && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            setHonoreeName(values.honoreeName);
            setHonoreeEmail(values.honoreeEmail);
            setDedicationMessage(values.dedicationMessage);
          }}
          validateOnChange={true}
          validateOnBlur={true}
        >
          {({ setFieldValue, values, errors, touched }) => (
            <Form className="mt-4 rounded border border-[#CCC2BF] bg-[#E2DDD7] p-4">
              <div className="mb-4">
                <label
                  htmlFor="honoreeName"
                  className="mb-2 block text-xs font-bold md:text-sm"
                >
                  {t("donationFlow.honoree")}
                </label>
                <Field name="honoreeName">
                  {({ field }: FieldProps) => (
                    <>
                      <InputText
                        {...field}
                        id="honoreeName"
                        placeholder={t("donationFlow.enterHonoreeName")}
                        onChange={(e) => {
                          field.onChange(e);
                          handleFormikChange(setFieldValue)(e);
                        }}
                        value={values.honoreeName}
                      />
                      {touched.honoreeName && errors.honoreeName && (
                        <div className="pt-2 text-sm text-[#F04438]">
                          {errors.honoreeName}
                        </div>
                      )}
                    </>
                  )}
                </Field>
              </div>
              <div className="mb-4">
                <label
                  htmlFor="honoreeEmail"
                  className="mb-2 block text-xs font-bold md:text-sm"
                >
                  {t("donationFlow.honoreeEmail")}{" "}
                  <span className="text-[#22222a80]">
                    {t("donationFlow.optional")}
                  </span>
                </label>
                <Field name="honoreeEmail">
                  {({ field }: FieldProps) => (
                    <>
                      <InputText
                        {...field}
                        id="honoreeEmail"
                        type="email"
                        placeholder={t("donationFlow.enterHonoreeEmail")}
                        onChange={(e) => {
                          field.onChange(e);
                          handleFormikChange(setFieldValue)(e);
                        }}
                        value={values.honoreeEmail}
                      />
                      {touched.honoreeEmail && errors.honoreeEmail && (
                        <div className="pt-2 text-sm text-[#F04438]">
                          {errors.honoreeEmail}
                        </div>
                      )}
                    </>
                  )}
                </Field>
              </div>
              <div className="">
                <label
                  htmlFor="dedicationMessage"
                  className="mb-2 block text-xs font-bold text-foreground md:text-sm"
                >
                  {t("donationFlow.dedicationMessage")}{" "}
                  <span className="text-[#22222a80]">
                    {t("donationFlow.optional")}
                  </span>
                </label>
                <Textarea
                  name="dedicationMessage"
                  id="dedicationMessage"
                  placeholder={t("donationFlow.enterMessage")}
                  onChange={(e) => handleFormikChange(setFieldValue)(e)}
                  value={values.dedicationMessage}
                />
                {touched.dedicationMessage && errors.dedicationMessage && (
                  <div className="pt-2 text-sm text-[#F04438]">
                    {errors.dedicationMessage}
                  </div>
                )}
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
}
