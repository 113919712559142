import { ProgramType } from "@/types/programs";
import { useFormStore } from "@/contexts/paymentFlowContext";
import { useLocation } from "@tanstack/react-router";
import { Route } from "@/routes/paymentFlow.$programId";
import { programQueryOptions } from "@/api/program";
import { suggestedAmountsQueryOptions } from "@/api/suggested-amounts";
import { useQuery } from "@tanstack/react-query";
import StepsContainer from "@/components/PaymentFlow/StepsContainer";
import { useTranslation } from "react-i18next";
import PaymentFlowContainer from "@/components/PaymentFlow/paymentFlowContainer";
import { useEffect } from "react";
import { livestockQueryOptions } from "@/api/livestock";
import { itemQueryOptions } from "@/api/items";
import i18n from "@/i18n";
import { Link } from "@tanstack/react-router";
import { isRTLLanguage } from "@/utils/helpers";

type DonationStepperProps = {
  readonly program: ProgramType;
};

export function DonationStepper({ program }: DonationStepperProps) {
  const {
    currentStep,
    setIsSpecial,
    setIsItemization,
    setDonationType,
    setSpecialType,
  } = useFormStore();
  const { data: suggestedAmounts } = useQuery(
    suggestedAmountsQueryOptions({
      program_id: program.id,
    }),
  );
  const { data: livestock } = useQuery(
    livestockQueryOptions({ program_id: program.id, lang: i18n.language }),
  );

  const { data: items } = useQuery(
    itemQueryOptions({ program_id: program.id, lang: i18n.language }),
  );

  useEffect(() => {
    setIsSpecial(program.is_special);
    setIsItemization(program.is_itemization);
    if (program.is_special && program.donation_types.length > 0) {
      setDonationType(program.donation_types[0].id);
      program.special_type && setSpecialType(program.special_type);
    }
  }, [program]);

  return (
    <div className="flex h-full flex-col">
      <StepsContainer
        step={currentStep}
        program={program}
        suggestedAmounts={suggestedAmounts}
        livestock={livestock}
        items={items}
      />
    </div>
  );
}

export const PaymentFlow = () => {
  const { t } = useTranslation();
  const { programId } = Route.useParams();
  const { search } = useLocation();
  const { i18n } = useTranslation();
  const { setSelectedProgram } = useFormStore();

  const { data: program } = useQuery(
    programQueryOptions(programId, {
      lang: i18n.language,
      shared: (search?.shared ?? "false").toString(),
    }),
  );

  useEffect(() => {
    if (program) {
      setSelectedProgram(program);
    }
  }, [program, setSelectedProgram]);

  return program ? (
    <>
      <div className="fixed z-50 flex h-14 w-full items-center justify-between bg-foreground px-4 md:px-[24px]">
        <div className="flex flex-row gap-1 text-white">
          {t("join.first_part")}{" "}
          <Link
            aria-label={t("join.first_part") + " " + t("join.second_part")}
            to="/community-challenge"
            search={{ tab: "" }}
          >
            <p className="font-bold text-white underline">
              {" "}
              {t("join.second_part")}
            </p>
          </Link>
        </div>
        <Link
          aria-label={
            t("poweredBy.first_part") + " " + t("poweredBy.second_part")
          }
          to="/"
        >
          {isRTLLanguage(i18n.language) ? (
            <img src="/coh-logo-ar-white.svg" className="h-4 md:h-6" />
          ) : (
            <img src="/coh-logo-white.svg" className="h-8" />
          )}
        </Link>
      </div>

      <div className="fixed top-[32px] flex w-full flex-col overflow-hidden bg-[#CDCFC9] text-white md:hidden">
        <h2 className="block p-4 pb-8 text-lg font-bold md:hidden">
          {program.title}
        </h2>
      </div>
      <PaymentFlowContainer program={program}>
        <div className="h-screen w-full overflow-y-auto rounded-t-2xl bg-background p-4 pb-0 sm:h-full md:flex-1 md:rounded-none lg:p-6 lg:pb-0 lg:pt-6">
          <DonationStepper program={program} />
        </div>
      </PaymentFlowContainer>
    </>
  ) : null;
};
