import React from "react";
import clsx from "clsx";
import { Button } from "../ui/button";
import { CrossIcon } from "@/assets/icons/CrossIcon";
import { ArrowLeftToRightIcon } from "@/assets/icons/ArrowLeftToRightIcon";
import { Link } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";
import { useGetPrograms } from "@/api/program";
import { Plus } from "lucide-react";
import useChallengeFormStore from "@/store/challengeStore";

const SidebarItem = ({
  title,
  link,
  icon,
  toggleSidebar,
  resetForm,
  framed,
  inverted,
  search,
}: {
  title: string;
  link: string;
  icon?: React.ReactNode;
  toggleSidebar: () => void;
  resetForm?: () => void;
  framed?: boolean;
  inverted?: boolean;
  search?: any;
}) => {
  return (
    <Link
      to={link}
      onClick={() => {
        toggleSidebar();
        resetForm && resetForm();
      }}
      className="font-normal"
      aria-label="Go to program"
      search={search ?? undefined}
    >
      <div
        className={`dark mt-2 flex items-center justify-between md:mt-4 ${
          framed ? "rounded-lg border border-foreground p-4" : ""
        } ${inverted ? "bg-foreground text-black" : ""}`}
      >
        <h4
          className={`${framed ? "text-base md:text-lg" : "text-lg md:text-2xl"}
        ${inverted ? "text-black" : ""}`}
        >
          {title}
        </h4>
        <Button
          className="min-w-6 p-0 dark:bg-transparent rtl:rotate-180"
          aria-label="Go to program"
          onClick={() => {
            resetForm && resetForm();
          }}
        >
          {icon}
        </Button>
      </div>
    </Link>
  );
};

interface SidebarProps {
  isOpen: boolean;
  toggleSidebar: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ isOpen, toggleSidebar }) => {
  const { t, i18n } = useTranslation();
  const { data: programs } = useGetPrograms({
    ordering: "rank",
    page_size: "3",
    lang: i18n.language,
  });
  const { resetForm } = useChallengeFormStore();

  return (
    <>
      <Button
        className={clsx(
          "fixed top-0 z-20 h-full w-full bg-gray-900 opacity-50 hover:bg-gray-900",
          {
            hidden: !isOpen,
          },
        )}
        onMouseDown={toggleSidebar}
        tabIndex={0}
        aria-label="Close sidebar"
      ></Button>
      <div
        className={clsx(
          "dark fixed right-0 top-0 z-40 flex h-full w-full transform flex-col bg-[#22222A] text-background transition-transform duration-300 ease-in-out lg:w-2/6 lg:min-w-[592px] lg:rounded-s-lg rtl:left-0",
          {
            "translate-x-0": isOpen,
            "translate-x-full rtl:-translate-x-full !duration-0": !isOpen,
          },
        )}
      >
        <div className="dark z-30 my-6 flex flex-row items-center justify-between md:mt-16">
          <Button
            onClick={toggleSidebar}
            className="ml-auto h-[25px] bg-transparent p-[2px] text-background hover:bg-transparent dark:bg-transparent"
            aria-label="Close sidebar"
          >
            <CrossIcon />
          </Button>
        </div>
        <div className="m-6">
          <SidebarItem
            title={t("sidebar.all_programs")}
            link="/programs"
            icon={
              <ArrowLeftToRightIcon className="h-[25px] fill-[#fafafa] px-0" />
            }
            toggleSidebar={toggleSidebar}
          />
          {programs?.map((program) => (
            <SidebarItem
              key={program.id}
              title={program.title}
              link={`/program/${program.id}`}
              icon={
                <ArrowLeftToRightIcon className="h-[25px] fill-[#fafafa] px-0" />
              }
              toggleSidebar={toggleSidebar}
            />
          ))}
          <div className="pt-8">
            <SidebarItem
              title={t("sidebar.challenge")}
              link="/community-challenge"
              search={{ tab: "" }}
              icon={<Plus className="h-[25px] fill-[#fafafa] px-0" />}
              toggleSidebar={toggleSidebar}
              resetForm={resetForm}
              framed={true}
            />
          </div>
          <div className="">
            <SidebarItem
              title={t("sidebar.sadaqah")}
              link="/community-challenge"
              search={{ tab: "Sadaqah Jariya" }}
              icon={<Plus className="h-[25px] stroke-black px-0" />}
              toggleSidebar={toggleSidebar}
              resetForm={resetForm}
              framed={true}
              inverted={true}
            />
          </div>
        </div>
        <div className="m-6 mb-8 mt-auto text-sm md:mb-16 md:text-base">
          <ul className="[&_a]:font-normal [&_a]:text-white [&_li]:my-2">
            <li>
              <Link aria-label="Go to about us page" to="/aboutUs">
                {t("sidebar.about")}
              </Link>
            </li>
            <li>
              <Link aria-label="Go to terms of service page" to="/faqs">
                {t("sidebar.faqs")}
              </Link>
            </li>
            <li>
              <Link aria-label="Go to contact page" to="/contact">
                {t("header.contact")}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
