import { Progress } from "@/components/ui/progress";
import clsx from "clsx";
import { ProgramType } from "@/types/programs";
import NumberTicker from "@/components/Helpers/NumberTicker";
import useDonationData from "../useDonationData";
import { formatCurrency, getProgramGoal } from "@/utils/currency";
import { Button } from "@/components/ui/button";
import { useRouter } from "@tanstack/react-router";
import { useFormStore } from "@/contexts/paymentFlowContext";
import { ShareIcon } from "@/assets/icons/ShareIcon";
import Share from "@/components/Share/Share";
import { shareDataFormatter } from "@/utils/share";
import ReactGA from "react-ga4";
interface SmallDonationBoxProps {
  bgColor: string;
  className?: string;
  showTitle?: boolean;
  title: string;
  program: ProgramType;
}

export const SmallDonationBox = ({
  bgColor,
  className,
  showTitle,
  title,
  program,
}: SmallDonationBoxProps) => {
  const { t, currency, raisedAmount, raisedPercentage } =
    useDonationData(program);
  const { reset } = useFormStore();
  const shareData = shareDataFormatter(program);
  const { navigate } = useRouter();

  const handleDonateNow = () => {
    reset();
    if (program) {
      navigate({
        to: `/paymentFlow/${program.id}`,
        search: { backUrl: location.pathname },
      });
    }
  };

  const handleSuccess = () => {
    ReactGA.event({
      category: "Social Media Share",
      action: "social_media_share",
      label: "Mobile",
    });
  };

  return (
    <div
      key={`${program.id}-${currency}`}
      className={clsx(
        "dark fixed bottom-0 z-[6] flex w-full flex-col gap-4 rounded-lg p-7 md:hidden",
        className,
      )}
      style={{ backgroundColor: bgColor }}
    >
      {showTitle && !program.is_special && (
        <h1 className="text-small font-bold leading-[140%] text-light">
          {title}
        </h1>
      )}
      {!program.is_special && (
        <>
          <Progress
            value={raisedPercentage}
            className="h-[6px] w-full rounded-[2px] bg-[#D6D9DC]"
            aria-label="Donation amount progress bar"
          />
          <div className="flex w-full flex-col space-y-2">
            <div className="flex justify-between">
              <div className="flex flex-col items-start space-y-1">
                <p className="text-xl font-semibold text-light">
                  <NumberTicker
                    value={raisedAmount}
                    initialValue={raisedAmount}
                  />
                  ({raisedPercentage.toFixed(1)}%)
                </p>
                <p className="font-light text-light">
                  {t("ProgramCard.raised_label")} -{" "}
                  {raisedPercentage.toFixed(1)}%
                </p>
              </div>
              <div className="flex flex-col items-start space-y-1">
                <p className="text-xl font-semibold text-light">
                  {formatCurrency(getProgramGoal(program))}
                </p>
                <p className="font-light text-light">
                  {t("ProgramCard.goal_label")}
                </p>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="flex w-full flex-row items-center space-x-4">
        <Button
          className="h-[54px] w-full px-5 py-4 font-bold md:min-w-[170px]"
          onClick={handleDonateNow}
        >
          {t("ProgramCard.donate_now")}
        </Button>
        <Share
          shareData={shareData}
          programId={program.id}
          onSuccess={handleSuccess}
        >
          <ShareIcon className="fill-[#22222A]" />
        </Share>
      </div>
    </div>
  );
};

export default SmallDonationBox;
