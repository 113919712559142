interface GovDetails {
  code: string;
  beneficiaries: string;
  cbos: number;
  services: string;
  servicesAr: string;
}
const allGovDetails: GovDetails[] = [
  {
    code: "gn1",
    beneficiaries: "4,760", // updated
    cbos: 11,
    services: "Protection",
    servicesAr: "حماية ",
  },
  {
    code: "gn2",
    beneficiaries: "16,525",
    cbos: 46,
    services: "Protection, Prevention ",
    servicesAr: "حماية، وقاية",
  },
  {
    code: "gn3",
    beneficiaries: "3,495",
    cbos: 8,
    services: "Protection, Prevention ",
    servicesAr: "حماية، وقاية ",
  },
  {
    code: "gn4",
    beneficiaries: "8,425", // updated
    cbos: 23,
    services: "Protection, Prevention, Empowerment",
    servicesAr: "حماية، وقاية، تمكين",
  },
  {
    code: "gn5",
    beneficiaries: "1,485",
    cbos: 8,
    services: "Protection ",
    servicesAr: "حماية ",
  },
  {
    code: "gn6",
    beneficiaries: "645",
    cbos: 3,
    services: "Protection, Prevention",
    servicesAr: "حماية، وقاية ",
  },
  {
    code: "gn7",
    beneficiaries: "1,115",
    cbos: 4,
    services: "Protection",
    servicesAr: "حماية ",
  },
  {
    code: "gn8",
    beneficiaries: "33,780",
    cbos: 90,
    services: "Protection, Prevention ",
    servicesAr: "حماية، وقاية ",
  },
  {
    code: "gn9",
    beneficiaries: "12,875", // updated
    cbos: 36,
    services: "Protection, Prevention ",
    servicesAr: "حماية، وقاية ",
  },
  {
    code: "gn10",
    beneficiaries: "2,455", // updated
    cbos: 3,
    services: "Protection",
    servicesAr: "حماية ",
  },
  {
    code: "gn11",
    beneficiaries: "4,965", // updated
    cbos: 17, // updated
    services: "Protection, Prevention ",
    servicesAr: "حماية، وقاية ",
  },
  {
    code: "gn12",
    beneficiaries: "21,985",
    cbos: 46,
    services: "Protection, Prevention",
    servicesAr: "حماية، وقاية ",
  },
  {
    code: "gn13",
    beneficiaries: "8,860",
    cbos: 32,
    services: "Protection, Prevention, Empowerment",
    servicesAr: "حماية، وقاية، تمكين",
  },
  {
    code: "gn14",
    beneficiaries: "1,940",
    cbos: 5,
    services: "Protection",
    servicesAr: "حماية",
  },
  {
    code: "gn15",
    beneficiaries: "81,825",
    cbos: 71,
    services: "Protection, Prevention",
    servicesAr: "حماية، وقاية ",
  },
  {
    code: "gn16",
    beneficiaries: "37,485", // updated
    cbos: 90, // updated
    services: "Protection, Prevention",
    servicesAr: "حماية، وقاية",
  },
  {
    code: "gn17",
    beneficiaries: "39,990", // updated
    cbos: 91, // updated
    services: "Protection, Prevention, Empowerment",
    servicesAr: "حماية، وقاية، تمكين",
  },
  {
    code: "gn18",
    beneficiaries: "13,760", // updated
    cbos: 37, // updated
    services: "Protection, Prevention",
    servicesAr: "حماية، وقاية",
  },
  {
    code: "gn19",
    beneficiaries: "22,255", // updated
    cbos: 57,
    services: "Protection, Prevention, Empowerment",
    servicesAr: "حماية، وقاية، تمكين",
  },
  {
    code: "gn20",
    beneficiaries: "27,965",
    cbos: 68,
    services: "Protection, Prevention",
    servicesAr: "حماية، وقاية",
  },
  {
    code: "gn21",
    beneficiaries: "15,430", // updated
    cbos: 37,
    services: "Protection, Prevention",
    servicesAr: "حماية، وقاية",
  },
  {
    code: "gn22",
    beneficiaries: "17,280",
    cbos: 47,
    services: "Protection, Prevention",
    servicesAr: "حماية، وقاية",
  },
  {
    code: "gn23",
    beneficiaries: "28,485", // updated
    cbos: 65, // updated
    services: "Protection, Prevention",
    servicesAr: "حماية، وقاية",
  },
  {
    code: "gn24",
    beneficiaries: "10,845", // updated
    cbos: 49,
    services: "Protection, Prevention",
    servicesAr: "حماية، وقاية",
  },
  {
    code: "gn25",
    beneficiaries: "21,715", // updated
    cbos: 50, // updated
    services: "Protection, Prevention",
    servicesAr: "حماية، وقاية",
  },
  {
    code: "gn26",
    beneficiaries: "12,500", // updated
    cbos: 30, // updated
    services: "Protection, Prevention",
    servicesAr: "حماية، وقاية",
  },
  {
    code: "gn27",
    beneficiaries: "2,955", // updated
    cbos: 9, // updated
    services: "Protection, Prevention, Empowerment",
    servicesAr: "حماية، وقاية، تمكين",
  },
];

export default allGovDetails;