import { useInfinitePrograms } from "@/api/program";
import ProgramCard from "@/components/Program/ProgramCard";
import { ProgramCardSkeleton } from "@/components/Program/ProgramCardSkeleton";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Loader2 } from "lucide-react";
import { DropdownMenuCheckboxes } from "@/components/ui/checkboxes";
import { useGetCategoriesOptions } from "@/api/category";
import { useGetDonationTypesOptions } from "@/api/donation_type";
import { v4 as uuidv4 } from "uuid";

enum ProgramSortOptions {
  RANK = "rank",
  LAUNCHED = "-created_at",
  RAISED = "-raised_percentage",
}

export default function ProgramListing() {
  const { t, i18n } = useTranslation();
  const queryParams = new URLSearchParams(window.location.search);
  useEffect(() => {
    if (window.location.search) {
      window.history.replaceState({}, document.title, "/programs");
    }
  }, []);
  const initialCategory = queryParams.get("category") ?? "all";
  const initialDonationType = queryParams.get("donationtype") ?? "all";

  const allCategoriesOption = {
    value: "all",
    label: t("ProgramListing.allTypes"),
  };
  const allDonationsOption = {
    value: "all",
    label: t("ProgramListing.allDonations"),
  };

  const sortOptions = [
    {
      value: ProgramSortOptions.LAUNCHED,
      label: i18n.t("ourPrograms.launched"),
    },
    { value: ProgramSortOptions.RAISED, label: i18n.t("ourPrograms.close") },
  ];

  const [selectedCategory, setSelectedCategory] =
    useState<string>(initialCategory);
  const [selectedSortOption, setSelectedSortOption] = useState<string>(
    ProgramSortOptions.RANK,
  );
  const [selectedDonationType, setSelectedDonationType] =
    useState<string>(initialDonationType);

  const categoryOptions = [
    allCategoriesOption,
    ...useGetCategoriesOptions({ lang: i18n.language }),
  ];
  const donationTypeOptions = [
    allDonationsOption,
    ...useGetDonationTypesOptions({ lang: i18n.language }),
  ];

  const handleSortChange = (value: string) => {
    setSelectedSortOption((prev) =>
      prev === value ? ProgramSortOptions.RANK : value,
    );
  };

  const handleCategoryChange = (value: string) => {
    setSelectedCategory((prev) => (prev === value ? "all" : value));
  };

  const handleDonationTypeChange = (value: string) => {
    setSelectedDonationType((prev) => (prev === value ? "all" : value));
  };

  const {
    data: programs,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfinitePrograms({
    page_size: "8",
    lang: i18n.language,
    category: selectedCategory === "all" ? "" : selectedCategory,
    ordering: selectedSortOption,
    donationtype: selectedDonationType === "all" ? "" : selectedDonationType,
  });

  const buttonsCss =
    "font-normal px-8 py-6 min-w-[215px] justify-center min-w-[240px]";
  const dropdownContentCss = "min-w-[240px] text-left";

  return (
    <>
      {programs?.pages?.[0].results?.length ||
      selectedCategory ||
      selectedDonationType ||
      selectedSortOption ? (
        <div className="flex gap-4 self-stretch overflow-x-auto pr-5 text-16px">
          <DropdownMenuCheckboxes
            options={sortOptions}
            selectedOptions={[selectedSortOption]}
            handleChange={handleSortChange}
            buttonTitle={
              selectedSortOption === ProgramSortOptions.RANK
                ? t("ourPrograms.sort")
                : sortOptions.find(
                    (option) => option.value === selectedSortOption,
                  )?.label
            }
            className={buttonsCss}
            classNameDrop={dropdownContentCss}
            keepButtonVisible={false}
          />
          <DropdownMenuCheckboxes
            options={categoryOptions}
            selectedOptions={[selectedCategory]}
            handleChange={handleCategoryChange}
            buttonTitle={
              selectedCategory === "all"
                ? t("ourPrograms.filter")
                : categoryOptions.find(
                    (option) => option.value === selectedCategory,
                  )?.label
            }
            className={buttonsCss}
            classNameDrop={dropdownContentCss}
            keepButtonVisible={false}
          />
          <DropdownMenuCheckboxes
            options={donationTypeOptions}
            selectedOptions={[selectedDonationType]}
            handleChange={handleDonationTypeChange}
            buttonTitle={
              selectedDonationType === "all"
                ? t("ourPrograms.donationType")
                : donationTypeOptions.find(
                    (option) => option.value === selectedDonationType,
                  )?.label
            }
            className={buttonsCss}
            classNameDrop={dropdownContentCss}
            keepButtonVisible={false}
          />
        </div>
      ) : null}
      <section className="my-6 flex min-h-[50vh] flex-col gap-8">
        <div className="grid grid-cols-1 gap-6 md:grid-cols-4">
          {isLoading &&
            Array.from({ length: 8 }).map(() => (
              <div key={uuidv4()}>
                <ProgramCardSkeleton />
              </div>
            ))}
          {!isLoading &&
            programs?.pages.map((page) =>
              page.results.map((program) => (
                <ProgramCard key={program.id} program={program} />
              )),
            )}
          {!isLoading && !programs?.pages?.[0].results?.length && (
            <div className="col-span-4 mb-8 h-full w-full space-y-4 font-normal md:mb-0">
              <p className="text-xl font-bold leading-[120%] sm:text-[2rem]">
                {t("ProgramListing.noPrograms")}
              </p>
              <p className="text-xl font-normal leading-[120%] sm:text-[2rem]">
                {t("ProgramListing.noProgramsText")}
              </p>
              <div className="flex items-center gap-2">
                <Input
                  type="email"
                  placeholder={t("donationFlow.emailenter")}
                  className="w-[40rem] rounded-md border border-gray-300 px-4 py-2"
                />
                <Button className="h-full w-[11rem] rounded-md px-4 py-2 font-semibold">
                  {t("ourPrograms.notifyMe")}
                </Button>
              </div>
            </div>
          )}
        </div>
        {hasNextPage && (
          <div className="flex w-full justify-center pb-4 md:pb-0">
            {isFetchingNextPage ? (
              <Loader2 className="animate-spin" />
            ) : (
              <button
                className="text-black underline"
                onClick={() => fetchNextPage()}
              >
                {t("myDonations.loadMore")}
              </button>
            )}
          </div>
        )}
      </section>
    </>
  );
}
