import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardFooter } from "@/components/ui/card";
import { ShareIcon } from "@/assets/icons/ShareIcon";
import { Progress } from "../ui/progress";
import { useTranslation } from "react-i18next";
import { cn } from "@/lib/utils";
import { Link, useRouter } from "@tanstack/react-router";

import { ProgramType } from "@/types/programs";
import { ArrowLeftToRightIcon } from "@/assets/icons/ArrowLeftToRightIcon";
import Share from "../Share/Share";
import ReactGA from "react-ga4";
import {
  formatCurrency,
  getProgramGoal,
  getProgramRaisedAmount,
  getProgramRaisedPercentage,
} from "@/utils/currency";
import { shareDataFormatter } from "@/utils/share";
import { useFormStore } from "@/contexts/paymentFlowContext";

type ProgramCardProps = {
  readonly className?: string;
  readonly program?: ProgramType;
};

export default function ProgramCard({ className, program }: ProgramCardProps) {
  const { t } = useTranslation();
  const [imageLoaded, setImageLoaded] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);
  const { reset } = useFormStore();

  useEffect(() => {
    if (program?.main_image?.large_url) {
      const img = new Image();
      img.src = program.main_image.large_url;
      img.onload = () => {
        setFadeOut(true);
        setImageLoaded(true);
      };
    }
  }, [program]);

  const { navigate } = useRouter();

  if (!program) {
    return null;
  }

  const shareData = shareDataFormatter(program);

  const handleSuccess = () => {
    ReactGA.event({
      category: "Social Media Share",
      action: "social_media_share",
      label: "Mobile",
    });
  };

  const handleDonateNow = () => {
    reset();
    if (program) {
      navigate({
        to: `/paymentFlow/${program.id}`,
        search: { backUrl: location.pathname },
      });
    }
  };

  return (
    <Card
      className={cn(
        className,
        "group cursor-pointer overflow-hidden transition-all duration-300 hover:scale-[1.02] hover:border-2 border-background-second flex flex-col justify-between",
        "bg-transparent",
      )}
    >
      <Link to={`/program/${program.id}`} aria-label="Go to program">
        <div className="relative overflow-hidden">
          {!program.published && (
            <div className="absolute inset-0 z-10 flex items-center justify-center bg-black bg-opacity-70">
              <span className="text-xl font-bold text-white">
                {t("ProgramCard.preview_label")}
              </span>
            </div>
          )}
          <img
            src={program.main_image?.large_url}
            srcSet={`
              ${program.main_image?.small_url} 640w,
              ${program.main_image?.medium_url} 1024w,
              ${program.main_image?.large_url} 1200w
            `}
            sizes="(min-width: 1024px) 1200px, (min-width: 640px) 1024px, 640px"
            alt={`Program ${program.title}`}
            className={`aspect-[3/2] w-full rounded-t-lg object-cover transition-all duration-1000 group-hover:scale-105 ${
              imageLoaded ? "opacity-100" : "opacity-0"
            }`}
            loading="lazy"
          />
          {!imageLoaded && (
            <div
              className={`absolute inset-0 blur-md transition-opacity duration-1000 ${
                fadeOut ? "opacity-0" : "opacity-100"
              }`}
              style={{
                backgroundImage: `url(${program.main_image?.thumbnail_url})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
          )}
          <Button
            className="absolute bottom-2 right-2 h-[48px] w-[48px] bg-foreground hover:bg-foreground rtl:left-2 rtl:right-auto"
            aria-label="Go to program page"
          >
            <ArrowLeftToRightIcon className="fill-[#fafafa] rtl:rotate-180" />
          </Button>
        </div>
        <CardContent>
          <div className="flex flex-col gap-4 pt-6">
            <h3 className="scroll-m-20 text-base  font-bold tracking-tight">
              {program.title}
            </h3>
            {!program.is_special && (
              <>
                <Progress
                  value={getProgramRaisedPercentage(program)}
                  className="dark h-[4px] rounded-[2px] rtl:scale-x-[-1] rtl:transform"
                  aria-label="Donation amount progress bar"
                />
                <div className="flex justify-between">
                  <span className="text-base font-bold">
                    {formatCurrency(getProgramRaisedAmount(program))}
                  </span>
                  <span className="text-base font-bold">
                    {formatCurrency(getProgramGoal(program))}
                  </span>
                </div>
              </>
            )}
          </div>
          {!program.is_special && (
            <div className="flex justify-between">
              <span className="text-sm font-normal text-black">
                {t("ProgramCard.raised_label")} -{" "}
                {getProgramRaisedPercentage(program).toFixed(1)}%
              </span>
              <span className="text-sm font-normal text-black">
                {t("ProgramCard.goal_label")}
              </span>
            </div>
          )}
        </CardContent>
      </Link>
      <CardFooter className="flex flex-row gap-2">
        <Button
          variant="custom"
          className={
            "h-full w-full flex-grow rounded-md py-4 !text-base font-bold sm:text-sm md:px-1"
          }
          onClick={handleDonateNow}
        >
          {t("ProgramCard.donate_now")}
        </Button>
        <Share
          shareData={shareData}
          programId={program.id}
          onSuccess={handleSuccess}
        >
          <ShareIcon className="fill-[#22222A]" />
        </Share>
      </CardFooter>
    </Card>
  );
}
