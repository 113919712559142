import useCurrencyStore from "@/store/currencyStore";
import { StepHeader } from "./stepHeader";
import { StepFooter } from "./stepFooter";
import AmountInput from "@/components/Inputs/AmountInput";
import { ChangeEvent, useCallback } from "react";
import { DatePickerWithRange } from "@/components/Inputs/RangeDatePicler";
import useChallengeFormStore from "@/store/challengeStore";
import { Trans, useTranslation } from "react-i18next";
import { t } from "i18next";
import {
  formatNumber,
  getCurrencySymbol,
  getSuggestedAmount,
} from "@/utils/currency";
import { SuggestedAmountType } from "@/types/programs";

type AmountProps = {
  id: string;
  amount: number;
  currency: string;
};

const AmountButton = ({ id, amount, currency }: AmountProps) => {
  const { selectedAmount, setSelectedAmount, setInputAmount } =
    useChallengeFormStore();

  const handleAmountClick = useCallback(
    (amount: number) => {
      setSelectedAmount(selectedAmount === amount ? 0 : amount);
      setInputAmount(selectedAmount === amount ? 0 : amount);
    },
    [selectedAmount, setSelectedAmount, setInputAmount],
  );

  return (
    <button
      key={`${id}${amount}`}
      onClick={() => handleAmountClick(amount)}
      className={`flex-grow cursor-pointer border border-[#AC9CA0] p-2 text-center font-[650] ${
        selectedAmount === amount ? " bg-[#E2DDD7]" : "bg-transparent"
      } h-14 rounded-lg`}
    >
      {`${currency}${formatNumber(amount)}`}
    </button>
  );
};

type ThanksMessageProps = {
  amount: number | null;
  currency: string;
  message: string;
  meal_price_egp: number;
  meal_price_usd: number;
};

export const ThanksMessage = ({
  amount,
  currency,
  message,
  meal_price_egp,
  meal_price_usd,
}: Readonly<ThanksMessageProps>) => {
  if (!amount) {
    return null;
  }
  const currencySymbol = currency === "EGP" ? "EGP" : "$";
  const mealPrice = currency === "EGP" ? meal_price_egp : meal_price_usd;
  const mealCount = +(amount / mealPrice).toFixed(2);
  const roundedMealCount = Math.ceil(mealCount);

  return (
    <div className="flex items-start gap-5 rounded-lg bg-[#E4E1D8] p-6 shadow-sm">
      <img src="/icons/thanks.svg" alt="Thanks" className="h-6 w-6" />
      <div className="flex w-full flex-col items-start justify-start gap-4">
        <div className="flex items-center">
          <p className="text-base leading-[150%] text-foreground">
            <Trans
              i18nKey="challenge.thankYou"
              values={{
                amount,
                roundedMealCount,
                message,
                currencySymbol,
                mealCount: mealCount % 1 !== 0 ? t("challenge.nearly") : "",
              }}
              components={{
                bold: <span className="font-[650]" />,
              }}
            />
          </p>
        </div>
      </div>
    </div>
  );
};

export const GoalStep = () => {
  const { t } = useTranslation();
  const {
    nextStep,
    programMealPriceEGP,
    programMealPriceUSD,
    programMessage,
    isFullDateRangeSet,
    InputAmount,
    setSelectedAmount,
    setInputAmount,
  } = useChallengeFormStore();
  const { currency } = useCurrencyStore();

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      const numberValue = parseInt(value);
      setSelectedAmount(0);
      setInputAmount(isNaN(numberValue) ? 0 : numberValue);
    },
    [setSelectedAmount, setInputAmount],
  );

  const suggestedAmounts = [
    {
      id: "99",
      amount_egp: 500000,
      amount_usd: 10121,
    },
    {
      id: "98",
      amount_egp: 1000000,
      amount_usd: 20242,
    },
    {
      id: "97",
      amount_egp: 2500000,
      amount_usd: 50606,
    },
    {
      id: "96",
      amount_egp: 5000000,
      amount_usd: 101212,
    },
  ];

  return (
    <>
      <StepHeader />
      <div className="scrollbar-hide mt-2 flex h-screen w-full flex-col gap-16 overflow-y-auto bg-background pt-4 md:mb-[90px] md:pt-16 lg:max-w-[615px]">
        <div className="mb-8 flex h-full w-full flex-col gap-4 bg-background md:mt-0 md:gap-8 md:px-6">
          <h3 className="w-full py-2 text-xl font-[650] leading-[120%] sm:text-[32px] md:py-0">
            {t("challenge.selectGoal")}
          </h3>
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-4">
              <div className="grid w-full grid-cols-3 gap-2 sm:grid-cols-4">
                {suggestedAmounts?.map((amount) => (
                  <AmountButton
                    key={amount.id}
                    id={amount.id}
                    amount={getSuggestedAmount(amount as SuggestedAmountType)}
                    currency={getCurrencySymbol()}
                  />
                ))}
              </div>
              <div className="flex w-full flex-col gap-2">
                <label
                  htmlFor="price"
                  className="text-sm font-[650] text-foreground"
                >
                  {t("challenge.writeAmount")}
                </label>
                <AmountInput
                  value={InputAmount !== null ? InputAmount.toString() : ""}
                  onChange={handleInputChange}
                  placeholder={t("challenge.enterCustom")}
                />
              </div>
            </div>
            <ThanksMessage
              amount={InputAmount}
              currency={currency}
              message={programMessage}
              meal_price_usd={programMealPriceUSD}
              meal_price_egp={programMealPriceEGP}
            />
            <div className="mb-12 flex w-full flex-col gap-2 md:mb-16 ">
              <h4 className="text-sm font-[650]">
                {t("challenge.goalTimeRange")}
              </h4>
              <DatePickerWithRange className="w-full" />
            </div>
          </div>
        </div>
      </div>
      <StepFooter
        nextStep={nextStep}
        isDisabled={!isFullDateRangeSet || InputAmount === 0}
      ></StepFooter>
    </>
  );
};
