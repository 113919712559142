import { useState, useMemo, useRef, useEffect } from "react";
import { useFormStore } from "@/contexts/paymentFlowContext";
import StepHeader from "./StepHeader";
import { z } from "zod";
import useCurrencyStore from "@/store/currencyStore";
import {
  LivestockType,
  ProgramType,
  SpecialProgramType,
  SuggestedAmountType,
  ItemType,
} from "@/types/programs";
import StepFooter from "./stepFooter";
import { useGetAuthenticatedUser } from "@/api/user";
import { getLoginURL } from "@/utils/auth";
import { Trans, useTranslation } from "react-i18next";
import SpecialProgramForm from "./SpecialProgramForm";
import ItemizationForm from "./ItemizationForm";
import RegularProgramForm from "./RegularProgramForm";
import { getMinAmount } from "@/utils/currency";

type FirstStepProps = {
  readonly program: ProgramType;
  readonly suggestedAmounts?: SuggestedAmountType[];
  readonly livestockList?: LivestockType[];
  readonly items?: ItemType[];
};

const FirstStep = ({
  program,
  suggestedAmounts,
  livestockList,
  items,
}: FirstStepProps) => {
  const { currency } = useCurrencyStore();
  const [errors, setErrors] = useState<Record<string, string>>({});
  const { data: user } = useGetAuthenticatedUser();
  const { t, i18n } = useTranslation();
  const minAmount = getMinAmount(program);
  const formRef = useRef<HTMLFormElement>(null);

  const {
    activeTab,
    selectedAmount,
    inputAmount,
    isZakat,
    nextStep,
    finalMonth,
    setFinalMonth,
    donationType,
    quantity,
    setIsDelivery,
  } = useFormStore();

  const amountFormSchema = useMemo(
    () =>
      z.object({
        selectedAmount: z.number(),
        inputAmount: z
          .number({ message: t("yup.amountInvalid") })
          .min(minAmount, {
            message: t("yup.minAmount", { amount: minAmount, currency }),
          })
          .refine((val) => val === null || val >= 1, {
            message: t("yup.amountInvalid"),
          }),
        finalMonth: z
          .date({ message: t("yup.dateMustBeSet") })
          .optional()
          .refine(
            (date) => {
              if (activeTab !== "monthly") {
                return true;
              }
              if (!date) {
                return true;
              }
              const today = new Date();
              today.setHours(0, 0, 0, 0);
              return date > today;
            },
            { message: t("yup.dateMustBeFuture") },
          ),
      }),
    [i18n.language],
  );

  const zakatFormSchema = useMemo(
    () =>
      z.object({
        quantity: z
          .number()
          .min(1, {
            message: t("yup.familyMembersNumberRequired"),
          })
          .refine((val) => val === null || val >= 1, {
            message: t("yup.familyMembersNumberRequired"),
          }),
        inputAmount: z
          .number({ message: t("yup.amountInvalid") })
          .min(1, { message: t("yup.amountInvalid") })
          .refine((val) => val === null || val >= 1, {
            message: t("yup.amountInvalid"),
          }),
      }),
    [i18n.language],
  );

  const handleSpcialProgramNextStep = () => {
    if (
      program.special_type !== SpecialProgramType.ZAKAT_FITR &&
      formRef.current
    ) {
      formRef.current.submitForm();
    } else {
      setIsDelivery(false);
      parseErrors() && nextStep();
    }
  };

  const handleItemizationNextStep = () => {
    if (formRef.current) {
      formRef.current.submitForm();
    } else {
      setIsDelivery(false);
      parseErrors() && nextStep();
    }
  };

  const handleRegularProgramNextStep = () => {
    if (!parseErrors()) {
      return;
    }

    if (activeTab === "monthly" && !user) {
      window.location.href = getLoginURL("", window.location.href, true);
      return;
    }

    if (activeTab === "monthly" && !finalMonth) {
      return;
    }

    nextStep();
  };

  const parseErrors = () => {
    try {
      if (finalMonth && typeof finalMonth === "string") {
        setFinalMonth(new Date(finalMonth));
      }
      if (program.is_special) {
        const zakatFormData = {
          quantity,
          inputAmount,
        };

        zakatFormSchema.parse(zakatFormData);
      } else {
        const formData = {
          selectedAmount,
          inputAmount,
          isZakat,
          finalMonth: activeTab === "monthly" ? finalMonth : undefined,
        };
        amountFormSchema.parse(formData);
      }

      setErrors({});

      if (activeTab === "once") {
        setFinalMonth(null);
      } else if (activeTab === "monthly") {
        if (user && !finalMonth) {
          setErrors({ ...errors, finalMonth: t("yup.finalMonthRequired") });
        }
      }

      return true;
    } catch (e) {
      if (e instanceof z.ZodError) {
        const fieldErrors = e.errors.reduce(
          (acc, error) => {
            if (error.path.length > 0) {
              acc[error.path[0]] = error.message;
            }
            return acc;
          },
          {} as Record<string, string>,
        );
        setErrors(fieldErrors);
      }
      return false;
    }
  };

  useEffect(() => {
    parseErrors();
  }, [inputAmount, finalMonth, quantity, selectedAmount]);

  const handleNextStep = () => {
    if (program.is_special) {
      handleSpcialProgramNextStep();
    } else if (program.is_itemization) {
      handleItemizationNextStep();
    } else {
      handleRegularProgramNextStep();
    }
  };

  const getTitle = useMemo(() => {
    if (program.special_type === SpecialProgramType.ZAKAT_FITR) {
      return t("donationFlow.zakatTitle");
    } else if (program.is_special) {
      return t("donationFlow.specialTitle");
    }
    return t("donationFlow.title");
  }, [program, i18n.language]);

  return (
    <>
      <StepHeader />
      <div className="scrollbar-hide flex h-screen w-full flex-col gap-16 overflow-y-auto bg-background lg:max-w-[43rem]">
        <div className="h-full w-full bg-background ">
          <div className="mb-4 flex w-full flex-col items-start justify-start gap-4">
            <h3 className="pt-4 text-xl font-bold leading-[120%] sm:text-[32px] md:pt-16">
              {getTitle}
            </h3>
            {program.special_type === SpecialProgramType.ZAKAT_FITR &&
            suggestedAmounts &&
            suggestedAmounts?.length !== 0 ? (
              <Trans
                i18nKey={"donationFlow.zakatEidText"}
                values={{
                  amount:
                    currency === "EGP"
                      ? `EGP ${suggestedAmounts[0].amount_egp}`
                      : `$ ${suggestedAmounts[0].amount_usd}`,
                  year: new Date().getFullYear(),
                }}
              />
            ) : (
              <span className="text-sm md:text-base">
                {program.is_special
                  ? t("donationFlow.specialDescription")
                  : t("donationFlow.description")}
              </span>
            )}
          </div>
          {program.is_special ? (
            <SpecialProgramForm
              specialType={program.special_type}
              livestockList={livestockList ?? []}
              zakatErrors={errors}
              zakatAmount={
                program.special_type === SpecialProgramType.ZAKAT_FITR &&
                suggestedAmounts
                  ? suggestedAmounts[0]
                  : undefined
              }
              ref={formRef}
            />
          ) : program.is_itemization ? (
            <ItemizationForm itemsList={items ?? []} ref={formRef} />
          ) : (
            <RegularProgramForm
              program={program}
              suggestedAmounts={suggestedAmounts}
              errors={errors}
            />
          )}
        </div>
      </div>
      <StepFooter
        nextStep={handleNextStep}
        donationtype={(() => {
          if (donationType) {
            return program.donation_types.find(
              (type) => type.id === donationType,
            )?.title;
          }
          return "";
        })()}
      />
    </>
  );
};

export default FirstStep;
