import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
  DialogClose,
} from "@/components/ui/dialog";
import { ChallengeType } from "@/types/challenge";
import { isRTLLanguage } from "@/utils/helpers";
import { useTranslation } from "react-i18next";

type CloseChallengeModalProps = {
  challenge: ChallengeType;
  mutate: any;
  open: boolean;
  setOpen: (open: boolean) => void;
};
export default function CloseChallengeModal({
  challenge,
  mutate,
  open,
  setOpen,
}: Readonly<CloseChallengeModalProps>) {
  const { t, i18n } = useTranslation();
  const handleCloseChallenge = () => {
    const currentDate = new Date();
    const startDate = new Date(challenge.start_date as string);
    const updateData: { state: string; end_date: string; start_date: string } =
      {
        state: "Closed",
        end_date: currentDate.toISOString().split("T")[0],
        start_date: startDate.toISOString().split("T")[0],
      };

    if (startDate > currentDate) {
      // If the challenge hasn't started yet, update both start and end dates
      updateData.start_date = currentDate.toISOString().split("T")[0];
    }

    mutate({
      challengeId: challenge.id,
      data: updateData,
    });
  };
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger className="w-full rounded-lg border border-2 border-foreground bg-transparent py-4">
        {t("challenge.closeChallenge")}
      </DialogTrigger>
      <DialogContent
        dir={isRTLLanguage(i18n.language) ? "rtl" : "ltr"}
        className="mt-48 flex h-screen w-full flex-col items-start justify-between justify-items-end overflow-y-auto rounded-t-2xl p-6 pb-52 md:mt-0 md:h-[400px] md:w-[788px] md:rounded-2xl md:px-16 md:py-8 rtl:items-end"
      >
        <div className="md:align-center flex h-full w-full flex-1 flex-col gap-4 text-start md:gap-8">
          <h3 className="text-[20px] font-bold md:text-3xl md:text-[32px]">
            {t("challenge.closeChallenge")}
          </h3>
          <div className="flex flex-col gap-6">
            <p>{t("challenge.close1")}</p>
            <p>{t("challenge.close2")}</p>
            <p>{t("challenge.close3")}</p>
          </div>
        </div>
        <div className="flex w-full flex-col justify-center gap-6 self-center md:flex-row-reverse">
          <Button
            size="lg"
            className="min-w-56 font-[650]"
            type="button"
            onClick={handleCloseChallenge}
          >
            {t("challenge.confirm")}
          </Button>
          <DialogClose>
            <Button size="lg" variant="ghost" className="min-w-56 font-[650]">
              {t("challenge.dismiss")}
            </Button>
          </DialogClose>
        </div>
      </DialogContent>
    </Dialog>
  );
}
