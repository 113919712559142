import { Skeleton } from "@/components/ui/skeleton";

export const ProgramImageSkeleton = () => {
  return Array.from({ length: 3 }).map((_, i) => {
    const key = `category-skeleton-${i}`;
    return (
      <div key={key} className="flex flex-col gap-5 self-center">
        <Skeleton className="h-20 w-20 rounded-full bg-[#c4c0bd] md:h-28 md:w-28" />
        <Skeleton className="h-6 w-full bg-[#c4c0bd]" />
      </div>
    );
  });
};
