import { Card, CardHeader, CardContent } from "@/components/ui/card";

const ContactCard = ({
  IconComponent,
  headerText,
  contactInfo,
  linkType,
  bgColor = "#E2DDD7",
}: {
  IconComponent: React.ElementType;
  headerText: string;
  contactInfo: string;
  linkType: string;
  bgColor?: string;
}) => {
  return (
    <Card
      className={`flex-1 rounded-lg border-none shadow-sm`}
      style={{ backgroundColor: bgColor }}
    >
      <CardHeader className="flex justify-start">
        <IconComponent />
        <h3
          className="pt-8 text-18px font-bold md:pt-16 md:text-2xl"
          style={{ marginTop: 0 }}
        >
          {headerText}
        </h3>
      </CardHeader>
      <CardContent className="overflow-hidden text-ellipsis ">
        <a
          href={`${linkType}:${contactInfo}`}
          className="font-400 text-20px underline md:text-32px"
        >
          {contactInfo}
        </a>
      </CardContent>
    </Card>
  );
};

export default ContactCard;
