import useChallengeFormStore from "@/store/challengeStore";
import { Currencies } from "@/store/currencyStore";
import { shareDataFormatter } from "../Challenge";
import { useRouter } from "@tanstack/react-router";
import clsx from "clsx";
import { Progress } from "@/components/ui/progress";
import ReactGA from "react-ga4";
import { ChallengeType } from "@/types/challenge";
import PreviewButton from "./PreviewButton";
import Share from "@/components/Share/Share";
import { ShareIcon } from "@/assets/icons/ShareIcon";
import { Button } from "@/components/ui/button";
import { calculateDeadline } from "@/utils/challenge";
import { useFormStore } from "@/contexts/paymentFlowContext";
import { useChallengeDonationData } from "@/pages/program/useDonationData";
import { formatNumber } from "@/utils/currency";

type ChallengeDonationBoxProps = {
  bgColor: string;
  className?: string;
  challenge: ChallengeType;
};

export const ChallengeDonationBox = ({
  bgColor,
  className,
  challenge,
}: ChallengeDonationBoxProps) => {
  const { t, currency, raisedAmount, raisedPercentage } =
    useChallengeDonationData(challenge);

  const { preview } = useChallengeFormStore();
  const { reset } = useFormStore();

  const shareData = shareDataFormatter(challenge);

  const handleSuccess = () => {
    ReactGA.event({
      category: "Social Media Share",
      action: "social_media_share",
      label: "Mobile",
    });
  };

  const { navigate } = useRouter();

  const handleDonateNow = () => {
    reset();
    if (challenge) {
      navigate({
        to: `/challenge-payment/${challenge.id}`,
        search: { backUrl: location.pathname },
      });
    }
  };

  return (
    <div
      className={clsx(
        "sticky top-0 flex hidden w-full justify-between py-6 md:inline-flex ",
        className,
      )}
      style={{
        backgroundColor: bgColor,
        gap: "72px",
      }}
    >
      <div className="flex-8 dark flex w-full flex-col">
        <div className="x-full flex flex-col gap-4">
          <Progress
            key={currency}
            value={raisedPercentage}
            className="h-[6px] rounded-[2px] bg-[#D6D9DC] rtl:scale-x-[-1] rtl:transform"
            aria-label="Donation amount progress bar"
          />
          <div className="flex justify-between">
            <div className="flex items-center space-x-3">
              <p className="text-sm font-[350] text-secondary">
                {t("ProgramCard.raised_label")} {""}
              </p>
              <p className="text-center font-[650] text-secondary">
                {currency === Currencies.EGP ? "EGP " : "$ "}
                {formatNumber(raisedAmount)} ({raisedPercentage}%)
              </p>
            </div>
            <div className="flex items-center space-x-3">
              <p className="font-light text-secondary">
                {t("challenge.deadline")}{" "}
                {calculateDeadline(
                  challenge.start_date as string,
                  challenge.end_date as string,
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
      {challenge.state !== "Closed" && (
        <div className="flex-2 flex items-center gap-x-4">
          {challenge.state === "Ongoing" && (
            <Button
              className="h-[54px] w-full px-5 py-4 font-bold md:min-w-[170px]"
              onClick={handleDonateNow}
            >
              {t("ProgramCard.donate_now")}
            </Button>
          )}
          <Share
            shareData={shareData}
            programId={challenge.id}
            onSuccess={handleSuccess}
          >
            <ShareIcon className="fill-[#22222A]" />
          </Share>
          {challenge.is_owner && !preview && <PreviewButton />}
        </div>
      )}
    </div>
  );
};
