import { bgOverlayStyle } from "@/utils/helpers";
import { AppShell } from "@/components/Navbar";
import { ReactNode } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Link } from "@tanstack/react-router";
import { isRTLLanguage } from "@/utils/helpers";

interface HeaderProps {
  title?: string | ReactNode;
  picture?: string;
  light?: boolean;
}

export const Header: React.FC<HeaderProps> = ({
  title,
  picture,
  light = false,
}) => {
  const backgroundStyle = bgOverlayStyle(picture ?? "");
  const { i18n, t } = useTranslation();

  return (
    <>
      <Helmet>
        <meta name="description" content={`${title} page`} />
      </Helmet>
      <div className="flex h-14 items-center justify-between bg-foreground px-4 md:px-[24px]">
        <div className="flex flex-row gap-1 text-white">
          {t("join.first_part")}{" "}
          <Link
            aria-label={t("join.first_part") + " " + t("join.second_part")}
            to="/community-challenge"
            search={{ tab: "" }}
          >
            <p className="font-bold text-white underline">
              {" "}
              {t("join.second_part")}
            </p>
          </Link>
        </div>
        <Link
          aria-label={t("join.first_part") + " " + t("join.second_part")}
          to="/community-challenge"
          search={{ tab: "" }}
        >
          {isRTLLanguage(i18n.language) ? (
            <img src="/coh-logo-ar-white.svg" className="h-8" />
          ) : (
            <img src="/coh-logo-white.svg" className="h-8" />
          )}
        </Link>
      </div>

      <AppShell
        theme="dark"
        navbarClass="top-14 absolute w-full"
        light={light}
      />
      <div
        className="dark relative h-[65vh] w-full bg-cover bg-center md:h-[calc(70vh-5rem)]"
        style={{
          backgroundImage: backgroundStyle,
        }}
      >
        <div className="flex h-full flex-shrink-0 flex-col items-start justify-end self-stretch p-4 md:p-6">
          <div className="w-5/7">
            <h1 className="text-40px font-bold leading-[40px] text-white md:text-96px md:leading-[96px] rtl:pb-4">
              {title}
            </h1>
          </div>
        </div>
      </div>
    </>
  );
};
