import { useTranslation } from "react-i18next";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Button } from "../ui/button";
import { isRTLLanguage } from "@/utils/helpers";
import { cn } from "@/lib/utils";
import { ChevronDown, ChevronUp } from "lucide-react";
import { Form, Formik, FormikErrors } from "formik";
import AmountInput from "@/components/Inputs/AmountInput";
import * as Yup from "yup";
import { useFormStore } from "@/contexts/paymentFlowContext";
import DropDown from "../ui/dropdown";
import { useGetDonationTypes } from "@/api/donation_type";
import { useInfinitePrograms } from "@/api/program";
import { useRouter } from "@tanstack/react-router";
import clsx from "clsx";
import { getMinAmount } from "@/utils/currency";
import useCurrencyStore from "@/store/currencyStore";

type QuickDonationFormProps = {
  readonly setIsOpen: (isOpen: boolean) => void;
};

const QuickDonationForm = ({ setIsOpen }: QuickDonationFormProps) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { navigate } = useRouter();
  const { currency } = useCurrencyStore();

  const [isNextStep, setIsNextStep] = useState(false);
  // Track manual field interactions with local state
  const [manuallyChanged, setManuallyChanged] = useState<
    Record<string, boolean>
  >({});

  const {
    setInputAmount,
    donationType,
    setDonationType,
    setSelectedProgram,
    inputAmount,
    selectedProgram,
    nextStep,
    reset,
  } = useFormStore();

  const { data: donationTypes } = useGetDonationTypes({
    lang: i18n.language,
    type: "Constant",
  });
  const {
    data: programs,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfinitePrograms({
    page_size: "8",
    lang: i18n.language,
    donationtypeId: donationType ?? "",
    isSpecial: "False",
    isItemization: "False",
  });

  const flatPrograms = useMemo(
    () => programs?.pages.map((page) => page.results).flat(),
    [programs],
  );

  const initialValues = {
    selectedProgram: selectedProgram?.id,
    donationType: donationType,
    inputAmount: inputAmount,
  };

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      donationType: Yup.string().required(t("yup.donationTypeRequired")),
      selectedProgram: Yup.string().required(t("yup.causeRequired")),
      inputAmount: Yup.number()
        .when("selectedProgram", (selectedProgram, schema) => {
          const selectedProgramId = Array.isArray(selectedProgram)
            ? selectedProgram[0]
            : selectedProgram;
          if (selectedProgramId) {
            const programObj = flatPrograms?.find(
              (p) => p.id === selectedProgramId,
            );
            const minAmount = programObj ? getMinAmount(programObj) : 1;

            return schema.min(
              minAmount,
              t("yup.minAmount", { amount: minAmount, currency }),
            );
          }
          return schema.min(1, t("yup.minAmount", { amount: 1, currency }));
        })
        .required(t("yup.amountInvalid")),
    });
  }, [flatPrograms, t, currency]);

  const handleInputChange = useCallback(
    ({
      event,
      setFieldValue,
    }: {
      event: ChangeEvent<HTMLInputElement>;
      setFieldValue: (
        field: string,
        value: any,
      ) => Promise<void | FormikErrors<any>>;
    }) => {
      setFieldValue("inputAmount", 0);
      const value = event.target.value;
      if (value === "") {
        setFieldValue("inputAmount", null);
      } else {
        const numberValue = parseInt(value);
        if (!isNaN(numberValue)) {
          setFieldValue("inputAmount", numberValue);
        } else {
          setFieldValue("inputAmount", null);
        }
      }
    },
    [setInputAmount],
  );

  useEffect(() => {
    if (isNextStep && selectedProgram) {
      navigate({
        to: `/paymentFlow/${selectedProgram.id}`,
        search: { backUrl: location.pathname },
      });
    }
  }, [isNextStep, selectedProgram]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={true}
      validateOnBlur={true}
      onSubmit={(values) => {
        setSelectedProgram(
          flatPrograms?.find(
            (program) => program.id === values.selectedProgram,
          ) ?? null,
        );
        setInputAmount(values.inputAmount);
        nextStep();
        setIsNextStep(true);
        setIsOpen(false);
      }}
    >
      {({ values, errors, touched, setFieldValue }) => (
        <Form className="flex flex-col gap-2">
          <p className="text-base font-bold">{t("quickDonation.title")}</p>
          <DropDown
            name="donationType"
            options={
              donationTypes
                ? donationTypes.map((type) => ({
                    label: type.title,
                    value: type.id,
                  }))
                : []
            }
            placeholder={t("donationFlow.donationtype")}
            onChange={(value) => {
              // Mark field as changed in our local state
              setManuallyChanged((prev) => ({ ...prev, donationType: true }));
              setDonationType(value as string);
              setFieldValue("donationType", value);
              setFieldValue("selectedProgram", "");
            }}
            // Only show error if field is touched AND not just changed
            error={
              touched.donationType && !manuallyChanged.donationType
                ? errors.donationType
                : ""
            }
            onBlur={() => {
              // When field loses focus, we can reset our manual change tracking
              setManuallyChanged((prev) => ({ ...prev, donationType: false }));
            }}
          />
          <DropDown
            name="selectedProgram"
            options={
              flatPrograms
                ? flatPrograms.map((program) => ({
                    label: program.title,
                    value: program.id,
                  }))
                : []
            }
            placeholder={t("donateNow.donationCause")}
            value={values.selectedProgram}
            onChange={(value) => {
              setManuallyChanged((prev) => ({
                ...prev,
                selectedProgram: true,
              }));
              setFieldValue("selectedProgram", value);
            }}
            onBlur={() => {
              setManuallyChanged((prev) => ({
                ...prev,
                selectedProgram: false,
              }));
            }}
            onScroll={() => {
              if (hasNextPage && !isFetchingNextPage) {
                fetchNextPage();
              }
            }}
            error={
              touched.selectedProgram && !manuallyChanged.selectedProgram
                ? errors.selectedProgram
                : ""
            }
            maxHeigh="!max-h-40"
          />
          <div className="mt-2 w-full">
            <AmountInput
              value={
                values.inputAmount !== null ? values.inputAmount.toString() : ""
              }
              onChange={(e) => {
                setManuallyChanged((prev) => ({ ...prev, inputAmount: true }));
                handleInputChange({ event: e, setFieldValue });
              }}
              onBlur={() => {
                setManuallyChanged((prev) => ({ ...prev, inputAmount: false }));
              }}
              className={cn({
                "border-[#F04438]": errors.inputAmount && touched.inputAmount,
              })}
              placeholder={t("donateNow.enterDonationAmount")}
            />
            {errors.inputAmount && touched.inputAmount && (
              <p className="mt-1 text-sm text-[#F04438]">
                {errors.inputAmount}
              </p>
            )}
          </div>
          <Button type="submit" className="mt-2 h-[54px] px-10 text-base">
            {t("quickDonation.proceedPayment")}
          </Button>
          <Button
            type="button"
            className="text-base"
            variant="underline"
            onClick={() => {
              reset();
              setIsOpen(false);
            }}
          >
            {t("quickDonation.cancel")}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export const QuickDonation = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const { i18n } = useTranslation();

  const btnRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  return (
    <DropdownMenu
      dir={isRTLLanguage(i18n.language) ? "rtl" : "ltr"}
      onOpenChange={setIsOpen}
      open={isOpen}
      modal={false}
    >
      <DropdownMenuTrigger asChild>
        <Button
          ref={btnRef}
          className={cn(
            "hover:bg-primary flex h-[44px] w-full md:w-[180px] justify-between rounded-lg pl-4 pr-3 py-4 text-sm focus:outline-none md:focus:ring-0",
            isOpen ? "invisible" : "",
          )}
        >
          <p className="w-full text-start font-bold">
            {t("quickDonation.title")}
          </p>
          {isOpen ? (
            <ChevronUp className={cn("ml-auto h-4 w-4")} />
          ) : (
            <ChevronDown className={cn("ml-auto h-4 w-4")} />
          )}
        </Button>
      </DropdownMenuTrigger>
      {btnRef.current && !isMobile ? (
        <DropdownMenuContent
          style={{
            marginTop: `-${btnRef.current.clientHeight}px`,
          }}
          className="w-[426px] border-none bg-background p-6 text-[14px]"
          side="bottom"
          align="end"
          avoidCollisions={false}
          hideWhenDetached={true}
        >
          <QuickDonationForm setIsOpen={setIsOpen} />
        </DropdownMenuContent>
      ) : (
        <div
          className={clsx(
            "fixed inset-0 z-30 flex items-end justify-center bg-black bg-opacity-50",
            {
              hidden: !isOpen,
            },
          )}
        >
          <div className="w-full overflow-hidden rounded-t-2xl bg-background p-6">
            <QuickDonationForm setIsOpen={setIsOpen} />
          </div>
        </div>
      )}
    </DropdownMenu>
  );
};
