import { Card, CardHeader, CardFooter } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { ArrowLeftToRightIcon } from "@/assets/icons/ArrowLeftToRightIcon";
import { useTranslation } from "react-i18next";
import { bgOverlayStyle } from "@/utils/helpers";
import { Link } from "@tanstack/react-router";
import { useGetCategories } from "@/api/category";
import { CategoryType } from "@/types/category";
import { CarouselComponent } from "./CarouselComponent";
import CustomLink from "./CustomLink";

const Category = (category: CategoryType) => {
  const { i18n } = useTranslation();
  return (
    <Link to={`/programs?category=${category.title}`} className="h-full w-full">
      <Card className="group relative flex w-[310px] flex-col overflow-hidden rounded-2xl border-0 bg-background-second p-6 md:h-[550px] md:p-0 ">
        <div
          className="absolute inset-0 !bg-cover !bg-center transition-transform duration-300 ease-in-out group-hover:scale-110"
          style={{
            background: bgOverlayStyle(category?.image?.minio_url!),
          }}
        ></div>
        <CardHeader className="relative h-96 p-0">
          <h3 className="text-[20px] font-bold leading-[120%] text-white md:text-[32px]">
            {category.title}
          </h3>
          <div
            className={`col-span-3 text-base text-white group-hover:translate-y-0  group-hover:opacity-100 sm:text-lg md:translate-y-full md:transform md:opacity-0 md:transition-all md:duration-300 lg:text-xl`}
          >
            {category?.description}
          </div>
        </CardHeader>
        <CardFooter className="flex">
          <Button
            size="icon"
            className={`absolute bottom-6 h-12  w-12 bg-foreground hover:bg-background-second ${i18n.language === "en" ? "right-6" : "left-6"}`}
            aria-label="Go to category page"
          >
            <ArrowLeftToRightIcon className="fill-[#fafafa] hover:fill-[#22222A] rtl:rotate-180" />
          </Button>
        </CardFooter>
      </Card>
    </Link>
  );
};

const Categories = () => {
  const { t, i18n } = useTranslation();
  const { data: categories } = useGetCategories({
    page_size: "null",
    lang: i18n.language,
  });

  return (
    <section className="my-6 flex w-full flex-col" id="beneficiaries">
      <div className="flex items-center justify-between ">
        <h2 className="mb-4 text-2xl font-bold !leading-[120%] md:mb-6 md:text-5xl">
          {t("Categories.title")}
        </h2>
        <div className="mx-[16px] flex flex-col justify-end pb-4 md:mx-[24px] md:pb-0">
          <CustomLink
            to="/programs"
            mobileText={t("highlightedLink.all_causes_mobile")}
            desktopText={t("highlightedLink.all_causes")}
          />
        </div>
      </div>
      <CarouselComponent imagesMd={2} imagesLg={4}>
        {categories?.map((category) => (
          <Category key={category.title} {...category} />
        ))}
      </CarouselComponent>
    </section>
  );
};

export default Categories;
