import api from "@/api/api-client.ts";
import { useQuery } from "@tanstack/react-query";

export const getClientIp = async () => {
  const res = await api.get("/ipify");
  return res.data as { ip: string };
};

export const useClientIp = () => {
  return useQuery({
    queryKey: ["client-ip"],
    queryFn: getClientIp,
  });
};
