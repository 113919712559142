import * as React from "react";
import { cn } from "@/lib/utils";

export interface InputTextProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  clean?: (_: string) => string;
}

const InputText = React.forwardRef<HTMLInputElement, InputTextProps>(
  ({ className, type, clean, ...props }, ref) => {
    const [isFocused, setIsFocused] = React.useState(false);

    const handleFocus = () => {
      setIsFocused(true);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (props.onChange) {
        props.onChange(e);
      }
    };

    return (
      <>
        <input
          hidden
          type="text"
          value={
            clean && props.value ? clean(props.value.toString()) : props.value
          }
          name={props.name}
        />
        <input
          id={props.name}
          type={type}
          className={cn(
            "p-2 flex h-[58px] w-full rounded-md border bg-background md:text-base text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50 border-[#CCC2BF]",
            isFocused ? "text-[#232329]" : "text-[#4F4754]",
            className,
          )}
          ref={ref}
          onFocus={handleFocus}
          onChange={handleChange}
          {...props}
        />
      </>
    );
  },
);

InputText.displayName = "InputText";

export { InputText };
