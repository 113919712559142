import { lazy } from "react";
import { AppShell } from "@/components/Navbar";
const DonationHistory = lazy(() =>
  import("@/pages/donor-profile/sections/donation-history").then((mod) => ({
    default: mod.DonationHistory,
  })),
);
import { DonationStatistics } from "./sections/donation-statistics";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useLocation, useNavigate } from "@tanstack/react-router";
import { MyUpdates } from "./sections/my-updates";
import { useTranslation } from "react-i18next";
import { Profile } from "./sections/profile";
import { ChallengesTab } from "./sections/challenge";
import { Link } from "@tanstack/react-router";
import { isRTLLanguage } from "@/utils/helpers";

export const MyDonations = () => {
  const { search } = useLocation();
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      <div className="flex h-14 items-center justify-between bg-foreground px-4 md:px-[24px]">
        <div className="flex flex-row gap-1 text-white">
          {t("join.first_part")}{" "}
          <Link
            aria-label={t("join.first_part") + " " + t("join.second_part")}
            to="/community-challenge"
            search={{ tab: "" }}
          >
            <p className="font-bold text-white underline">
              {" "}
              {t("join.second_part")}
            </p>
          </Link>
        </div>
        <Link
          aria-label={t("join.first_part") + " " + t("join.second_part")}
          to="/community-challenge"
          search={{ tab: "" }}
        >
          {isRTLLanguage(i18n.language) ? (
            <img src="/coh-logo-ar-white.svg" className="h-8" />
          ) : (
            <img src="/coh-logo-white.svg" className="h-8" />
          )}
        </Link>
      </div>
      <AppShell theme="white" navbarClass="bg-[#CDCFC8]" />
      <main className="flex flex-col gap-16">
        <section>
          <Tabs
            value={search.page}
            defaultValue="my-updates"
            onValueChange={(value) =>
              navigate({ to: "/donor-profile?page=" + value })
            }
          >
            <div className="sticky top-0 z-[9] bg-[#CDCFC8] p-4">
              <TabsList
                className="bg-transparent [&>button]:rounded-none [&>button]:font-thin [&>button]:text-foreground"
                role="tablist"
                aria-label="Donor profile tabs"
              >
                <TabsTrigger
                  role="tab"
                  aria-controls="my-updates"
                  value="my-updates"
                  className="mx-2 border-transparent px-0 data-[state=active]:bg-[#CDCFC8] data-[state=active]:font-bold data-[state=active]:shadow-none"
                >
                  {t("donorProfile.myUpdates")}
                </TabsTrigger>
                <TabsTrigger
                  role="tab"
                  aria-controls="my-donations"
                  value="my-donations"
                  className="mx-2 border-transparent px-0 data-[state=active]:bg-[#CDCFC8] data-[state=active]:font-bold data-[state=active]:shadow-none"
                >
                  {t("donorProfile.myDonations")}
                </TabsTrigger>
                <TabsTrigger
                  role="tab"
                  aria-controls="challenges"
                  value="challenges"
                  className="mx-2 border-transparent px-0 data-[state=active]:bg-[#CDCFC8] data-[state=active]:font-bold data-[state=active]:shadow-none"
                >
                  {t("donorProfile.challenges")}
                </TabsTrigger>
                <TabsTrigger
                  role="tab"
                  aria-controls="profile"
                  value="profile"
                  className="mx-2 border-transparent px-0 data-[state=active]:bg-[#CDCFC8] data-[state=active]:font-bold data-[state=active]:shadow-none"
                >
                  {t("donorProfile.accountDetails")}
                </TabsTrigger>
              </TabsList>
            </div>
            <TabsContent
              id="my-updates"
              className="mt-0 px-4 md:px-6"
              value="my-updates"
              role="tabpanel"
            >
              <MyUpdates />
            </TabsContent>
            <TabsContent
              id="my-donations"
              value="my-donations"
              className="mt-0 flex flex-col gap-y-16 px-4 md:px-6"
              role="tabpanel"
            >
              <DonationStatistics />
              <DonationHistory />
            </TabsContent>
            <TabsContent id="profile" value="profile" role="tabpanel">
              <Profile />
            </TabsContent>
            <TabsContent id="challenges" value="challenges" role="tabpanel">
              <ChallengesTab />
            </TabsContent>
          </Tabs>
        </section>
      </main>
    </>
  );
};
