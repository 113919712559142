import React, { useState, useEffect } from "react";
import CommonSelectField from "./commondropdwon";
import { FormikErrors } from "formik";

interface StandaloneCustomSelectFieldProps {
  name: string;
  options: { value: string; label: string }[];
  placeholder?: string;
  searchable?: boolean;
  searchPlaceholder?: string;
  label?: string;
  optional?: boolean;
  initialValue?: string | string[];
  onChange?: (value: string | string[]) => void;
  value?: string | string[];
  multiSelect?: boolean;
  error?: string;
  onScroll?: () => void;
  className?: string;
  maxHeigh?: string;
  onBlur?: () => void | Promise<
    FormikErrors<{
      selectedProgram: string | undefined;
      donationType: string | undefined;
      inputAmount: number | null;
    }>
  >;
}

const DropDown: React.FC<StandaloneCustomSelectFieldProps> = ({
  initialValue = "",
  onChange,
  value: externalValue,
  error,
  multiSelect = false,
  onScroll,
  className,
  maxHeigh,
  ...props
}) => {
  const [internalValue, setInternalValue] = useState<string | string[]>(
    multiSelect
      ? Array.isArray(initialValue)
        ? initialValue
        : []
      : initialValue,
  );

  const currentValue =
    externalValue !== undefined ? externalValue : internalValue;

  useEffect(() => {
    if (externalValue !== undefined) {
      setInternalValue(externalValue);
    }
  }, [externalValue]);

  const handleChange = (newValue: string | string[]) => {
    setInternalValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <CommonSelectField
      {...props}
      value={currentValue}
      onChange={handleChange}
      multiSelect={multiSelect}
      error={error}
      onScroll={onScroll}
      className={className}
      maxHeight={maxHeigh}
    />
  );
};

export default DropDown;
