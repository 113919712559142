import { useFormStore } from "@/contexts/paymentFlowContext";
import CustomInputField from "./CustomInputFiled";
import DropDown from "@/components/ui/dropdown";
import { useTranslation } from "react-i18next";
import { ItemType } from "@/types/programs";
import { forwardRef, useEffect, useState } from "react";
import { Form, Formik, FormikErrors } from "formik";
import useCurrencyStore from "@/store/currencyStore";
import * as Yup from "yup";

type ItemizationFormProps = {
  readonly itemsList: ItemType[];
};

type InnerFormProps = {
  setFieldValue: (
    field: string,
    value: any,
  ) => Promise<void | FormikErrors<any>>;
  values: any;
  submitCount: number;
  errors: Record<string, string>;
  itemList: ItemType[];
};

const InnerForm = ({
  setFieldValue,
  values,
  submitCount,
  errors,
  itemList,
}: Readonly<InnerFormProps>) => {
  const { t } = useTranslation();
  const { currency } = useCurrencyStore();
  const [itemObject, setItemObject] = useState<ItemType | null>(
    values.item ? itemList?.find((l) => l.id === values.item)! : null,
  );
  const { setFormError, setInputAmount } = useFormStore();

  const getAmountByCurrency = (item: ItemType) => {
    if (currency === "EGP") {
      return `EGP ${item.amount_egp}`;
    } else {
      return `$ ${item.amount_usd}`;
    }
  };

  useEffect(() => {
    if (submitCount > 0) {
      const hasErrors = Object.keys(errors).length > 0;
      setFormError(hasErrors);
    }
  }, [errors, submitCount]);

  useEffect(() => {
    setInputAmount(
      values.quantity *
        ((currency === "EGP"
          ? itemObject?.amount_egp
          : itemObject?.amount_usd) ?? 0),
    );
  }, [values.quantity, itemObject, currency]);

  useEffect(() => {
    setItemObject(itemList?.find((l) => l.id === values.item) ?? null);
  }, [values.item]);

  return (
    <Form className="flex flex-col gap-8">
      <DropDown
        label={t("donationFlow.donationValue")}
        name="item"
        options={itemList?.map((l) => {
          return {
            label: `${l.name} - ${getAmountByCurrency(l)}`,
            value: l.id,
          };
        })}
        value={values.item ?? ""}
        onChange={(value) => {
          setFieldValue("item", value);
        }}
        placeholder={t("donationFlow.donationValuePlaceholder")}
        error={!values.item && submitCount > 0 ? errors.item : ""}
      />
      <CustomInputField
        label={t("donationFlow.quantity")}
        name="quantity"
        value={values.quantity === 0 ? "" : values.quantity}
        placeholder={t("donationFlow.itemsQuantityPlaceholder")}
        pattern="\d*"
        format={(e) => {
          const numberValue = parseInt(e.target.value);
          if (!isNaN(numberValue)) {
            setFieldValue("quantity", numberValue);
          } else {
            setFieldValue("quantity", 0);
          }
        }}
      />
    </Form>
  );
};

const ItemizationForm = forwardRef<any, ItemizationFormProps>(
  ({ itemsList }, ref) => {
    const { t } = useTranslation();

    const { item, quantity, setQuantity, setItem, setIsDelivery, nextStep } =
      useFormStore();

    const validationSchema = Yup.object().shape({
      quantity: Yup.number().min(1, t("yup.quantityRequired")),
      item: Yup.string()
        .required(t("yup.donationValueRequired"))
        .min(1, t("yup.donationValueRequired")),
    });

    const formInitialValue = {
      quantity: quantity,
      item: item,
    };

    return (
      <Formik
        initialValues={formInitialValue}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          console.log(values);
          setQuantity(values.quantity);
          setItem(values.item ?? "");
          setIsDelivery(false);
          nextStep();
        }}
        innerRef={ref}
      >
        {({ values, setFieldValue, submitCount, errors }) => (
          <InnerForm
            setFieldValue={setFieldValue}
            values={values}
            submitCount={submitCount}
            errors={errors}
            itemList={itemsList}
          />
        )}
      </Formik>
    );
  },
);

export default ItemizationForm;
