import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const CbosSvg: React.FC = () => (
  <svg
    className="absolute bottom-0 right-0"
    width="76"
    height="127"
    viewBox="0 0 76 127"
    fill="none"
  >
    <g opacity="0.06">
      <path
        d="M86 57.5164V105.477C86 115.104 82.3183 124.371 75.7011 131.4C69.0839 138.429 60.0261 142.694 50.3631 143.332V95.3621C50.3566 85.7342 54.0363 76.4647 60.6551 69.4357C67.2738 62.4067 76.3354 58.1452 86 57.5164Z"
        fill="#0A5849"
      />
      <path
        d="M0.0554306 0.405052C0.0554306 0.405052 0.0554306 2.60236 0.0554306 3.69638C0.046104 25.1503 -0.205714 80.9916 0.447148 102.427C0.63368 108.555 2.87206 115.073 5.82859 120.608C13.6163 135.173 30.8239 144.324 44.8511 142.405C44.8511 118.3 46.1288 59.6953 44.4593 35.6547C43.0604 15.5174 20.434 -0.902209 0.0554306 0.405052Z"
        fill="#0A5849"
      />
      <path
        d="M81.9336 11.0486V31.4456C81.9336 46.5486 69.6038 54.1697 54.4574 55.1617V34.7647C54.4574 19.6431 66.7592 12.0499 81.9336 11.0579"
        fill="#70C171"
      />
    </g>
  </svg>
);

const NurseriesSvg: React.FC = () => (
  <svg
    className="absolute bottom-0 right-0"
    width="77"
    height="127"
    viewBox="0 0 77 127"
    fill="none"
  >
    <path
      opacity="0.1"
      d="M82.9336 12.0486V32.4456C82.9336 47.5486 70.6038 55.1697 55.4574 56.1617V35.7647C55.4574 20.6431 67.7592 13.0499 82.9336 12.0579M87 58.5164V106.477C87 116.104 83.3183 125.371 76.7011 132.4C70.0839 139.429 61.0261 143.694 51.3631 144.332V96.3621C51.3566 86.7342 55.0363 77.4647 61.6551 70.4357C68.2738 63.4068 77.3354 59.1452 87 58.5164ZM1.05543 1.40505C1.05543 1.40505 1.05543 3.60236 1.05543 4.69638C1.0461 26.1503 0.794286 81.9916 1.44715 103.427C1.63368 109.555 3.87206 116.073 6.82859 121.608C14.6163 136.173 31.8239 145.324 45.8511 143.405C45.8511 119.3 47.1288 60.6953 45.4593 36.6547C44.0604 16.5174 21.434 0.0977914 1.05543 1.40505Z"
      stroke="#0A5849"
    />
  </svg>
);

export const PartnersSection: React.FC = () => {
  const { t } = useTranslation();
  // Array of partners data
  //INSERT HERE PARTNERS DATA
  const partnersData = [
    {
      type: "image",
      src: "/partners-1.png",
    },
    {
      type: "stats",
      title: "1,257",
      description: t("aboutPage.partnersSection.cbos"),
      icon: <CbosSvg />,
      bgColor: "border border-[#E2DDD7]",
    },
    {
      type: "image",
      src: "/partners-3.png",
    },
    {
      type: "stats",
      title: "51",
      description: t("aboutPage.partnersSection.schools"),
      bgColor: "border border-[#E2DDD7]",
    },
    {
      type: "stats",
      title: "14",
      description: t("aboutPage.partnersSection.elderlyHomes"),
      bgColor: "bg-[#E2DDD7]",
    },
    {
      type: "image",
      src: "/partners-2.jpeg",
    },
    {
      type: "stats",
      title: "149",
      description: t("aboutPage.partnersSection.nurseries"),
      icon: <NurseriesSvg />,
      bgColor: "bg-[#E2DDD7]",
    },
    {
      type: "image",
      src: "/partners-4.png",
    },
  ];

  // State to track screen size
  const [isMediumScreen, setIsMediumScreen] = useState(false);

  // Effect to update screen size state
  useEffect(() => {
    const handleResize = () => {
      setIsMediumScreen(window.innerWidth >= 768); // 768px is the default breakpoint for `md` in Tailwind
    };

    // Initial check
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="my-4 grid grid-cols-2 gap-4 md:grid-cols-4">
      {partnersData.map((partner, index) => {
        // Hide images on small screens
        if (partner.type === "image" && !isMediumScreen) {
          return null;
        }

        return (
          <div
            key={index}
            className={`h-[186px] w-full rounded-[16px] ${
              partner.type === "stats" ? partner.bgColor : ""
            } relative flex flex-col justify-end ${partner.type === "image" ? "" : "p-4"}`}
          >
            {partner.type === "image" ? (
              <img
                className="h-full w-full rounded-[16px] object-cover object-center"
                src={partner.src}
              />
            ) : (
              <>
                {partner.icon}
                <h1 className="text-5xl">{partner.title}</h1>
                <p>{partner.description}</p>
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};
